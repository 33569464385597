import { useEffect } from "react";
import { observer } from "mobx-react";

import CheckBox from "components/CheckBox";
import { appInfoInst } from ".";
import s from "./styles.module.scss";
import { Radio } from "@gamesb42/ui-kit";

const Step2 = observer(() => {
  useEffect(() => {
    appInfoInst.isStepIsValid[2] = appInfoInst.values.monetization.length > 0;
  }, [appInfoInst.values.monetization]);

  return (
    <>
      <h3>
        <b>Monetization model of your app/game?*</b>
      </h3>
      <Radio.Group
        value={appInfoInst.values.monetization}
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <Radio
          value="In-app purchase"
          onChange={() => {
            appInfoInst.values.monetization = "In-app purchase";
          }}
        >
          In-app purchase
        </Radio>
        <Radio
          value="Ads"
          onChange={() => {
            appInfoInst.values.monetization = "Ads";
          }}
        >
          Ads
        </Radio>
        <Radio
          value="In-app purchase & Ads"
          onChange={() => {
            appInfoInst.values.monetization = "In-app purchase & Ads";
          }}
        >
          In-app purchase & Ads
        </Radio>
        <Radio
          value="Premium"
          onChange={() => {
            appInfoInst.values.monetization = "Premium";
          }}
        >
          Premium
        </Radio>
        <Radio
          value="Subscription"
          onChange={() => {
            appInfoInst.values.monetization = "Subscription";
          }}
        >
          Subscription
        </Radio>
        <Radio
          value="I don’t know"
          onChange={() => {
            appInfoInst.values.monetization = "I don’t know";
          }}
        >
          I don’t know
        </Radio>
        <Radio
          value="Other"
          onChange={() => {
            appInfoInst.values.monetization = "Other";
          }}
        >
          Other
        </Radio>
      </Radio.Group>
    </>
  );
});

export default Step2;
