import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import Error from '../../pages/Error';
import appStore from '../../store/App';

type Props = {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError || appStore.isError) {
      return <Error />;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default observer(ErrorBoundary);
