import { observer } from "mobx-react";
import { Button, ArrowLeftIcon, Modal } from "@gamesb42/ui-kit";
import connectionStore from "pages/Connection/components/connectionStore";
import appStore from "store/App";

import s from "./styles.module.scss";
import { contactPersonInst } from "../..";
import { about } from "pages/Connection/components/AboutCompany";
import { authorizedRequest } from "utils/authorization";
import userStore from "store/User";

type Page = 1 | 2;

export const Paginator = observer(() => {
  const emptyArr: number[] = [];
  emptyArr.length = contactPersonInst.isDataSended ? 2 : 3;
  emptyArr.fill(1, 1);

  return (
    <div className={s.paginatorHolder}>
      <Button
        icon={<ArrowLeftIcon size={16} />}
        size="large"
        onClick={() => {
          if (contactPersonInst.page > 1) {
            contactPersonInst.page -= 1;

            const currentContact = about.values.contacts.find(
              (item) => item.type === "contact"
            );
            const currentContactIndx = about.values.contacts.indexOf(
              currentContact!
            );
            if (currentContactIndx > -1) {
              about.values.contacts.splice(currentContactIndx, 1);
            }
          } else {
            connectionStore.currentScreen = "infoScreen";
            appStore.showAccountsDrawer = false;
          }
        }}
        className={s.backBtn}
      >
        Go back
      </Button>
      <div className={s.dotsHolder}>
        {emptyArr.map((item, idx) => {
          return (
            <div
              className={idx === contactPersonInst.page ? s.active : s.inactive}
            />
          );
        })}
      </div>
      <Button
        violet
        type="primary"
        size="large"
        onClick={async () => {
          if (contactPersonInst.isLastStep) {
            let resStatus;
            if (!contactPersonInst.isDataSended) {
              // create
              resStatus = await authorizedRequest({
                url: `/v1/companies/${
                  userStore.me?.company?.id.toString() || ""
                }/contacts`,
                service: "fca",
                method: "POST",
                data: about.contact,
              });
            } else {
              // update
              resStatus = await authorizedRequest({
                url: `/v1/companies/${
                  userStore.me?.company?.id.toString() || ""
                }/contacts/${contactPersonInst.contactId}`,
                service: "fca",
                method: "PATCH",
                data: about.contact,
              });
            }

            if (resStatus) {
              appStore.setNotification({
                type: "success",
                title: `Contact Person information was sent successful`,
                width: 380,
              });
            }

            // console.log({ resStatus });
            // const contact = about.values.contacts.find(
            //   (item) => item.type === "contact"
            // );
            // console.log({ contact });

            contactPersonInst.contactId = resStatus.id;

            connectionStore.currentScreen = "infoScreen";
            contactPersonInst.page = 1;
            contactPersonInst.isDataSended = true;
            appStore.showAccountsDrawer = false;
            return;
          }
          contactPersonInst.page += 1;
        }}
        className={s.nextBtn}
        disabled={
          contactPersonInst.isLastStep
            ? !contactPersonInst.isStepIsValid[2]
            : !contactPersonInst.isStepIsValid[1]
        }
      >
        {contactPersonInst.isLastStep ? "Submit" : "Proceed"}
      </Button>
    </div>
  );
});
