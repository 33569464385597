//@ts-nocheck
import { useEffect } from "react";
import { Radio, Input } from "@gamesb42/ui-kit";

import { corparateDoc } from "./index";
import { observer } from "mobx-react";
import UploadFile from "components/UploadFile";
import { authorizedRequest } from "utils/authorization";
import FileNameMulty from "./components/FileNameMulty";
import FileName from "./components/FileName";

import { ReactComponent as Pdf } from "./assets/pdf.svg";
import { ReactComponent as Close } from "./assets/close.svg";

import s from "./styles.module.scss";

const Step4 = observer(() => {
  useEffect(() => {
    const conditionForUpload =
      ["uploadOne", "uploadMulti"].includes(
        corparateDoc.values.step4Variant2
      ) && corparateDoc.values.certificateOfShareholdersFiles!.length > 0;
    const conditionForNotApplicable =
      corparateDoc.values.step4Variant === "notApplicable" &&
      corparateDoc.values.certificateOfShareholdersComment.length > 0;

    if (conditionForUpload || conditionForNotApplicable) {
      corparateDoc.isStepIsValid[4] = true;
    } else {
      corparateDoc.isStepIsValid[4] = false;
    }
  }, [
    corparateDoc.values.step4Variant2,
    corparateDoc.values.step4Variant,
    corparateDoc.values.certificateOfShareholdersComment,
    corparateDoc.values.certificateOfShareholdersFiles!.length,
  ]);

  function delFileHandler() {
    corparateDoc.values.certificateOfShareholdersFiles.forEach((el) => {
      if (el?.docId > 0) {
        corparateDoc.values.needtoDelete.push(el.docId);
      }
    });
  }

  function delOne(i) {
    if (
      corparateDoc.values.certificateOfShareholdersFiles[i]?.docId?.length > 0
    ) {
      corparateDoc.values.needtoDelete.push(
        corparateDoc.values.certificateOfShareholdersFiles[i].docId
      );
    }
    corparateDoc.values.certificateOfShareholdersFiles =
      corparateDoc.values.certificateOfShareholdersFiles.filter(
        (el2) =>
          el2.name !==
          corparateDoc.values.certificateOfShareholdersFiles[i].name
      );
  }

  return (
    <>
      <p>Certificate of Shareholders*</p>
      <Radio.Group value={corparateDoc.values.step4Variant}>
        <Radio
          value="upload"
          onChange={() => {
            corparateDoc.values.step4Variant = "upload";
          }}
        >
          Upload
        </Radio>
        <Radio
          value="notApplicable"
          onChange={() => {
            delFileHandler();
            corparateDoc.values.step4Variant = "notApplicable";
          }}
        >
          Not applicable
        </Radio>
      </Radio.Group>

      {["upload"].includes(corparateDoc.values.step4Variant!) && (
        <div style={{ marginTop: "40px" }}>
          <p>
            Is your company shareholding structure is going directly to the UBOs
            (physical persons)?
          </p>
          <Radio.Group value={corparateDoc.values.step4Variant2}>
            <Radio
              value="uploadOne"
              onChange={() => {
                corparateDoc.values.step4Variant2 = "uploadOne";
              }}
            >
              Yes
            </Radio>
            <Radio
              value="uploadMulti"
              onChange={() => {
                corparateDoc.values.step4Variant2 = "uploadMulti";
              }}
            >
              No
            </Radio>
          </Radio.Group>
        </div>
      )}

      {corparateDoc.values.step4Variant === "upload" &&
        corparateDoc.values.step4Variant2 === "uploadOne" && (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div className={s.uploadHolder}>
              {
                (corparateDoc?.values?.certificateOfShareholdersFiles
                  ?.length === 0 && (
                  <UploadFile
                    dragText="Drag and drop file*"
                    uploadText={`50 Mb max. weight`}
                    onUpload={(e, file) => {
                      corparateDoc.values.certificateOfShareholdersFiles.push({
                        name: file.name,
                        size: file.size,
                        value: file,
                      });
                    }}
                  />
                )) || <FileNameMulty fileIndex={0} clearFiles={delOne} />
                // (
                //   <div className={s.uploaded}>
                //     <div style={{ display: "flex", alignItems: "center" }}>
                //       <Pdf />
                //       <span className={s.textUploaded}>
                //         {
                //           corparateDoc?.values?.certificateOfShareholdersFiles[0]
                //             ?.name
                //         }
                //       </span>
                //     </div>
                //     <button
                //       className={s.btn}
                //       onClick={() => {
                //         if (
                //           corparateDoc.values.certificateOfShareholdersFiles[0]
                //             ?.docId?.length > 0
                //         ) {
                //           corparateDoc.values.needtoDelete.push(
                //             corparateDoc.values.certificateOfShareholdersFiles[0]
                //               .docId
                //           );
                //         }
                //         corparateDoc.values.certificateOfShareholdersFiles = [];
                //       }}
                //     >
                //       <Close />
                //     </button>
                //   </div>
                // )
              }
            </div>
            <Input.TextArea
              style={{ height: "100px" }}
              label="Comment to the uploaded file"
              disabled={
                corparateDoc?.values?.certificateOfShareholdersFiles[0]?.docId
                  ?.length > 0
              }
              showCount={true}
              maxLength={200}
              value={corparateDoc.values.certificateOfShareholdersComment}
              onChange={(e) => {
                corparateDoc.values.certificateOfShareholdersComment =
                  e.target.value;
              }}
            />
          </div>
        )}

      {corparateDoc.values.step4Variant === "upload" &&
        corparateDoc.values.step4Variant2 === "uploadMulti" && (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <UploadFile
              dragText="Drag and drop files*"
              uploadText={`50 Mb max. weight`}
              onUpload={(e, file) => {
                corparateDoc.values.certificateOfShareholdersFiles.push({
                  name: file.name,
                  size: file.size,
                  value: file,
                });
              }}
            />
            {corparateDoc.values.certificateOfShareholdersFiles.map((el, i) => {
              return <FileNameMulty fileIndex={i} clearFiles={delOne} />;
              // (
              //   <div className={s.uploaded} key={i}>
              //     <div style={{ display: "flex", alignItems: "center" }}>
              //       <Pdf />
              //       <span className={s.textUploaded}>{el?.name}</span>
              //     </div>
              //     <button
              //       className={s.btn}
              //       onClick={() => {
              //         if (el?.docId?.length > 0) {
              //           corparateDoc.values.needtoDelete.push(el.docId);
              //         }

              //         corparateDoc.values.certificateOfShareholdersFiles =
              //           corparateDoc.values.certificateOfShareholdersFiles.filter(
              //             (el2) => el2.name !== el.name
              //           );
              //       }}
              //     >
              //       <Close />
              //     </button>
              //   </div>
              // );
            })}
            <Input.TextArea
              style={{ height: "100px" }}
              label="Comment to the uploaded file"
              disabled={corparateDoc.values.certificateOfShareholdersFiles.some(
                (el) => el?.docId?.length > 0
              )}
              showCount={true}
              maxLength={200}
              value={corparateDoc.values.certificateOfShareholdersComment}
              onChange={(e) => {
                corparateDoc.values.certificateOfShareholdersComment =
                  e.target.value;
              }}
            />
          </div>
        )}

      {corparateDoc.values.step4Variant === "notApplicable" && (
        <div style={{ padding: "32px 0 0 0" }}>
          <Input.TextArea
            style={{ height: "100px" }}
            label="Not applicable? Please explain why*"
            disabled={false}
            showCount={true}
            maxLength={200}
            value={corparateDoc.values.certificateOfShareholdersComment}
            onChange={(e) => {
              corparateDoc.values.certificateOfShareholdersComment =
                e.target.value;
            }}
            placeholder="Not applicable? Please explain why*"
          />
        </div>
      )}
    </>
  );
});

export default Step4;
