//@ts-nocheck
import { observer } from "mobx-react";
import { InputNumber } from "@gamesb42/ui-kit";

import { director } from ".";

import s from "./styles.module.scss";

const CorpDocs = observer(() => {
  director.values.currentPerson = 0;

  if (director.numberOfUBOs >= director.numberOfUBOsBefore) {
    for (
      let i = 0;
      i < director.numberOfUBOs - director.numberOfUBOsBefore;
      i++
    ) {
      director.values.persons.push({
        fullName: "",
        positionInCompany: "",
        businessControl: null,
        profOfId: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
        profOfAddress: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
        resume: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
        profOfFunds: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
      });
    }

    director.numberOfUBOsBefore = director.numberOfUBOs;
  } else {
    director.deleteAllDirectors();
    director.values.persons = [];
  }

  if (director.numberOfDirectors >= director.numberOfDirectorsBefore) {
    for (
      let i = 0;
      i < director.numberOfDirectors - director.numberOfDirectorsBefore;
      i++
    ) {
      director.values.persons.push({
        fullName: "",
        positionInCompany: "",
        businessControl: null,
        profOfId: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
        profOfAddress: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
        resume: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
        profOfFunds: {
          id: "",
          docId: "",
          name: "",
          value: "",
          size: 0,
        },
      });
    }

    director.numberOfUBOsBefore = director.numberOfUBOs;
    director.numberOfDirectorsBefore = director.numberOfDirectors;
  } else {
    director.deleteAllDirectors();
    director.values.persons = [];
  }

  return (
    <>
      <div className={s.corpBlock}>
        <div className={s.row}>
          <div className={s.header}>
            How many UBOs in total does you company have?*
          </div>
          <InputNumber
            label="Number of UBOs"
            size="large"
            value={director.numberOfUBOs}
            onChange={(e) => {
              if (e > 0) director.numberOfUBOs = e;
            }}
            min={1}
            max={5}
          />
        </div>

        <div className={s.row}>
          <div className={s.header}>
            How many Directors or its equivalent in total does you company
            have?*
          </div>
          <InputNumber
            label="Number of Directors or its equivalent"
            size="large"
            value={director.numberOfDirectors}
            onChange={(e) => {
              if (e > 0) director.numberOfDirectors = e;
            }}
            min={1}
            max={5}
          />
        </div>
      </div>
    </>
  );
});

export default CorpDocs;
