import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Drawer as DrawerUiKit, Button } from "@gamesb42/ui-kit";
import appStore from "store/App";
import { Paginator } from "../AboutCompany/components/Paginator";
import { Paginator as CorporateDocsPaginator } from "../CorporateDocument/components/Paginator";
import { Paginator as AppInfoPaginator } from "../AppInfo/components/Paginator";
import { Paginator as CompanySignatoryPaginator } from "../CompanySignatory/components/Paginator";
import { Paginator as ContactPersonPaginator } from "../ContactPerson/components/Paginator";
import { Paginator as DirectorsPaginator } from "../Director/components/Paginator";
import connectSources from "../../../../store/ConnectSources";
import accountConnect from "../accountConnect";
import connectionStore, { DrawerScreens } from "../connectionStore";
import { about } from "../AboutCompany";
import styles from "./styles.module.scss";
import { contactPersonInst } from "../ContactPerson";

const Drawer = ({
  children,
  initialScreen,
}: {
  children: React.ReactNode;
  initialScreen?: DrawerScreens;
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      accountConnect.reset();
      connectionStore.reset();
      if (initialScreen) {
        connectionStore.currentScreen = initialScreen;
      }
    };
  }, []);

  const isDataSaved = about.isPersonalDataValid && about.isCompanyInfoValid;

  const getDrawerTitle = () => {
    if (connectionStore.currentScreen === "aboutCompany")
      return "Basic company info";
    if (connectionStore.currentScreen === "personInfo") return "Contact Person";
    if (connectionStore.currentScreen === "revenuesList")
      return "Connect your Revenue Sources";
    if (connectionStore.currentScreen === "corporateDocs")
      return "Set of corporate documents";
    if (connectionStore.currentScreen === "appInfo") return "App/Game info";
    if (connectionStore.currentScreen === "companySignatory")
      return "Company Signatory";
    if (connectionStore.currentScreen === "directors") return "UBOs&Directors";
    return null;
  };

  return (
    <DrawerUiKit
      open={appStore.showAccountsDrawer}
      className={styles.drawer}
      title={getDrawerTitle()}
      onClose={() => {
        if (
          connectionStore.currentScreen === "personInfo" &&
          !contactPersonInst.isDataSended
        ) {
          about.values.contacts = about.values.contacts.filter(
            (item) => item.type !== "contact"
          );
        }
        appStore.showAccountsDrawer = false;
      }}
      footer={
        <>
          {(connectSources.selectedSourcesList.length && (
            <div className={styles.btnsHolder}>
              <div className={styles.disclaimer}>
                You selected {connectSources.selectedSourcesList.length}{" "}
                <b>RevenueSource</b>
              </div>
              <Button
                className={styles.submit}
                onClick={async () => {
                  await accountConnect.addAccountByIds(
                    connectSources.selectedSourcesList
                  );
                  appStore.showAccountsDrawer = false;
                }}
              >
                Send request
              </Button>
            </div>
          )) ||
            null}

          {connectionStore.currentScreen === "aboutCompany" && <Paginator />}

          {connectionStore.currentScreen === "corporateDocs" && (
            <CorporateDocsPaginator />
          )}

          {connectionStore.currentScreen === "companySignatory" && (
            <CompanySignatoryPaginator />
          )}

          {connectionStore.currentScreen === "appInfo" && <AppInfoPaginator />}

          {connectionStore.currentScreen === "personInfo" && (
            <ContactPersonPaginator />
          )}
          {connectionStore.currentScreen === "directors" && (
            <DirectorsPaginator />
          )}
        </>
      }
    >
      <div className={styles.drawerHolder}>{children}</div>
    </DrawerUiKit>
  );
};

export default observer(Drawer);
