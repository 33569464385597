import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { makeAutoObservable } from "mobx";
import Main from "../../layout/Main";
import SideMenu from "../../components/SideMenu";
import connectSources from "../../store/ConnectSources";
import RevenueCard from "../Connection/components/RevenueCard";
import accountConnect from "../Connection/components/accountConnect";
import { Title2 } from "../Connection/components/Typography";
import TextInput from "../Connection/components/TextInput";
import UploadFile from "../../components/UploadFile";
import Button from "../../components/Button";
import appStore from "../../store/App";

import { ReactComponent as Loader } from "./assets/loader.svg";
import { ReactComponent as Pdf } from "./assets/pdf.svg";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as Back } from "./assets/back.svg";
import styles from "./styles.module.scss";

class Revenue {
  constructor() {
    makeAutoObservable(this);
  }

  currentScreen: "sources" | "connect" = "sources";
}

const revenueSources = new Revenue();

const RevenueSources = observer(() => {
  useEffect(() => {
    connectSources.getSources();
  }, []);

  const getContent = () => {
    switch (revenueSources.currentScreen) {
      case "sources":
        return (
          <div className={styles.sourcesList}>
            {connectSources.sourcesListSelf.map((source) => {
              const { id, name, logo, code } = source;
              const onClick = () => {
                accountConnect.account = {
                  id,
                  name,
                  logo,
                  code,
                };
                revenueSources.currentScreen = "connect";
              };
              return (
                <div className={styles.cardHolder}>
                  <RevenueCard {...source} onClick={onClick} />
                </div>
              );
            })}
          </div>
        );
      case "connect":
      default:
        return <ConnectSource />;
    }
  };

  return (
    <Main>
      <SideMenu />
      <div className={styles.container}>
        {revenueSources.currentScreen === "sources" && (
          <h1 className={styles.title}>Revenue Sources</h1>
        )}
        {getContent()}

        {revenueSources.currentScreen === "sources" && (
          <p className={styles.text}>
            We are constantly updating the list of Revenue Source available for
            connection.
            <br />
            If your Revenue Source is not on the list above, please contact your
            manager.
          </p>
        )}
      </div>
    </Main>
  );
});

const ConnectSource = observer(() => {
  useEffect(() => {
    accountConnect.getFields(accountConnect.account?.code || "");
    return () => {
      accountConnect.reset();
    };
  }, []);
  const [isLoading, setLoading] = useState(false);

  return (
    <div className={styles.connectContainer}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <Title2 className={styles.connectTitle}>
        <button
          onClick={() => {
            revenueSources.currentScreen = "sources";
          }}
          className={styles.btn}
        >
          <Back className={styles.backIcon} />
        </button>
        <div className={styles.accountName}>
          {(accountConnect.account?.logo && (
            <img
              alt="src"
              src={accountConnect.account?.logo}
              className={styles.icon}
            />
          )) ||
            null}
          Connect your {accountConnect.account?.name} account
        </div>
      </Title2>
      {accountConnect.instructionUrl && (
        <a
          className={styles.link}
          rel="noreferrer"
          target="_blank"
          href={accountConnect.instructionUrl}
        >
          Connection instructions
        </a>
      )}
      <RevenueBody />
      <Button
        type="submit"
        className={styles.submit}
        onClick={async () => {
          try {
            await accountConnect.addAccount();
            revenueSources.currentScreen = "sources";
            connectSources.hasRevenueSource = true;
          } catch (e) {
            appStore.notification = {
              title: "An error occurred while attempting",
              message: `to connect ${accountConnect.account?.name}`,
              width: 377,
              type: "error",
            };
          } finally {
            setLoading(false);
          }
        }}
        disabled={!accountConnect.canSend}
      >
        Connect source
      </Button>
    </div>
  );
});

export const RevenueBody = observer(() => {
  return (
    <>
      {accountConnect.fields
        .slice()
        .sort((a, b) => b.length - a.length)
        .map((field, idx) => {
          if (field !== "key") {
            return (
              <TextInput
                className={styles.input}
                onChange={(e) => {
                  accountConnect.values[field] = e;
                }}
                labelClassName={styles.labelInput}
                label={accountConnect.fieldLabels[field]}
                value={accountConnect.values[field]}
              />
            );
          }
          return (
            <div className={styles.uploadHolder}>
              {(!accountConnect.file.value && (
                <>
                  <div className={styles.apiLabel}>Api key*</div>
                  <UploadFile
                    onUpload={(e, file) => {
                      accountConnect.file.name = file.name;
                      accountConnect.file.size = file.size;
                      accountConnect.file.value = e!.target!.result;
                    }}
                  />
                </>
              )) || (
                <div className={styles.uploaded}>
                  <Pdf />
                  <span className={styles.textUploaded}>
                    {accountConnect.file.name}{" "}
                  </span>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      accountConnect.file = {
                        name: "",
                        value: "",
                        size: 0,
                      };
                    }}
                  >
                    <Close />
                  </button>
                </div>
              )}
            </div>
          );
        })}
    </>
  );
});

export default RevenueSources;
