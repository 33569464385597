import React, { useEffect, useState, useCallback } from 'react';
import { Pagination, PaginationItem } from '@mui/lab';
import Select, { SelectItem } from '../Select';
import { ReactComponent as Prev } from '../../pages/Home/SalesInfo/assets/prev.svg';
import { ReactComponent as Next } from '../../pages/Home/SalesInfo/assets/next.svg';
import styles from './styles.module.scss';

const PAGES_LIMIT = 5;

const Paginator = ({
  initialRows,
  onRowsChange,
  totalPages,
  currentPage,
  onPageChange,
  hasNext,
  hasPrev,
  totalItems,
}:{
  initialRows: number,
  onRowsChange: (rows: number) => void,
  totalPages: number,
  currentPage: number,
  onPageChange: (page: number) => void,
  hasNext: boolean,
  hasPrev: boolean,
  totalItems: number,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  useEffect(() => {
    onRowsChange(rowsPerPage);
  }, [rowsPerPage]);

  const arr: number[] = [];
  arr.length = totalPages;
  arr.fill(1);

  const smallPagesAmount = useCallback(() => {
    const emptyArr:number[] = [];
    emptyArr.length = totalPages;
    emptyArr.fill(1, 0, totalPages);
    return emptyArr.map((item, idx) => {
      return (
        <div
          className={
            idx + 1 === currentPage ? styles.page : styles.inactivePage
          }
          /* eslint-disable-next-line react/no-array-index-key */
          key={idx}
          onClick={() => onPageChange(idx + 1)}
          tabIndex={0}
          role="button"
        >
          {idx + 1}
        </div>
      );
    });
  }, [totalPages, currentPage]);

  const lotPageAmount = useCallback(() => {
    const btnsStart:number[] = [];
    const btnsEnd:number[] = [];
    if (currentPage >= 1 && currentPage <= 4) {
      btnsStart.length = 4;
      btnsStart.fill(1, 0, 4);
    } else if (currentPage > 4 && currentPage >= totalPages - 3) {
      for (let i = totalPages - 3; i <= totalPages; i++) {
        btnsStart.push(i);
      }
    } else if (currentPage > 4 && currentPage < totalPages - 3) {
      btnsEnd.push(currentPage);
      btnsEnd.push(currentPage + 1);
    }

    return (
      <>
        {currentPage >= 1 && currentPage <= 4 && (
          <>
            {btnsStart.map((btn, idx) => {
              return (
                <div
                  className={
                    idx + 1 === currentPage ? styles.page : styles.inactivePage
                  }
                  onClick={() => onPageChange(idx + 1)}
                  tabIndex={0}
                  role="button"
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={idx}
                >
                  {idx + 1}
                </div>
              );
            })}
            <div
              className={`${styles.inactivePage} ${styles.dots}`}
              onClick={() => onPageChange(5)}
              tabIndex={0}
              role="button"
            >...
            </div>
            <div
              className={styles.inactivePage}
              onClick={() => onPageChange(totalPages)}
              tabIndex={0}
              role="button"
            >
              {totalPages}
            </div>
          </>
        )}

        {
          currentPage > 4 && currentPage >= totalPages - 3 && (
            <>
              <div
                className={styles.inactivePage}
                onClick={() => onPageChange(1)}
                tabIndex={0}
                role="button"
              >
                1
              </div>
              <div
                className={`${styles.inactivePage} ${styles.dots}`}
                tabIndex={0}
                role="button"
                onClick={() => onPageChange(btnsStart[0] - 1)}
              >...
              </div>
              {btnsStart.map((btn) => {
                return (
                  <div
                    className={
                      btn === currentPage ? styles.page : styles.inactivePage
                    }
                    onClick={() => onPageChange(btn)}
                    tabIndex={0}
                    role="button"
                  >
                    {btn}
                  </div>
                );
              })}
            </>
          )
        }
        {
          currentPage > 4 && currentPage < totalPages - 3 &&
          <>
            <div
              className={styles.inactivePage}
              onClick={() => onPageChange(1)}
              tabIndex={0}
              role="button"
            >
              1
            </div>
            <div
              className={`${styles.inactivePage} ${styles.dots}`}
              tabIndex={0}
              role="button"
              onClick={() => onPageChange(btnsEnd[0] - 1)}
            >...
            </div>
            {btnsEnd.map((btn) => {
              return (
                <div
                  className={
                    btn === currentPage ? styles.page : styles.inactivePage
                  }
                  onClick={() => onPageChange(btn)}
                  tabIndex={0}
                  role="button"
                >
                  {btn}
                </div>
              );
            })}
            <div
              className={`${styles.inactivePage} ${styles.dots}`}
              tabIndex={0}
              role="button"
              onClick={() => onPageChange(btnsEnd[1] + 1)}
            >...
            </div>
            <div
              className={styles.inactivePage}
              onClick={() => onPageChange(totalPages)}
              tabIndex={0}
              role="button"
            >
              {totalPages}
            </div>
          </>
        }
      </>
    );
  }, [totalPages, currentPage]);

  const currentItemsText = () => {
    if (currentPage === 1 && rowsPerPage > totalItems) {
      return totalItems;
    }
    const endCurrentItems = rowsPerPage * currentPage > totalItems ? totalItems : rowsPerPage * currentPage;
    return `${rowsPerPage * (currentPage - 1) + 1}-${endCurrentItems}`;
  };

  return (
    <div className={styles.paginatorHolder}>
      <span className={`${styles.text} ${styles.rowsText}`}>Rows per page</span>
      <div className={styles.selectHolder}>
        <Select title={rowsPerPage}>
          <SelectItem onClick={() => setRowsPerPage(initialRows)}>{initialRows}</SelectItem>
          <SelectItem onClick={() => setRowsPerPage(initialRows * 2)}>{initialRows * 2}</SelectItem>
          <SelectItem onClick={() => setRowsPerPage(initialRows * 3)}>{initialRows * 3}</SelectItem>
        </Select>
      </div>
      <span className={`${styles.text} ${styles.rowsTextCount}`}>{currentItemsText()} of {totalItems}</span>
      {
        totalPages &&
        <div className={styles.pagContainer}>
          {
            hasPrev &&
            <Prev
              className={styles.arrow}
              onClick={() => onPageChange(currentPage - 1)}
              role="button"
              tabIndex={0}
            /> || null
          }

          {totalPages <= PAGES_LIMIT && (
            <div className={styles.holder}>{smallPagesAmount()}</div>
          )}

          {totalPages > PAGES_LIMIT && <div className={styles.holder}>{lotPageAmount()}</div>}
          {
            hasNext &&
            <Next
              className={styles.arrow}
              role="button"
              tabIndex={0}
              onClick={() => onPageChange(currentPage + 1)}
            /> || null
          }

        </div>
        || null
      }
    </div>
  );
};

export default Paginator;
