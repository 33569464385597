import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from 'components/Modal';
import { Icon } from 'pages/Home/SalesInfo/components/Request';
import { Currency } from 'types';
import applaudStore, { Status } from '../../../../store/Applaud';
import Tooltip from '../../../../components/Tooltip';
import Button from '../../../../components/Button';
import { authorizedRequest } from '../../../../utils/authorization';
import { formatCurrency } from '../../../../utils/helpers';
import styles from '../styles.module.scss';

type RProps = {
  onClose: () => void,
  id: string|null,
}

type CProps = {
  platform_id: string
  platform_logo: string
  purchase_price: string
  purchased: string,
  platform: string
}

type DProps = {
  advance_sum: string
  claim: null|[]|CProps[],
  currency: Currency,
  number: number
  rejection_reason: null|string,
  status: Status
}

const EditRequest = ({ onClose, id }:RProps) => {
  const [requestDetails, setRequestDetails] = useState<null|DProps>(null);
  useEffect(() => {
    (async () => {
      try {
        const response = await authorizedRequest({
          url: `/ui/api/v1/purchases/now?request_id=${id}`,
        });
        setRequestDetails(response);
      } catch (e) {
        onClose();
      }
    })();
  }, []);
  if (!requestDetails) return null;

  const getText = (status:Status, rejectedReason:string|null) => {
    if (rejectedReason) return rejectedReason;
    if (status === 'signed') return 'Awaiting fund transfer';
    if (status === 'funded') return 'Has been funded';
    return '';
  };

  return (
    <Modal
      isOpen={!!id}
    >
      <div className={styles.modal}>
        <Box display="flex" justifyContent="space-between">
          <h3 className={styles.title}>Request No. {requestDetails.number}</h3>
          <Icon status={requestDetails.status} />
        </Box>

        <div className={styles.disclaimer}>
          {
          getText(requestDetails.status, requestDetails.rejection_reason) &&
            <>{getText(requestDetails.status, requestDetails.rejection_reason)}</>
          || null
        }
        </div>
        <div className={styles.greyHolder}>
          <h4 className={styles.titleTable}>Total Advance Requested: {formatCurrency(+requestDetails.advance_sum, requestDetails.currency)}</h4>
          <Box display="flex">
            <Box width="33%" display="flex" alignItems="baseline"><span className={styles.titleHeader}>Revenue Source</span><Tooltip title="The marketplace or ad network which revenue you sell to us" /></Box>
            <Box width="25%" textAlign="right"><span className={styles.titleHeader}>You sell</span><Tooltip title="The amount of revenue you have sold to us" /></Box>
            <Box width="38%" textAlign="right"><span className={styles.titleHeader}>You get</span><Tooltip title="The amount we have transferred to your bank account" /></Box>
          </Box>
          <div className={styles.divider} />

          {
            requestDetails.claim?.map(claim => {
              return (
                <Box display="flex" height={52} alignItems="center">
                  <Box width="33%" display="flex" alignItems="center">
                    <img src={claim.platform_logo} alt="platform" className={styles.img} />
                    <span className={styles.cellText}>
                      {claim.platform}
                    </span>
                  </Box>
                  <Box width="25%" textAlign="right"><span className={styles.cellText}>{formatCurrency(+claim.purchased, requestDetails.currency)}</span></Box>
                  <Box width="38%" textAlign="right"><span className={styles.cellTextGet}>{formatCurrency(+claim.purchase_price, requestDetails.currency)}</span></Box>
                </Box>
              );
            })
          }

        </div>
        {
          (requestDetails.status === 'new' || requestDetails.status === 'rejected') &&
          <Box marginTop="20px" display="inline-flex" width="100%">
            <Button
              className={styles.btn}
              type="delete"
              onClick={async () => {
                await authorizedRequest({
                  url: `/ui/api/v1/purchase_request/${id}`,
                  method: 'DELETE',
                });
                await applaudStore.getApplaud();
                onClose();
              }}
            >
              Delete Request
            </Button>
            <Box marginLeft="auto">
              <Button
                type="cancel"
                className={styles.btn}
                onClick={onClose}
              >
                Cancel
              </Button>
              {/*<Button width={153} color="#131416" background="#ECEEF2" height={52} marginLeft="20px">Edit Request</Button>*/}
            </Box>
          </Box>
        }

        {
          (requestDetails.status === 'pending' || requestDetails.status === 'signed') &&
          <Box marginTop="20px" display="inline-flex" width="100%">
            <Box marginLeft="auto">
              <Button
                className={styles.btn}
                type="cancel"
                onClick={onClose}
              >
                Got it
              </Button>
            </Box>
          </Box>
        }

      </div>
    </Modal>
  );
};

export default EditRequest;
