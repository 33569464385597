import React from "react";
import { Checkbox } from "@gamesb42/ui-kit";
import style from "./styles.module.scss";

const CheckBox = ({
  checked,
  label,
  type = "square",
  className = "",
  onChange,
  labelClassName = "",
  dataTest,
  children,
}: {
  children?: React.ReactNode;
  dataTest?: string;
  checked?: boolean;
  label?: string;
  type?: "square" | "round";
  className?: string;
  onChange?: () => void;
  labelClassName?: string;
}) => {
  return (
    <div className={className}>
      <Checkbox onChange={onChange} checked={checked}>
        {children || label}
      </Checkbox>
    </div>
  );
};

export default CheckBox;
