import React, { useState, useEffect } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Table, Tooltip, QuestionIcon, ArrowTrMaxUpIcon, ArrowTrMaxDownIcon } from '@gamesb42/ui-kit';
import type { ColumnsType } from 'antd/es/table';
import FormatDate from 'components/FormatDate';
import { Currency } from 'types';
import { USD } from 'const';
import SideMenu from '../../components/SideMenu';
import Main from '../../layout/Main';
import { authorizedRequest } from '../../utils/authorization';
import { formatCurrency } from '../../utils/helpers';
import styles from './styles.module.scss';
import { ReactComponent as Overdue } from './assets/overdue.svg';
import { ReactComponent as Next } from './assets/next.svg';

type Payment = {
  commission: number,
  currency: Currency,
  default_interest: number
  is_overdue: boolean,
  order_number: string,
  principal_amount: string,
}

class ExpectedPaymentsStore {
  constructor() {
    makeAutoObservable(this);
  }

  epList:DataType[] = []

  loading = true;

  opened:string[] = [];

  getEpList = async () => {
    try {
      const response = await authorizedRequest({ url: '/ui/api/v1/repayment_schedule' });
      this.epList = response.schedule.map((item: DataType, key:number) => { return { ...item, key }; });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}

const localStore = new ExpectedPaymentsStore();

// const fakeData = [{
//   payment_date: '21.02.2023',
//   total: 2300,
//   payments: [{
//     commission: 25,
//     currency: USD as Currency,
//     default_interest: 223,
//     is_overdue: false,
//     order_number: '',
//     principal_amount: '556',
//   }],
// }];

interface DataType {
  payment_date: string,
  total: number,
  payments: Payment[]
}

const ExpectedPayments = observer(() => {
  const [opened, setOpened] = useState<string[]>([]);
  useEffect(() => {
    localStore.getEpList();
  }, []);

  const getStatusHeader = () => {
    return (
      <div className={styles.tooltipHolder}>
        <Tooltip
          title={
            <ul>
              <li> NEXT: Payment is not yet due. Please make sure that the payment reaches us no later than the payment date</li>
              <li> DUE: Payment is due. Please make sure you have sent the payment, otherwise it will become overdue</li>
              <li> OVERDUE: The payment is overdue and it might be considered as an Event of Default</li>
            </ul>
        }
          placement="bottom"
        >
          Status
          <QuestionIcon className={styles.tooltipIcon} />
        </Tooltip>
      </div>
    );
  };
  const columns: ColumnsType<DataType> = [
    {
      title: <div className={styles.tooltipHolder}><Tooltip title="The date on which payment should be received" placement="top">Date<QuestionIcon className={styles.tooltipIcon} /></Tooltip></div>,
      dataIndex: '',
      key: 'date',
      width: '30%',
      render: (data) => {
        return (
          <div className={classNames(styles.dateData, data.payments[0].is_overdue && styles.isOverdue)}>
            <FormatDate date={data.payment_date} />
          </div>
        );
      },
    },
    {
      title: <div className={styles.tooltipHolder}><Tooltip title="Amount due on the payment date" placement="top">Amount<QuestionIcon className={styles.tooltipIcon} /></Tooltip></div>,
      dataIndex: '',
      key: 'amount',
      align: 'center',
      width: '30%',
      render: (data) => {
        return (
          <div className={classNames(styles.amountData, data.payments[0].is_overdue && styles.isOverdue)}>
            {formatCurrency(data.total, data.payments[0].currency)}
          </div>
        );
      },
    },
    {
      title: getStatusHeader(),
      dataIndex: 'payments',
      key: 'status',
      width: '30%',
      align: 'right',
      render: (payments) => {
        return <div className={styles.statusData}>{ payments[0].is_overdue ? <Overdue /> : <Next /> }</div>;
      },
    },
  ];
  const data: DataType[] = localStore.epList.slice();
  return (
    <Main>
      <SideMenu />
      <div className={styles.container}>
        <h1 className={styles.title}>Expected Payments</h1>
        <Table
          className={styles.table}
          expandable={{
            expandedRowRender: (record) => <PaymentInfoRow item={record} />,
            showExpandColumn: true,
            expandIconColumnIndex: 4,
            onExpand: (expanded, record) => {
              if (expanded) {
                setOpened([...opened, record.payment_date]);
                return;
              }
              setOpened(opened.filter(item => item !== record.payment_date));
            },
            expandIcon: ({ expanded, onExpand, record }) => expanded ? (
              <div
                className={styles.actionButton}
                role="button"
                onClick={e => onExpand(record, e)}
                tabIndex={0}
              >
                <ArrowTrMaxUpIcon />
              </div>
            ) : (
              <div
                className={styles.actionButton}
                role="button"
                onClick={e => onExpand(record, e)}
                tabIndex={0}
              >
                <ArrowTrMaxDownIcon />
              </div>
            ),
          }}
          columns={columns}
          dataSource={data}
          expandRowByClick
        />
      </div>
    </Main>
  );
});

const PaymentInfoRow = observer(({ item }: { item: DataType }) => {
  return (
    <div className={styles.openedData}>
      {
          item.payments.map(payment => {
            return (
              <div className={styles.openedInfo} key={payment.order_number}>
                <div className={styles.firstInfo}>AO {payment.order_number}</div>
                <div className={styles.infoList}>
                  <div className={styles.infoRow}>
                    <span className={styles.infoText}>
                      Commission:
                    </span>
                    <span className={styles.infoPay}>
                      {formatCurrency(payment.commission, payment.currency)}
                    </span>
                  </div>
                  {Number(payment.default_interest) !== 0 && (
                    <div className={styles.infoRow}>
                      <span className={styles.infoText}>Default Interest:</span>
                      <span className={styles.infoPay}>{formatCurrency(payment.default_interest, payment.currency)}</span>
                    </div>
                  )}
                  {Number(payment.principal_amount) !== 0 && (
                    <div className={styles.infoRow}>
                      <span className={styles.infoText}>Principal amount:</span>
                      <span className={styles.infoPay}>{formatCurrency(payment.principal_amount, payment.currency)}</span>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        }
      {/*<div className={styles.openedInfo}>*/}
      {/*  <div className={styles.firstInfo}>AO 13</div>*/}
      {/*  <div className={styles.infoList}>*/}
      {/*    <div className={styles.infoRow}><span className={styles.infoText}>Commission:</span> <span className={styles.infoPay}>€2,000.00</span></div>*/}
      {/*    <div className={styles.infoRow}><span className={styles.infoText}>Default Interest:</span> <span className={styles.infoPay}>€23,000.00</span></div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
});

export default ExpectedPayments;
