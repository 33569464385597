//@ts-nocheck
import { makeAutoObservable } from "mobx";
import { authorizedRequest } from "../../../utils/authorization";
import applaudStore from "../../../store/Applaud";
import { formatCurrency } from "../../../utils/helpers";
import User from "../../../store/User";

type Platform = {
  initialAvailableReceivable: number;
  platform_id: string;
  pds_platform_id: string;
  platform: string;
  platform_logo: string;
  available_advance: number;
  purchase_percentage: number;
  currency: string;
  purchase_price_percentage: number;
  initialAdvance: number;
  relativePercent: number;
  funding_amount: string;
  available_receivable: string;
  initialAvailableAdvance: number;
  payment_date: string;
};

type ModalData = {
  min_threshold: number;
  available_advance: string;
  currency: string | undefined | null;
  funding_amount: string;
  platforms: Platform[];
};

export type Validation = {
  title: string;
  description: string;
};

class Platforms {
  constructor() {
    makeAutoObservable(this);
  }

  errorOnCreate: null | boolean = null;

  requestCreated: number = 0;

  requestCreating: boolean = false;

  loadingData = false;

  validationError: Validation | null = null;

  data: ModalData = {
    min_threshold: 0,
    available_advance: "0",
    currency: User.getDefaultCurrency?.value,
    funding_amount: "0",
    platforms: [],
  };

  createRequest = async () => {
    if (this.totalAdvance < this.data.min_threshold) {
      this.errorOnCreate = true;
      this.validationError = {
        title: "Sorry... Request could not be created now",
        description: `Your Available Advance for funding is less than ${formatCurrency(
          +this.data.min_threshold,
          User.getDefaultCurrency?.label
        )}`,
      };
      return;
    }
    try {
      this.requestCreating = true;
      const available_advance = this.data.platforms.map((platform) => {
        return {
          platform_id: platform.platform_id,
          purchase_price: platform.available_advance.toFixed(2),
          currency: platform.currency,
        };
      });
      const { request_number } = await authorizedRequest({
        url: "/ui/api/v1/purchase_requests/now/",
        method: "POST",
        data: {
          currency: User.getDefaultCurrency?.label,
          available_advance,
        },
      });
      this.requestCreated = request_number;
      applaudStore.getApplaud();
      this.errorOnCreate = false;
    } catch (e) {
      this.errorOnCreate = true;
    } finally {
      this.requestCreating = false;
    }
  };

  getPlatformsData = async () => {
    try {
      this.loadingData = true;
      // @ts-ignore
      const data: ModalData = await authorizedRequest({
        url: "/ui/api/v1/purchase_request/available_advance/",
        params: {
          currency: User?.me?.company?.settings?.operating_currency,
        },
      });

      const preparedPlatforms = data.platforms.map((item) => {
        return {
          available_receivable: +item.available_receivable,
          available_advance: +item.available_advance,
          initialAvailableAdvance: +item.available_advance,
          initialAvailableReceivable: +item.available_receivable,
          purchase_price_percentage: +item.purchase_price_percentage,
          currency: item.currency,
          funding_amount: +item.funding_amount,
          platform: item.platform,
          platform_id: item.platform_id,
          platform_logo: item.platform_logo,
          purchase_percentage: +item.purchase_percentage,
          initialAdvance: +item.available_advance,
          totalInitialAdvance: +item.available_advance,
          relativePercent: 100,
          max: +item.available_advance,
          payment_date: item.payment_date,
        };
      });
      const fullData = {
        ...data,
        platforms: preparedPlatforms,
      };

      // @ts-ignore
      this.data = fullData;
      // @ts-ignore
      this.staticData = fullData;
      this.recalculatePlatformAdvance();
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingData = false;
    }
  };

  staticData: ModalData | null = null;

  totalAdvance: number = 0;

  get getTotalCommission() {
    return (
      (this.data &&
        this.data.platforms.reduce((prev, curr) => {
          return prev + +curr.available_receivable * curr.purchase_percentage;
        }, 0)) ||
      0
    );
  }

  get totalAdvanceStatic() {
    if (!this.staticData?.platforms) return null;
    return (
      this.staticData &&
      this.staticData.platforms.reduce((prev, curr) => {
        return prev + curr.available_advance;
      }, 0)
    );
  }

  get availableReceivablePercent() {
    if (!this.staticData?.platforms) return 0;
    const percent = (this.totalAdvanceStatic || 0) / 100;
    return this.data.min_threshold / percent;
  }

  get getTotalAdvance() {
    return (
      (this.data &&
        this.data.platforms.reduce((prev, curr) => {
          return prev + curr.available_advance;
        }, 0)) ||
      0
    );
  }

  get getTotalAvailableReceivable() {
    // @ts-ignore
    return (
      (this.data &&
        this.data.platforms.reduce((prev, curr) => {
          return prev + curr.available_receivable;
        }, 0)) ||
      0
    );
  }

  get getStaticPlatformById() {
    const items: { [key: string]: Platform } = {};
    this.staticData &&
      this.staticData.platforms.forEach((platform) => {
        items[platform.platform_id] = platform;
      });
    return items;
  }

  totalPercent: number | number[] = 100;

  totalPercentChange = false;

  recalculateAdvance = () => {
    if (!this.data?.platforms) return;
    // @ts-ignore
    this!.data!.platforms = this!.data!.platforms.map((platform) => {
      const localAvailableAdvance: number =
        this.getStaticPlatformById &&
        this.getStaticPlatformById[platform.platform_id]
          .initialAvailableAdvance;
      const localInitialAvailableAdvance: number =
        this.getStaticPlatformById &&
        this.getStaticPlatformById[platform.platform_id].initialAdvance;
      const localInitialAvailableReceivable: number =
        this.getStaticPlatformById &&
        this.getStaticPlatformById[platform.platform_id]
          .initialAvailableReceivable;
      return {
        ...platform,
        relativePercent: platform.relativePercent === 0 ? 0 : 100,
        initialAdvance:
          platform.relativePercent === 0
            ? 0
            : typeof this.totalPercent === "number" &&
              (localInitialAvailableAdvance / 100) * this.totalPercent,
        available_advance:
          platform.relativePercent === 0
            ? 0
            : typeof this.totalPercent === "number" &&
              (localAvailableAdvance / 100) * this.totalPercent,
        available_receivable:
          platform.relativePercent === 0
            ? 0
            : typeof this.totalPercent === "number" &&
              (localInitialAvailableReceivable / 100) * this.totalPercent,
      };
    });
  };

  get totalAvailableAdvance() {
    return (
      (this.data &&
        this.data.platforms.reduce((prev, curr) => {
          return prev + curr.initialAdvance;
        }, 0)) ||
      0
    );
  }

  recalculatePlatformAdvance = () => {
    this.totalAdvance =
      (this.data &&
        this.data.platforms.reduce((prev, curr) => {
          return prev + curr.available_advance;
        }, 0)) ||
      0;
    const onePercent: number = +((this.totalAdvanceStatic || 0) / 100).toFixed(
      2
    );
    this.totalPercent = +(this.totalAdvance / onePercent).toFixed(2);

    if (!this.data?.platforms) return;
    // @ts-ignore
    this!.data!.platforms = this!.data!.platforms.map((platform) => {
      const localInitialAvailableAdvance: number =
        this.getStaticPlatformById &&
        this.getStaticPlatformById[platform.platform_id].initialAdvance;
      const localInitialAvailableReceivable: number =
        this.getStaticPlatformById &&
        this.getStaticPlatformById[platform.platform_id]
          .initialAvailableReceivable;
      return {
        ...platform,
        initialAdvance:
          platform.relativePercent === 0
            ? 0
            : typeof this.totalPercent === "number" &&
              (localInitialAvailableAdvance / 100) *
                +this.totalPercent.toFixed(2),
        available_receivable:
          platform.relativePercent === 0
            ? 0
            : typeof this.totalPercent === "number" &&
              (localInitialAvailableReceivable / 100) *
                platform.relativePercent,
      };
    });
  };
}

const platformStore = new Platforms();
export default platformStore;
