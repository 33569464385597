import { observer } from "mobx-react";
import { makeAutoObservable } from "mobx";

import Step1 from "./Step1";

import s from "./styles.module.scss";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { authorizedRequest } from "utils/authorization";
import { isExist } from "pages/Onboarding";
class AppInfoBC {
  constructor() {
    makeAutoObservable(this);
  }

  isModalFillShowed = false;
  page = 1;

  values = {
    distribution: {
      appleStore: false,
      appleStoreComment: "",
      googleStore: false,
      googleStoreComment: "",
      steam: false,
      steamComment: "",
      other: false,
      otherComment: "",
    },
    monetization: "",
    uaSpend: {
      facebook: false,
      googleAds: false,
      appleSearchAds: false,
      snapchat: false,
      tikTok: false,
      unityAds: false,
      dontKnow: false,
      other: false,
    },
    mmp: {
      adjust: false,
      appsflyer: false,
      tenjin: false,
      kochava: false,
      branch: false,
      firebase: false,
      dontKnow: false,
      other: false,
    },
    additionalInfo: "",
  };

  isStepIsValid = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  };

  isDataSended = false;

  get isLastStep() {
    return this.page === 5;
  }

  get isAppInfoValid() {
    return (
      (this.isStepIsValid[1] &&
        this.isStepIsValid[2] &&
        this.isStepIsValid[3] &&
        this.isStepIsValid[4] &&
        this.isStepIsValid[5]) ||
      ([
        this.values.distribution.appleStoreComment,
        this.values.distribution.googleStoreComment,
        this.values.distribution.steamComment,
        this.values.distribution.otherComment,
      ].some((el) => el?.length > 0) &&
        this.values.monetization.length > 0 &&
        Object.values(this.values.uaSpend).some((el) => el === true) &&
        Object.values(this.values.mmp).some((el) => true))
    );
  }

  get isSteamApplied() {
    return isExist(this.values.distribution.steam);
  }

  get isMonetizationNotPremium() {
    return this.values.monetization && this.values.monetization !== "Premium";
  }

  getJSON = () => {
    // if (this.isSteamApplied) {
    //   return {
    //     distribution: this.values.distribution,
    //   };
    // }

    // if (!this.isMonetizationNotPremium) {
    //   return {
    //     distribution: this.values.distribution,
    //     monetization: this.values.monetization,
    //   };
    // }

    return {
      distribution: this.values.distribution,
      monetization: this.values.monetization,
      uaSpend: this.values.uaSpend,
      mmp: this.values.mmp,
      additionalInfo: this.values.additionalInfo,
    };
  };

  async sendData(companyId: string) {
    await authorizedRequest({
      url: `/v1/onboarding/${companyId}/questionary/app`,
      method: "POST",
      service: "fca",
      data: this.getJSON(),
    });
  }

  async getData(companyId: string) {
    let result;
    try {
      result = await authorizedRequest({
        url: `/v1/onboarding/${companyId}`,
        method: "GET",
        service: "fca",
        data: this.getJSON(),
      });
    } catch (e) {
      console.log(e);
    }

    if (result) {
      if (result?.app_questionary?.distribution !== null) {
        this.values.distribution = result.app_questionary.distribution;
      }

      if (result?.app_questionary?.monetization !== null) {
        this.values.monetization = result.app_questionary.monetization;
      }

      if (result?.app_questionary?.uaSpend !== null) {
        this.values.uaSpend = result.app_questionary.uaSpend;
      }

      if (result?.app_questionary?.mmp !== null) {
        this.values.mmp = result.app_questionary.mmp;
      }

      if (result?.app_questionary?.additionalInfo !== null) {
        this.values.additionalInfo = result.app_questionary.additionalInfo;
      }

      this.isDataSended = true;
    }
  }
}

export const appInfoInst = new AppInfoBC();

const AppInfo = observer(() => {
  const getPage = (page: number) => {
    switch (page) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <Step5 />;
      default:
        return <div />;
    }
  };

  return <div className={s.container}>{getPage(appInfoInst.page)}</div>;
});

export default AppInfo;
