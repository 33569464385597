import { observer } from "mobx-react";
import React from "react";
import { Button, ArrowLeftIcon, Modal } from "@gamesb42/ui-kit";
import { about } from "../../index";
import connectionStore from "../../../connectionStore";
import connectSources from "../../../../../../store/ConnectSources";
import appStore from "../../../../../../store/App";
import styles from "./styles.module.scss";

type Page = 1 | 2 | 3 | 4 | 5 | 6;

export const Paginator = observer(() => {
  const emptyArr: number[] = [];
  if (about.currency.length === 1) {
    emptyArr.length = 6;
  } else if (about.currency.length === 2) {
    emptyArr.length = 7;
  } else {
    emptyArr.length = 5;
  }
  emptyArr.fill(1, 1);
  const isSavedData = about.isPersonalDataValid && about.isCompanyInfoValid;
  return (
    <div className={styles.paginatorHolder}>
      <Button
        icon={<ArrowLeftIcon size={16} />}
        size="large"
        onClick={() => {
          if (about.page > 1) {
            about.page -= 1;
          } else {
            if (!connectSources.accountsWithCredentials.length) {
              appStore.showAccountsDrawer = false;
            } else {
              connectionStore.currentScreen = "infoScreen";
            }
            connectionStore.currentScreen = "infoScreen";
          }
        }}
        className={styles.backBtn}
      >
        Go back
      </Button>

      <div className={styles.dotsHolder}>
        {emptyArr.map((item, idx) => {
          return (
            <div
              className={idx === about.page ? styles.active : styles.inactive}
            />
          );
        })}
      </div>

      <Button
        type="primary"
        violet
        size="large"
        onClick={async () => {
          if (about.isLastStep) {
            appStore.showConfirmOnboardingModal = true;
            return;
          }
          about.page += 1;
        }}
        className={styles.nextBtn}
        disabled={
          !about.isStepIsValid[about.page as Page] ||
          about.sendCompanyInfoLoading
        }
      >
        {about.isLastStep ? (isSavedData && "Submit") || "Save" : "Proceed"}
      </Button>
    </div>
  );
});
