import React, { ForwardedRef } from 'react';
import styles from './styles.module.scss';

const Table = ({ children, className = '' }:Props) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {children}
    </div>
  );
};

type Props = {
  children: React.ReactNode,
  className?: string,
}

export const Header = ({ children, className = '' }:Props) => {
  return (
    <div className={`${styles.head} ${className}`}>
      {children}
    </div>
  );
};

export const Body = React.forwardRef((props:Props, ref:ForwardedRef<HTMLElement>) => {
  const { children, className = '' } = props;
  return (
    // @ts-ignore
    <div className={`${styles.body} ${className}`} ref={ref}>
      {children}
    </div>
  );
});

export default Table;
