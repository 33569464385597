import { observer } from "mobx-react";
import { makeAutoObservable } from "mobx";

import Step1 from "./Step1";

import styles from "../AboutCompany/styles.module.scss";
import { authorizedRequest } from "utils/authorization";
import { getToken } from "utils/token";
import axios from "axios";
import userStore from "store/User";
import appStore from "store/App";
import { about } from "../AboutCompany";
import Auth from "store/Auth";

type CompanySignatory = {
  alreadyCreated: boolean;
  fullName: string;
  position: string;
  email: string;
  id: string;
  companySignatoryFile: any;
};

class CompanySignatoryBC {
  constructor() {
    makeAutoObservable(this);
  }

  values: CompanySignatory = {
    alreadyCreated: false,
    fullName: "",
    position: "",
    email: "",
    id: "",
    companySignatoryFile: {
      name: "",
      value: "",
      size: 0,
    },
  };

  isStepIsValid = {
    1: false,
  };

  isDataSended = false;
  //@ts-ignore
  init(contact) {
    if (contact && contact?.full_name) {
      this.values.fullName = contact.full_name;
      this.values.position = contact.position;
      this.values.email = contact.email;
      this.values.id = contact.id;
      this.values.alreadyCreated = true;
      this.isDataSended = true;
    }
  }

  async sendFile() {
    // work with file -->
    let reqStatus;
    if (this.values.companySignatoryFile.size > 0) {
      if (this.values.companySignatoryFile?.docId) {
        await authorizedRequest({
          url: `/v1/documents/${this.values.companySignatoryFile?.docId}`,
          method: "DELETE",
          service: "fca",
          data: {},
        });
      }

      let data = new FormData();

      if (this.values.companySignatoryFile.value.size > 0) {
        data.append(
          "file",
          this.values.companySignatoryFile.value,
          this.values.companySignatoryFile.name
        );
      } else {
        data.append("file", "");
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SCA_URL}/v1/documents/?doc_type=signatory-confirming-document`,
        headers: {
          accept: "application/json",
          authorization: `Bearer ${Auth.scaToken}`,
        },
        data,
      };

      const result = await axios.request(config);
      reqStatus = result.status;

      companySignatoryInst.values.companySignatoryFile.id =
        result.data?.file?.id;
      companySignatoryInst.values.companySignatoryFile.docId = result.data?.id;
      companySignatoryInst.values.companySignatoryFile.name =
        result.data?.file?.name;
      companySignatoryInst.values.companySignatoryFile.size = 1;
    }
    // work with file <--

    // work with contact -->
    function setContact(data: any) {
      if (data) {
        about.values.contacts.push({
          id: data?.id,
          type: data?.type,
          contact_phone: data?.contact_phone,
          email: data?.email,
          full_name: data?.full_name,
          position: data?.position,
        });
      }
    }

    let reqStatus2;
    if (this.values.alreadyCreated) {
      // console.log("update");
      try {
        const updateResult = await authorizedRequest({
          url: `/v1/companies/${userStore.me?.company?.id.toString()}/contacts/${
            this.values.id
          }`,
          service: "fca",
          method: "PATCH",
          data: {
            type: "signer",
            full_name: this.values.fullName,
            position: this.values.position,
            email: this.values.email,
            contact_phone: "",
          },
        });
        setContact(updateResult);

        reqStatus2 = 201;
      } catch (e) {
        reqStatus2 = 400;
      }
    } else {
      // console.log("create");
      try {
        const updateResult = await authorizedRequest({
          url: `/v1/companies/${userStore.me?.company?.id.toString()}/contacts`,
          service: "fca",
          method: "POST",
          data: {
            type: "signer",
            full_name: this.values.fullName,
            position: this.values.position,
            email: this.values.email,
            contact_phone: "",
          },
        });

        setContact(updateResult);

        reqStatus2 = 201;
      } catch (e) {
        reqStatus2 = 400;
      }
    }
    // work with contact <--

    if (reqStatus === 201 || reqStatus2 === 201) {
      appStore.setNotification({
        type: "success",
        title: `Company Signatory information was sent successful`,
        width: 380,
      });
    }
  }
}

export const companySignatoryInst = new CompanySignatoryBC();

const CompanySignatory = observer(() => {
  return (
    <div className={styles.container}>
      <Step1 />
    </div>
  );
});

export default CompanySignatory;
