import React from 'react';
import { ReactComponent as Logo } from '../../assets/mainLogo.svg';
import Button, { ButtonMedium } from '../../components/Button';
import styles from './styles.module.scss';
import { ReactComponent as Main } from './assets/main_image.svg';
import { ReactComponent as Reload } from './assets/reload.svg';

const Error = () => {
  return (
    <div className={styles.container}>
      <Logo />
      <div className={styles.holder}>
        <div className={styles.main}>
          <Main />
          <p className={styles.text}>Something went wrong. The data were not loaded.<br />
            Please reload the page or come back later
          </p>
          <ButtonMedium renderIcon={() => <Reload />} onClick={() => window.location.reload()}>Reload the page</ButtonMedium>
        </div>
      </div>
    </div>
  );
};

export default Error;
