import React, { FC, Children } from 'react';

export interface ColorPassType {
  splitText: string;
  color?: string;
}

const ColorPass: FC<ColorPassType> = ({ children, splitText, color = 'black' }) => {
  const text = children && children.toString();
  const myRe = new RegExp(splitText.replaceAll('[', '\\['), 'gi');
  let arrayIncludeValues: any[] = [];
  while (myRe.exec(text as string) !== null && splitText) {
    arrayIncludeValues = [...arrayIncludeValues, text && text.slice(myRe.lastIndex - splitText.length, myRe.lastIndex)];
  }
  return (
    <span>
      {Children.toArray(
        text && text.split(myRe).map((val, i) => (
          <>
            <span>{val}</span>
            <span style={{ color, fontWeight: 600 }}>{arrayIncludeValues[i]}</span>
          </>
        )),
      )}
    </span>
  );
};

export default ColorPass;
