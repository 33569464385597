import React, { useEffect, useState } from "react";
import moment from "moment";
import { AoIcon, DownloadIcon, ReportIcon, Skeleton } from "@gamesb42/ui-kit";
import cn from "classnames";
import Table, { Body } from "components/Table";
import Main from "../../layout/Main";
import SideMenu from "../../components/SideMenu";
import { authorizedRequest } from "../../utils/authorization";
import { downloadFileOfBlob } from "../../utils/helpers";
import appStore from "../../store/App";
import SplittedButtons, {
  SplittedButton,
} from "../../components/SplittedButton";
import styles from "./styles.module.scss";

type DocItem = {
  id: string;
  title: string;
  doc_date: string | null;
  name: string;
  file_id: string;
  report_date: string;
  file_meta: {
    friendly_name: string;
  };
};

const ReportingDocuments = () => {
  const [items, setItems] = useState<null | DocItem[]>(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("invoice_report");
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { records } = await authorizedRequest({
          url: `/ui/api/v1/monthly_invoice_reports?report_type=${filter}`,
        });
        setItems(records);
      } catch (e) {
        setItems([]);
        appStore.setNotification({
          title: "An error occurred",
          type: "error",
          message: "Please check the connection",
          width: 300,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [filter]);

  const onDownloadFile = async (fileId: string, fileName: string) => {
    const res = await authorizedRequest({
      url: `/ui/api/v1/file_storage/${fileId}`,
      responseType: "blob",
    });
    downloadFileOfBlob(res, "application/pdf", fileName);
  };

  const contentBody = () => {
    return (
      <div className={styles.tableSize}>
        {(!loading &&
          items?.length &&
          items.map((doc) => {
            return (
              <div
                key={doc.id}
                className={styles.row}
                onClick={() => onDownloadFile(doc.file_id, doc.name)}
              >
                <div className={styles.firstColumn}>
                  {(filter === "invoice_report" && (
                    <div className={styles.aoIcon}>
                      <AoIcon />
                    </div>
                  )) || (
                    <div className={styles.reportIcon}>
                      <ReportIcon />
                    </div>
                  )}
                </div>
                <div className={styles.secondColumn}>
                  <span className={styles.linked} role="link" tabIndex={0}>
                    {(filter === "invoice_report" &&
                      doc.file_meta.friendly_name) ||
                      moment(doc.report_date)
                        .subtract(1, "month")
                        .format("MMMM YYYY")}
                  </span>
                  <button className={styles.btn}>
                    <DownloadIcon size={24} />
                  </button>
                  {/*}*/}
                </div>
              </div>
            );
          })) ||
          null}
      </div>
    );
  };
  return (
    <Main>
      <SideMenu />
      <div className={styles.container}>
        <h1 className={styles.title}>Reporting Documents</h1>
        <Table>
          <div className={styles.filtersHolder}>
            <SplittedButtons>
              <SplittedButton
                isActive={filter === "invoice_report"}
                onClick={() => setFilter("invoice_report")}
              >
                Invoices
              </SplittedButton>
              <SplittedButton
                isActive={filter === "monthly_report"}
                onClick={() => setFilter("monthly_report")}
              >
                Reports
              </SplittedButton>
            </SplittedButtons>
          </div>

          <div className={cn(styles.tableSize, styles.header)}>
            <div>
              {(filter === "invoice_report" && "Invoice No.") || "Report No."}
            </div>
          </div>
          {!loading && Array.isArray(items) && contentBody()}

          {!loading && items && !items.length && <EmptyState />}

          {loading && <LoadingState />}
        </Table>
      </div>
    </Main>
  );
};

const LoadingState = () => {
  const data: [] | number[] = [];
  data.length = 5;
  // @ts-ignore
  data.fill(1, 0, 10);
  return (
    <Body className={styles.tableSize}>
      {data.map((item) => {
        return (
          <div className={styles.loaderRow}>
            <div className={styles.firstColumn}>
              <Skeleton type="icon24" />
            </div>
            <div className={cn(styles.secondColumn, styles.loaderItem)}>
              <Skeleton type="text14" className={styles.loaderItem} />
            </div>
          </div>
        );
      })}
    </Body>
  );
};

const EmptyState = () => {
  return (
    <div className={styles.emptyContainer}>
      <p className={styles.emptyText}>You have no Reporting Documents yet</p>
    </div>
  );
};

export default ReportingDocuments;
