//@ts-nocheck
import { observer } from "mobx-react";

import { corparateDoc } from "../..";

import { ReactComponent as FileIcon } from "./assets/file.svg";
import { ReactComponent as PdfIcon } from "./assets/pdf.svg";
import { ReactComponent as PngIcon } from "./assets/png.svg";
import { ReactComponent as JpegIcon } from "./assets/jpeg.svg";
import { ReactComponent as GifIcon } from "./assets/gif.svg";
import { ReactComponent as DocIcon } from "./assets/doc.svg";
import { ReactComponent as DocxIcon } from "./assets/docx.svg";
import { ReactComponent as XlsIcon } from "./assets/xls.svg";
import { ReactComponent as XlsxIcon } from "./assets/xlsx.svg";
import { ReactComponent as DjvuIcon } from "./assets/djvu.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

import s from "./styles.module.scss";

const FileNameMulty = observer(({ fileIndex, clearFiles, needDel = true }) => {
  const calcTypeIcon = (type) => {
    switch (type) {
      case "image/png":
        return <PngIcon />;
      case "application/pdf":
        return <PdfIcon />;
      case "image/jpeg":
        return <JpegIcon />;
      case "image/gif":
        return <GifIcon />;
      case "application/msword":
        return <DocIcon />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <DocxIcon />;
      case "application/vnd.ms-excel":
        return <XlsIcon />;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <XlsxIcon />;
      case "image/vnd.djvu":
        return <DjvuIcon />;
      default:
        return <FileIcon />;
    }
  };

  return (
    <div
      style={{ border: needDel ? "1px solid #eceef2" : "none" }}
      className={s.uploaded}
    >
      <div style={{ display: "flex", gap: "8px" }}>
        <div style={{ width: "40px" }}>
          {calcTypeIcon(
            corparateDoc.values?.certificateOfShareholdersFiles[fileIndex]
              ?.value?.type
          )}
        </div>
        <span className={s.textUploaded} style={{ wordWrap: "anywhere" }}>
          {corparateDoc.values?.certificateOfShareholdersFiles[fileIndex]?.name}
        </span>
      </div>
      {needDel && (
        <button
          className={s.btn}
          onClick={() => {
            clearFiles(fileIndex);
          }}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
});

export default FileNameMulty;
