import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
// @ts-ignore
import * as Validator from "validatorjs";
import { toJS } from "mobx";
import { Title3 } from "../Typography";
import TextInput from "../TextInput";
import { Currency as CurrencyType } from "../../../../types";
import { about } from "./index";
import styles from "./styles.module.scss";

const AccountEur = observer(() => {
  // const rules = {
  //   bank_eur: 'required',
  //   swift_eur: 'required',
  //   iban_eur: 'required',
  //   beneficiary_name_eur: 'required',
  //   beneficiary_address_eur: 'required',
  // };

  const rules = {
    bank_name: "required",
    swift: "required|regex:/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/",
    account: "required",
  };
  const bank = about.values.bank_details.find(
    (item) => item.currency === "EUR"
  );

  const validation = useMemo(
    () =>
      new Validator(toJS(bank), rules, {
        required: "This field is required",
      }),
    [bank?.bank_name, bank?.swift, bank?.account]
  );
  const isValid = validation.passes();

  useEffect(() => {
    const step = 5;
    about.isStepIsValid[step] = isValid;
  }, [isValid]);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Title3 className={styles.subTitle}>Your EUR Bank Account</Title3>
      </div>
      <TextInput
        onChange={(e) => {
          bank!.bank_name = e;
        }}
        label="Bank name*"
        dataTest="bankName"
        className={styles.textField}
        value={bank?.bank_name}
        error={validation.errors.first("bank_name")}
      />

      <TextInput
        onChange={(e) => {
          bank!.swift = e;
        }}
        label="SWIFT code*"
        dataTest="swiftCode"
        className={styles.textField}
        value={bank!.swift}
        error={validation.errors.first("swift")}
      />

      <TextInput
        onChange={(e) => {
          bank!.account = e;
        }}
        label="IBAN or account number*"
        dataTest="iban"
        className={styles.textField}
        value={bank!.account}
        error={validation.errors.first("account")}
      />
      <TextInput
        onChange={(e) => {
          bank!.bank_address = e;
        }}
        label="Bank address"
        dataTest="iban"
        className={styles.textField}
        value={bank!.bank_address}
        // error={validation.errors.first("account")}
      />
    </>
  );
});

export default AccountEur;
