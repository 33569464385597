import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as Question } from './assets/question.svg';

type TooltipProps = {
  className?: any,
  title: any,
}

const TooltipContainer = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement="top" classes={{ popper: className }} arrow><Question style={{ position: 'relative', top: 1, cursor: 'pointer' }} /></Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#131416',
    color: '#ffffff',
    fontSize: 16,
    padding: '4px 12px',
    fontFamily: 'MullerRegular',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#131416',
  },
}));

export default TooltipContainer;
