//@ts-nocheck
import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Input, Select, Radio } from "@gamesb42/ui-kit";

import UploadFile from "components/UploadFile";
import { director } from ".";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as Pdf } from "./assets/pdf.svg";
import FileNameCorp from "./components/FileNameCorp";

import s from "./styles.module.scss";

const AddPerson = observer(() => {
  const [render, setRender] = useState(false);
  const [showFiles, setShowFiles] = useState(true);
  const [currentPosition, setCurrentPosition] = useState("");

  console.log({ currentPosition });
  console.log(`current person: ${director.values.currentPerson}`);

  // init persons
  useEffect(() => {
    // new person
    if (director.values.persons.length === 0) {
      for (
        let i = 0;
        i < director.numberOfUBOs + director.numberOfDirectors;
        i++
      ) {
        director.values.persons.push({
          fullName: "",
          positionInCompany: "",
          businessControl: null,
          profOfId: {
            id: "",
            docId: "",
            name: "",
            value: "",
            size: 0,
          },
          profOfAddress: {
            id: "",
            docId: "",
            name: "",
            value: "",
            size: 0,
          },
          resume: {
            id: "",
            docId: "",
            name: "",
            value: "",
            size: 0,
          },
          profOfFunds: {
            id: "",
            docId: "",
            name: "",
            value: "",
            size: 0,
          },
        });
      }
    }

    setRender(true);
  }, []);

  // validation
  useEffect(() => {
    if (render) {
      const conditionForUpload =
        director.values.persons[director.values.currentPerson]?.fullName
          ?.length > 0 &&
        director.values.persons[director.values.currentPerson].positionInCompany
          .length > 0 &&
        director.values.persons[director.values.currentPerson]?.profOfId?.size >
          0 &&
        director.values.persons[director.values.currentPerson]?.profOfAddress
          ?.size > 0;

      if (conditionForUpload) {
        director.isCorpValid = true;
      } else {
        director.isCorpValid = false;
      }
    }
  }, [
    render,
    director.values.persons[director.values.currentPerson]?.fullName,
    director.values.persons[director.values.currentPerson]?.positionInCompany,
    director.values.persons[director.values.currentPerson]?.profOfId?.name,
    director.values.persons[director.values.currentPerson]?.profOfAddress,
  ]);

  useEffect(() => {
    setCurrentPosition(
      director.values.persons[director.values.currentPerson]?.positionInCompany
    );
  }, [
    director.values.persons[director.values.currentPerson]?.positionInCompany,
  ]);

  // clear files on inputs change
  function clearFiles() {
    director.values.persons[director.values.currentPerson].profOfId.size = 0;
    director.values.persons[
      director.values.currentPerson
    ].profOfAddress.size = 0;
    director.values.persons[director.values.currentPerson].resume.size = 0;
    director.values.persons[director.values.currentPerson].profOfFunds.size = 0;
  }

  return (
    render && (
      <div className={s.addPersonBlock}>
        <div className={s.row}>
          <div className={s.header}>
            Person {director.values.currentPerson + 1}
          </div>
          <Input
            label="Full name*"
            size="large"
            value={
              director.values.persons[director.values.currentPerson].fullName
            }
            onChange={(e) => {
              director.values.persons[director.values.currentPerson].fullName =
                e.target.value;
              if (director.isDataSended) clearFiles();
            }}
          />
        </div>

        <div className={s.row}>
          <Select
            label={"Position in the company*"}
            options={[
              { label: "UBO", value: "UBO" },
              { label: "Director", value: "Director" },
              { label: "CEO", value: "CEO" },
              { label: "General manager", value: "General manager" },
            ]}
            size="large"
            value={
              director.values.persons[director.values.currentPerson]
                .positionInCompany
            }
            onChange={(e) => {
              director.values.persons[
                director.values.currentPerson
              ].positionInCompany = e;
              setShowFiles(true);
              setCurrentPosition(e);
              if (director.isDataSended) clearFiles();
            }}
          />
        </div>

        {showFiles && (
          <>
            <div>
              <div className={s.label}>Proof of ID*</div>
              <div className={s.uploadHolder}>
                {(director.values.persons[director.values.currentPerson]
                  ?.profOfId?.size === 0 && (
                  <UploadFile
                    dragText="Drag and drop file*"
                    uploadText={`Upload a clear copy of the person’s passport
              <br/>50 Mb max. weight`}
                    onUpload={(e, file) => {
                      director.values.persons[
                        director.values.currentPerson
                      ].profOfId = {
                        name: file.name,
                        size: file.size,
                        value: file,
                      };
                    }}
                  />
                )) || (
                  <FileNameCorp fileName="profOfId" clearFiles={clearFiles} />
                )}
              </div>
            </div>

            <div>
              <div className={s.label}>Proof of Address*</div>
              <div className={s.uploadHolder}>
                {(director.values.persons[director.values.currentPerson]
                  .profOfAddress?.size === 0 && (
                  <UploadFile
                    dragText="Drag and drop file*"
                    uploadText={`Upload utility bill or bank statement issued in last 90 days (mobile phones bills and P.O. Box addresses <br />are not accepted). 50 Mb max. weight`}
                    onUpload={(e, file) => {
                      director.values.persons[
                        director.values.currentPerson
                      ].profOfAddress = {
                        name: file.name,
                        size: file.size,
                        value: file,
                      };
                    }}
                  />
                )) || (
                  <FileNameCorp
                    fileName="profOfAddress"
                    clearFiles={clearFiles}
                  />
                )}
              </div>
            </div>

            {currentPosition === "UBO" && (
              <>
                <div>
                  <div className={s.label}>Resume/CV</div>
                  <div className={s.uploadHolder}>
                    {(director.values.persons[director.values.currentPerson]
                      .resume?.size === 0 && (
                      <UploadFile
                        dragText="Drag and drop file*"
                        uploadText={`Upload Resume/CV <br/>50 Mb max. weight`}
                        onUpload={(e, file) => {
                          director.values.persons[
                            director.values.currentPerson
                          ].resume = {
                            name: file.name,
                            size: file.size,
                            value: file,
                          };
                        }}
                      />
                    )) || (
                      <FileNameCorp fileName="resume" clearFiles={clearFiles} />
                    )}
                  </div>
                </div>

                <div>
                  <div className={s.label}>Proof of Funds</div>
                  <div className={s.uploadHolder}>
                    {(director.values.persons[director.values.currentPerson]
                      .profOfFunds?.size === 0 && (
                      <UploadFile
                        dragText="Drag and drop file*"
                        uploadText={`Upload proof of funds (A bank statement, tax declarations, security statement, or custody statement <br />usually qualify as proof of funds). 50 Mb max. weight`}
                        onUpload={(e, file) => {
                          director.values.persons[
                            director.values.currentPerson
                          ].profOfFunds = {
                            name: file.name,
                            size: file.size,
                            value: file,
                          };
                        }}
                      />
                    )) || (
                      <FileNameCorp
                        fileName="profOfFunds"
                        clearFiles={clearFiles}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: "8px" }}>
                    More than 10% shares or exercises control over the
                    business*?
                  </div>
                  <Radio.Group
                    value={
                      director.values.persons[director.values.currentPerson]
                        .businessControl
                    }
                  >
                    <Radio
                      onChange={() => {
                        director.values.persons[
                          director.values.currentPerson
                        ].businessControl = true;

                        if (director.isDataSended) clearFiles();
                      }}
                      value={true}
                    >
                      Yes
                    </Radio>
                    <Radio
                      value={false}
                      onChange={() => {
                        director.values.persons[
                          director.values.currentPerson
                        ].businessControl = false;
                        if (director.isDataSended) clearFiles();
                      }}
                    >
                      No
                    </Radio>
                  </Radio.Group>
                </div>
              </>
            )}
          </>
        )}
      </div>
    )
  );
});

export default AddPerson;
