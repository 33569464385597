//@ts-nocheck
import axios from "axios";
import { observer } from "mobx-react";
import { makeAutoObservable } from "mobx";

import { authorizedRequest } from "utils/authorization";
import Auth from "store/Auth";
import IEDocs from "./IEDocs";
import AddPerson from "./AddPerson";
import userStore from "../../../../store/User";

import s from "./styles.module.scss";
import CorpDocs from "./CorpDocs";

class Director {
  constructor() {
    makeAutoObservable(this);
  }

  page = 1;
  isDataSended = false;
  numberOfUBOs = null;
  numberOfDirectors = null;
  isIEDataValid = false;
  isCorpValid = false;

  numberOfUBOsBefore = null;
  numberOfDirectorsBefore = null;

  values = {
    // IE data -->
    profOfId: {
      id: "",
      docId: "",
      name: "",
      value: "",
      size: 0,
    },
    profOfAddress: {
      id: "",
      docId: "",
      name: "",
      value: "",
      size: 0,
    },
    profOfFunds: {
      id: "",
      docId: "",
      name: "",
      value: "",
      size: 0,
    },
    // IE data <--

    needtoDelete: [],
    directors: [], // resived data from server
    persons: [], // data from user forms flat
    currentPerson: 0,
  };

  get isLastStep() {
    if (this.numberOfUBOs + this.numberOfDirectors + 1 === this.page) {
      return true;
    } else {
      return false;
    }
  }

  clearData() {
    this.page = 1;
    this.isDataSended = false;
    this.numberOfUBOs = null;
    this.numberOfDirectors = null;

    this.numberOfUBOsBefore = null;
    this.numberOfDirectorsBefore = null;

    this.values = {
      profOfId: {
        id: "",
        docId: "",
        name: "",
        value: "",
        size: 0,
      },
      profOfAddress: {
        id: "",
        docId: "",
        name: "",
        value: "",
        size: 0,
      },
      profOfFunds: {
        id: "",
        docId: "",
        name: "",
        value: "",
        size: 0,
      },
      needtoDelete: [],
      needtoDelete: [],
      directors: [], // resived data from server
      persons: [], // data from user forms flat
      currentPerson: 0,
    };
  }

  fillIEFields(el) {
    switch (el.doc_type) {
      case "prof-of-id":
        if (el.file) {
          this.values.profOfId.id = el.file.id;
          this.values.profOfId.name = el.file.name;
          this.values.profOfId.size = 1;
        }
        this.values.profOfId.docId = el.id;
        break;
      case "prof-of-address":
        if (el.file) {
          this.values.profOfAddress.id = el.file.id;
          this.values.profOfAddress.name = el.file.name;
          this.values.profOfAddress.size = 1;
        }
        this.values.profOfAddress.docId = el.id;
        break;
      case "prof-of-funds":
        if (el.file) {
          this.values.profOfFunds.id = el.file.id;
          this.values.profOfFunds.name = el.file.name;
          this.values.profOfFunds.size = 1;
        }
        this.values.profOfFunds.docId = el.id;
        break;
      default:
        null;
    }
  }

  fillCorpFields(el, newDirector) {
    switch (el.doc_type) {
      case "prof-of-id":
        if (el.file) {
          newDirector.profOfId.id = el.file.id;
          newDirector.profOfId.name = el.file.name;
          newDirector.profOfId.size = 1;
          newDirector.profOfId.value = { type: el.file.media_type };
        }
        newDirector.profOfId.docId = el.id;
        break;
      case "prof-of-address":
        if (el.file) {
          newDirector.profOfAddress.id = el.file.id;
          newDirector.profOfAddress.name = el.file.name;
          newDirector.profOfAddress.size = 1;
          newDirector.profOfAddress.value = { type: el.file.media_type };
        }
        newDirector.profOfAddress.docId = el.id;
        break;
      case "prof-of-funds":
        if (el.file) {
          newDirector.profOfFunds.id = el.file.id;
          newDirector.profOfFunds.name = el.file.name;
          newDirector.profOfFunds.size = 1;
          newDirector.profOfFunds.value = { type: el.file.media_type };
        }
        newDirector.profOfFunds.docId = el.id;
        break;
      case "resume-cv":
        if (el.file) {
          newDirector.resume.id = el.file.id;
          newDirector.resume.name = el.file.name;
          newDirector.resume.size = 1;
          newDirector.resume.value = { type: el.file.media_type };
        }
        newDirector.resume.docId = el.id;
        break;
      default:
        null;
    }
  }

  async getDirectorData(companyId: string) {
    this.clearData();
    const result = await authorizedRequest({
      url: `/v1/companies/${companyId}/directors`,
      method: "GET",
      service: "fca",
      data: {},
    });
    this.values.directors = result.directors;

    //для IE берём 1 запись и парсим её
    if (userStore.me.company.details.company_form === "sole-proprietor") {
      if (
        result.directors.length > 0 &&
        result.directors[0].documents.length > 0
      ) {
        result.directors[0].documents.forEach((el) => {
          this.fillIEFields(el);
        });
        this.isDataSended = true;
      }
    } else {
      if (result.directors.length > 0) {
        result.directors.forEach((director, i) => {
          const mapPosition = (position) => {
            switch (position) {
              case "ubo":
                return "UBO";
              case "director":
                return "Director";
              case "ceo":
                return "CEO";
              case "general-manager":
                return "General manager";
              default:
                return "";
            }
          };

          const newDirector = {
            id: director.id,
            fullName: director.full_name,
            positionInCompany: mapPosition(director.position_in_company),
            businessControl: director.business_control,
            profOfId: {
              id: "",
              docId: "",
              name: "",
              value: "",
              size: 0,
            },
            profOfAddress: {
              id: "",
              docId: "",
              name: "",
              value: "",
              size: 0,
            },
            resume: {
              id: "",
              docId: "",
              name: "",
              value: "",
              size: 0,
            },
            profOfFunds: {
              id: "",
              docId: "",
              name: "",
              value: "",
              size: 0,
            },
          };
          director.documents.forEach((document) => {
            this.fillCorpFields(document, newDirector);
          });
          if (director.position_in_company === "ubo") {
            this.numberOfUBOs += 1;
          } else {
            this.numberOfDirectors += 1;
          }
          this.values.persons.push(newDirector);
        });
        this.isDataSended = true;
        this.numberOfUBOsBefore = this.numberOfUBOs;
        this.numberOfDirectorsBefore = this.numberOfDirectors;
      }
    }
  }

  async sendFiles() {
    const reqArr = [];

    if (userStore.me.company.details.company_form !== "corporation") {
      const mappingDocType = {
        profOfId: "prof-of-id",
        profOfAddress: "prof-of-address",
        profOfFunds: "prof-of-funds",
        resume: "resume-cv",
      };

      if (this.values.directors.length > 0) {
        // need to del director if some file changed -->
        const isChanged = ["profOfId", "profOfAddress", "profOfFunds"].some(
          (el) => {
            return (
              this.values.directors[0].documents.find(
                (doc) => doc.doc_type === mappingDocType[el]
              ).file.name !== this.values[el].name
            );
          }
        );

        if (isChanged) {
          let config = {
            method: "delete",
            url: `${process.env.REACT_APP_SCA_URL}/v1/companies/director/${this.values.directors[0].id}`,
            headers: {
              authorization: `Bearer ${Auth.scaToken}`,
            },
          };

          await axios
            .request(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // <--
      }

      const params = new URLSearchParams({
        full_name: "IE ubo",
        position_in_company: "ubo",
        business_control: true,
      }).toString();

      const newDirector = await axios
        .request({
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_SCA_URL}/v1/companies/director?${params}`,
          headers: {
            accept: "application/json",
            authorization: `Bearer ${Auth.scaToken}`,
          },
        })
        .catch((error) => {
          console.log(error);
        });

      for (const [i, el] of Object.keys(mappingDocType).entries()) {
        if (this.values[el]?.value?.size > 0) {
          const doc_type = mappingDocType[el];
          let data = new FormData();

          if (this.values[el].value?.size > 0) {
            data.append("file", this.values[el].value, this.values[el].name);
          } else {
            data.append("file", "");
          }

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SCA_URL}/v1/documents/director/document?director_id=${newDirector.data.id}&doc_type=${doc_type}`,
            headers: {
              accept: "application/json",
              authorization: `Bearer ${Auth.scaToken}`,
            },
            data,
          };

          reqArr.push(axios.request(config));
        }
      }
      // <-- IE end
    } else {
      // create director docs for corps -->
      const mappingDocType = {
        profOfId: "prof-of-id",
        profOfAddress: "prof-of-address",
        profOfFunds: "prof-of-funds",
        resume: "resume-cv",
      };

      // update directors -->
      const result = await authorizedRequest({
        url: `/v1/companies/${
          userStore.me?.company?.id.toString() || ""
        }/directors`,
        method: "GET",
        service: "fca",
        data: {},
      });
      this.values.directors = result.directors;
      // <--

      for (const [i, person] of Object.entries(this.values.persons)) {
        // compare before & after
        if (this.values.directors[i]) {
          const isChanged = this.values.directors[i]
            ? this.findChanges(this.values.directors[i], person)
            : false;

          // del if some changes
          if (isChanged) {
            let config = {
              method: "delete",
              url: `${process.env.REACT_APP_SCA_URL}/v1/companies/director/${this.values.directors[i].id}`,
              headers: {
                authorization: `Bearer ${Auth.scaToken}`,
              },
            };

            await axios
              .request(config)
              .then((response) => {
                console.log(JSON.stringify(response.data));
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            continue;
          }
        }

        // create director -->

        let directorId = "";
        const mapPosition = (position) => {
          switch (position) {
            case "UBO":
              return "ubo";
            case "Director":
              return "director";
            case "CEO":
              return "ceo";
            case "General manager":
              return "general-manager";
            default:
              return "";
          }
        };

        const params = new URLSearchParams({
          full_name: person.fullName,
          position_in_company: mapPosition(person.positionInCompany),
          business_control: person.businessControl
            ? person.businessControl
            : false,
        }).toString();

        const newDirector = await axios
          .request({
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SCA_URL}/v1/companies/director?${params}`,
            headers: {
              accept: "application/json",
              authorization: `Bearer ${Auth.scaToken}`,
            },
          })
          .catch((error) => {
            console.log(error);
          });

        directorId = newDirector.data.id;

        // create files for director -->
        for (const [i, el] of Object.keys(mappingDocType).entries()) {
          if (person[el]?.value?.size > 0) {
            const doc_type = mappingDocType[el];
            let data = new FormData();

            if (person[el].value?.size > 0) {
              data.append("file", person[el].value, person[el].name);
            } else {
              data.append("file", "");
            }

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_SCA_URL}/v1/documents/director/document?director_id=${directorId}&doc_type=${doc_type}`,
              headers: {
                accept: "application/json",
                authorization: `Bearer ${Auth.scaToken}`,
              },
              data,
            };

            // reqArr.push(axios.request(config));
            try {
              await axios.request(config);
            } catch (e) {}
          }
        }
      }

      // <-- end create director with docs
    }

    this.isDataSended = true;
  }

  findChanges(personBefore, personAfter) {
    const isProfOfIdChanged =
      personBefore.documents.find((el) => el.doc_type === "prof-of-id")?.file
        ?.name !== personAfter.profOfId.name &&
      personAfter.profOfId.name.length > 0;

    const isProfOfAddressChanged =
      personBefore.documents.find((el) => el.doc_type === "prof-of-address")
        ?.file?.name !== personAfter.profOfAddress.name &&
      personAfter.profOfAddress.name.length > 0;

    const isProfOfFundsChanged =
      personBefore.documents.find((el) => el.doc_type === "prof-of-funds")?.file
        ?.name !== personAfter.profOfFunds.name &&
      personAfter.profOfFunds.name.length > 0;

    const isResumeChanged =
      personBefore.documents.find((el) => el.doc_type === "resume-cv")?.file
        ?.name !== personAfter.resume.name &&
      personAfter.resume.name.length > 0;

    const isFullNameChanged = personBefore.full_name !== personAfter.fullName;
    const isPositionChanged = personBefore.position !== personAfter.position;
    const isBusinessControllChanged =
      personBefore.business_control !== personAfter.businessControl;

    return (
      isProfOfIdChanged ||
      isProfOfAddressChanged ||
      isProfOfFundsChanged ||
      isResumeChanged ||
      isFullNameChanged ||
      isPositionChanged ||
      isBusinessControllChanged
    );
  }

  async deleteAllDirectors() {
    console.log("call deleteAllDirectors");

    this.isDataSended = false;

    // delete all current directors
    await Promise.all(
      this.values.directors.map((director) => {
        axios
          .request({
            method: "delete",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SCA_URL}/v1/companies/director/${director.id}`,
            headers: {
              accept: "application/json",
              authorization: `Bearer ${Auth.scaToken}`,
            },
          })
          .catch((error) => {
            console.log(error);
          });
      })
    );
  }
}

export const director = new Director();

const UBODirectors = observer(() => {
  const getPage = (page: number) => {
    switch (page) {
      case 1:
        return userStore.me.company.details.company_form !== "corporation" ? (
          <IEDocs />
        ) : (
          <CorpDocs />
        );
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return <AddPerson />;
      default:
        return <div />;
    }
  };

  return <div className={s.container}>{getPage(director.page)}</div>;
});

export default UBODirectors;
