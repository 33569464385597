//@ts-nocheck
import { useEffect } from "react";
import { observer } from "mobx-react";
import { Button, ArrowLeftIcon, Modal } from "@gamesb42/ui-kit";
import s from "./styles.module.scss";
import connectionStore from "pages/Connection/components/connectionStore";
import appStore from "store/App";
import { director } from "../..";
import userStore from "store/User";

export const Paginator = observer(() => {
  const isIE = userStore.me.company.details.company_form !== "corporation";
  const emptyArr: number[] = [];
  emptyArr.length = director.numberOfUBOs + director.numberOfDirectors + 2;
  emptyArr.fill(1, 1);

  return (
    <div className={s.paginatorHolder}>
      <Button
        icon={<ArrowLeftIcon size={16} />}
        size="large"
        onClick={() => {
          if (director.page >= 2) {
            director.page -= 1;
            if (director.values.currentPerson !== 0) {
              director.values.currentPerson -= 1;
            }
          } else {
            connectionStore.currentScreen = "infoScreen";
            appStore.showAccountsDrawer = false;
          }
        }}
        className={s.backBtn}
      >
        Go back
      </Button>

      <div className={s.dotsHolder}>
        {emptyArr.map((item, idx) => {
          return (
            <div className={idx === director.page ? s.active : s.inactive} />
          );
        })}
      </div>

      <Button
        violet
        type="primary"
        size="large"
        onClick={async () => {
          if (director.isLastStep) {
            appStore.showConfirmOnboardingModal = true;
            return;
          }

          if (director.page > 1) {
            director.values.currentPerson += 1;
          }

          director.page += 1;
        }}
        className={s.nextBtn}
        disabled={
          (isIE && !director.isIEDataValid) ||
          (!isIE && director.numberOfUBOs < 1) ||
          (!isIE && director.page > 1 && !director.isCorpValid)
        }
      >
        Proceed
      </Button>
    </div>
  );
});
