import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';
import { Currency } from 'types';
import userStore from 'store/User';
import SideMenu from '../../components/SideMenu';
import Main from '../../layout/Main';
import Skeleton from '../../components/Skeleton';
import Tooltip from '../../components/Tooltip';
import SplittedButtons, { SplittedButton } from '../../components/SplittedButton';
import { authorizedRequest } from '../../utils/authorization';
import { formatCurrency, prettyDate } from '../../utils/helpers';
import { useFilterFunction } from '../../components/SearchSelect/hooks';
import { USD, EUR } from '../../const';
import SearchSelect, { SearchSelectItem } from '../../components/SearchSelect';
import ColorPass from '../../components/SearchSelect/ColorPass';
import dashboardStore from '../../store/Dashboard';
import styles from './styles.module.scss';

type Status = 'FUNDED'|'REPAID';

type LoanItem = {
  name: string,
  loan_body: string,
  issuing_date: string,
  amount: string,
  repayment_date: string,
}

type Filters = {
  currency: Currency|null|undefined;
  status: Status;
}

class AoListStore {
  constructor() {
    makeAutoObservable(this);
  }

  items:LoanItem[] = [];

  isDataEmpty = false;

  filters: Filters = {
    currency: null,
    status: 'FUNDED',
  }

  getItems = async () => {
    try {
      this.loading = true;
      this.items = await authorizedRequest({
        url: '/ui/api/v1/fin_data/activation_order',
        params: {
          show_deleted: 'true',
          ...this.filters,
        },
      });
      this.isDataEmpty = !this.items.length;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  loading = true;
}

const localStore = new AoListStore();

const Aolist = () => {
  const [isOpened, setOpened] = useState(false);
  useEffect(() => {
    localStore.filters.currency = null;
    localStore.getItems();
  }, []);
  useEffect(() => {
    localStore.getItems();
  }, [localStore.filters.currency, localStore.filters.status]);
  const { filterData, filteredItems, search } = useFilterFunction({
    items: [{ label: USD, value: USD }, { label: EUR, value: EUR }],
  });
  const emptyArr:number[] = [];
  emptyArr.length = 3;
  emptyArr.fill(1, 0, 3);

  const totalAmount = localStore.items.reduce((prevVal, curVal) => {
    return Number(curVal.amount) + prevVal;
  }, 0);

  useEffect(() => {
    localStore.filters.currency = null;
  }, []);
  return (
    <Main>
      <SideMenu />
      <div className={styles.container}>
        <h1 className={styles.title}>Activation Orders</h1>
        <div className={styles.btnsHolder}>
          <div className={styles.finInfoBtn}>
            <SplittedButtons>
              <SplittedButton
                isActive={localStore.filters.status === 'FUNDED'}
                onClick={() => {
                  localStore.filters.status = 'FUNDED';
                }}
              >FUNDED
              </SplittedButton>
              <SplittedButton
                isActive={localStore.filters.status === 'REPAID'}
                onClick={() => {
                  localStore.filters.status = 'REPAID';
                }}
              >REPAID
              </SplittedButton>
            </SplittedButtons>
          </div>
          <div className={styles.currInfoBtn}>
            <SearchSelect
              title={localStore.filters.currency || 'All'}
              onFilter={filterData}
              setOpened={setOpened}
              isOpened={isOpened}
              label="Currency"
            >
              {
                filteredItems.length > 1 && (
                  <SearchSelectItem
                    onClick={() => {
                      localStore.filters.currency = null;
                      setOpened(false);
                    }}
                    selected={localStore.filters.currency === null}
                    key="all"
                  >
                    <ColorPass splitText={search}>All</ColorPass>
                  </SearchSelectItem>
                )
              }
              {
                filteredItems.map(account => {
                  return (
                    <SearchSelectItem
                      onClick={() => {
                        // @ts-ignore
                        localStore.filters.currency = account.value;
                        setOpened(false);
                      }}
                      selected={account.value === localStore.filters.currency}
                      key={account.label}
                    >
                      <ColorPass splitText={search}>{account.label}</ColorPass>
                    </SearchSelectItem>
                  );
                })
              }
            </SearchSelect>
            {/*<SplittedButtons>*/}
            {/*  <SplittedButton*/}
            {/*    isActive={localStore.filters.currency === 'EUR'}*/}
            {/*    onClick={() => {*/}
            {/*      localStore.filters.currency = 'EUR';*/}
            {/*    }}*/}
            {/*  >EUR*/}
            {/*  </SplittedButton>*/}
            {/*  <SplittedButton*/}
            {/*    isActive={localStore.filters.currency === 'USD'}*/}
            {/*    onClick={() => {*/}
            {/*      localStore.filters.currency = 'USD';*/}
            {/*    }}*/}
            {/*  >USD*/}
            {/*  </SplittedButton>*/}
            {/*</SplittedButtons>*/}
          </div>
        </div>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr className={styles.th}>
              <th className={styles.cell} style={{ width: '7%' }}>
                <div className={styles.cellInnerCenter}>
                  <span className={styles.headerText}>No.</span>
                  <Tooltip title="The number of the Activation Order under which we have transferred funds to you" />
                </div>
              </th>
              <th className={styles.cell} style={{ width: '20%' }}>
                <div className={styles.cellInnerCenter}>
                  <span className={styles.headerText}>Purchase Price</span>
                  <Tooltip title="The amount of funds transferred to you under the Activation Order" />
                </div>
              </th>
              <th className={styles.cell} style={{ width: '20%' }}>
                <div className={styles.cellInnerCenter}>
                  <span className={styles.headerText}>Purchase Date</span>
                  <Tooltip title="The date we have transferred the Purchase Price to you" />
                </div>
              </th>
              <th className={styles.cell} style={{ width: '20%' }}>
                <div className={styles.cellInnerCenter}>
                  <span className={styles.headerText}>Principal Amount</span>
                  <Tooltip title="An outstanding amount of the Purchase Price" />
                </div>
              </th>
              <th className={styles.cell} style={{ width: '15%' }}>
                <div className={styles.cellInnerCenter}>
                  <span className={styles.headerText}>Repayment Date</span>
                  <Tooltip title="The date when we expect to receive the Principal Amount from you. Please be informed that in case of delay, a penalty (Default Interest) will accrue" />
                </div>
              </th>
            </tr>
          </thead>
          {
            localStore.items.length && !localStore.loading &&
            <tbody className={styles.tbody}>
              {
              localStore.items.map(item => {
                return (
                  <tr>
                    <td style={{ width: '7%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}><div>{item.name}</div></td>
                    {/*@ts-ignore*/}
                    <td style={{ width: '20%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}><div>{formatCurrency(+item.loan_body, item.currency.toUpperCase())}</div></td>
                    <td style={{ width: '20%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}>{prettyDate(item.issuing_date)}</td>
                    {/*@ts-ignore*/}
                    <td style={{ width: '20%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}>{formatCurrency(+item.amount, item.currency.toUpperCase())}</td>
                    <td style={{ width: '15%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}>{prettyDate(item.repayment_date)}</td>
                  </tr>
                );
              })
            }

            </tbody> || null
          }

          {
            localStore.loading &&
            <tbody className={styles.tbody}>
              {
              emptyArr.map(item => {
                return (
                  <tr>
                    <td style={{ width: '7%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}><Skeleton width={25} height={16} /></td>
                    <td style={{ width: '20%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}><Skeleton width={120} height={16} /></td>
                    <td style={{ width: '20%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}><Skeleton width={100} height={16} /></td>
                    <td style={{ width: '20%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}><Skeleton width={120} height={16} /></td>
                    <td style={{ width: '15%' }} className={`${styles.cell} ${styles.cellInnerCenter}`}><Skeleton width={100} height={16} /></td>
                  </tr>
                );
              })
            }
            </tbody> || null
          }

          {
            localStore.isDataEmpty && !localStore.loading &&
            <tbody className={styles.emptyPlaceholder}><tr><td>There are no data yet</td></tr></tbody> || null
          }
          {/*{*/}
          {/*  !isEmpty && visible && !localStore.loading && localStore.transactions.length >= 25 && <div className={styles.down}><Down /></div>*/}
          {/*}*/}
        </table>
        {/*<div className={styles.totalBlock}>*/}
        {/*  /!*@ts-ignore*!/*/}
        {/*  Total Principal Amount: {formatCurrency(totalAmount, localStore.filters.currency)}*/}
        {/*</div>*/}
      </div>
    </Main>
  );
};

export default observer(Aolist);
