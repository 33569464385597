import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Table, { Body, Header } from '../Table';
import Tooltip from '../Tooltip';
import applaudStore from '../../store/Applaud';
import { formatCurrency, prettyDate } from '../../utils/helpers';
import Skeleton from '../Skeleton';
import style from './styles.module.scss';

const NowFunding = () => {
  useEffect(() => {
    applaudStore.getClaims();
  }, []);
  const getContent = () => {
    const emptyArr:number[] = [];
    emptyArr.length = 8;
    emptyArr.fill(0, 0, 8);
    if ((applaudStore.claims?.claims?.records?.length === 0 || !applaudStore.availableProducts.length) && !applaudStore.claimsLoading) {
      return <div className={style.noDataText}>There are no data yet</div>;
    }
    if (applaudStore.claims?.claims?.records?.length && !applaudStore.claimsLoading) {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {
            applaudStore.claims?.claims?.records?.map((tableItem, idx) => {
              return (
                <>
                  <div className={style.startFormat}>{prettyDate(tableItem.advance_date)}</div>
                  <div className={style.startFormat}><img style={{ width: 20, height: 20, marginRight: 6 }} src={tableItem.icon} alt={tableItem.name} /> {tableItem.name}</div>
                  <div className={style.endFormatBody}>{formatCurrency(+tableItem.purchased, tableItem.currency)}</div>
                  <div className={style.endFormatBody}>{formatCurrency(+tableItem.amount, tableItem.currency)}</div>
                  <div className={style.endFormatBody}>{prettyDate(tableItem.expected_date)}</div>
                  <div className={style.endFormatBody}>{formatCurrency(+tableItem.outstanding_amount, tableItem.currency)}</div>
                  <div>{tableItem.number}</div>
                </>
              );
            })
          }
        </>
      );
    }
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {
          applaudStore.claimsLoading && emptyArr.map(item => {
            return (
              <>
                <div className={style.startFormat}><Skeleton width={80} height={16} /></div>
                <div className={style.startFormat}><Skeleton width={92} height={16} /></div>
                <div className={style.endFormatBody}><Skeleton width={80} height={16} /></div>
                <div className={style.endFormatBody}><Skeleton width={92} height={16} /></div>
                <div className={style.endFormatBody}><Skeleton width={80} height={16} /></div>
                <div className={style.endFormatBody}><Skeleton width={100} height={16} /></div>
                <div><Skeleton width={24} height={16} /></div>
              </>
            );
          })
        }
      </>
    );
  };
  return (
    <Table>
      <Header className={style.tableSize}>
        <div className={style.first}>
          <span className={style.titleTooltip}>Date</span>  <Tooltip title="The date we have transferred funds to you" />
        </div>
        <div>
          <span className={style.titleTooltip}>Revenue Source</span> <Tooltip title="The marketplace or ad network which revenue you sell to us" />
        </div>
        <div className={style.endFormat}>
          You sold <Tooltip title="The amount of revenue you have sold to us" />
        </div>
        <div className={style.endFormat}>
          You got <Tooltip title="The amount we have transferred to your bank account" />
        </div>
        <div className={style.longRow}>
          Repayment Date <Tooltip title="The date when we expect to receive sold revenue from the relevant Revenue Source. If we don’t get it from RS, we expect you to cover it directly" />
        </div>
        <div className={style.endFormat}>
          Outstanding <Tooltip title="The outstanding amount of the sold revenue which we expect to receive from the relevant Revenue Source. If we don’t get it from RS, we expect you to cover it directly" />
        </div>
        <div>
          Req. <Tooltip title="The number of the Purchase Request under which we have transferred funds to you" />
        </div>
      </Header>
      <Body
        className={(applaudStore.claims?.claims?.records?.length === 0 || !applaudStore.availableProducts.length) && !applaudStore.claimsLoading ? style.emptyBody : style.tableSize}
      >
        {getContent()}
      </Body>
    </Table>
  );
};

export default observer(NowFunding);
