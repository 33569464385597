import React from 'react';
import { observer } from 'mobx-react';
import appStore from '../../store/App';
import ErrorBoundary from '../ErrorBoundary';
import loader from './assets/loader.gif';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode,
}

const AuthRequired = ({ children }:Props) => {
  if (!appStore.isLoadedMainInfo) {
    return (
      <div className={styles.loaderContainer}>
        <img src={loader} alt="GameBoost42" />
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <ErrorBoundary><div>{children}</div></ErrorBoundary>;
};

export default observer(AuthRequired);
