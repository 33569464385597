import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
// @ts-ignore
import * as Validator from "validatorjs";
import { Select, CountryIcon } from "@gamesb42/ui-kit";
import countries from "pages/Connection/components/CountrySelect/countrieslist";
import { Title3 } from "../Typography";
import TextInput from "../TextInput";
import { about } from "./index";
import styles from "./styles.module.scss";

const Address = observer(() => {
  const countriesList = countries.map((country) => {
    const Country = CountryIcon?.[country.code] || CountryIcon.UNKNOWN;
    return {
      label: (
        <div className={styles.country}>
          {Country && <Country size={16} />}
          <span className={styles.countryName}>{country.name}</span>
        </div>
      ),
      labelForSearch: country.name,
      value: country.code,
    };
  });

  const rules = {
    country: "required",
    zip: "required",
    state: "string",
    city: "required|string",
    address: "required|string",
  };
  const validation = useMemo(
    () =>
      new Validator(about.values.details, rules, {
        required: "This field is required",
      }),
    [
      about.values.details.country,
      about.values.details.zip,
      about.values.details.state,
      about.values.details.city,
      about.values.details.address,
    ]
  );
  const isValid = validation.passes();

  useEffect(() => {
    about.isStepIsValid[2] = isValid;
  }, [isValid]);

  return (
    <div>
      {["partnership", "corporation"].includes(
        about.values.details.company_form ?? ""
      ) && (
        <Title3 className={styles.subTitle}>Company full legаl address*</Title3>
      )}
      {about.values.details.company_form === "sole-proprietor" && (
        <Title3 className={styles.subTitle}>
          Individual Entrepreneur full address (registered)*
        </Title3>
      )}
      {/*<<<<<<< HEAD*/}
      {/*      <div className={styles.textField}>*/}
      {/*        <CountrySelect*/}
      {/*          onChange={(e) => {*/}
      {/*            about.address.country = {*/}
      {/*              name: e.name,*/}
      {/*              code: e.code,*/}
      {/*            };*/}
      {/*            about.values.details.country = e.code;*/}
      {/*=======*/}
      <div className={styles.selectHolder} style={{ marginTop: "20px" }}>
        <Select
          label="Country"
          size="large"
          onChange={(countryCode) => {
            const selectedCountry = countries.find(
              (item) => item.code === countryCode
            );
            if (selectedCountry) {
              about.address.country = {
                name: selectedCountry.name,
                code: selectedCountry.code,
              };
            }
            about.values.details.country = countryCode;
          }}
          value={
            about.address.country?.code &&
            countries.find((item) => item.code === about.address.country?.code)
              ?.code
          }
          showSearch
          filterOption
          optionFilterProp="labelForSearch"
          options={countriesList}
          notFoundContent={null}
          className={styles.selectCountry}
        />
      </div>
      <div className={styles.post}>
        <TextInput
          onChange={(e) => {
            about.values.details.zip = e;
          }}
          label="Postal/Zip Code*"
          dataTest="zip"
          className={styles.halfInput}
          value={about.values.details.zip}
          error={validation.errors.first("zip")}
        />
        <TextInput
          onChange={(e) => {
            about.values.details.state = e;
          }}
          label="State"
          dataTest="state"
          className={styles.halfInput}
          value={about.values.details.state}
          error={validation.errors.first("state")}
        />
      </div>
      <TextInput
        onChange={(e) => {
          about.values.details.city = e;
        }}
        label="City*"
        dataTest="city"
        className={styles.textField}
        value={about.values.details.city}
        error={validation.errors.first("city")}
      />
      <TextInput
        onChange={(e) => {
          about.values.details.address = e;
        }}
        label="Street Address*"
        dataTest="street"
        className={styles.textField}
        value={about.values.details.address}
        error={validation.errors.first("address")}
      />
    </div>
  );
});

export default Address;
