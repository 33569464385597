import { makeAutoObservable } from 'mobx';
import { isExist } from '../pages/Onboarding';
import { authorizedRequest } from '../utils/authorization';

class Onboarding {
  constructor() {
    makeAutoObservable(this);
  }

  errors = {
    distribution: {},
    monetization: false,
    uaSpend: false,
    mmp: false,
  };

  isValidationTouched = false;

  openId = '';

  setOpenId = (id: string) => {
    if (!id) return;
    this.checkValidation(this.openId);
    // @ts-ignore
    onboard.touchedForms[this.openId] = true;
    if (this.openId === 'distribution' && this.getDistributionStatus === 'error') return;
    if (this.openId === 'monetization' && this.monetizationStatus === 'error') return;
    if (this.openId === 'uaSpend' && this.uaSpendStatus === 'error') return;
    if (this.openId === 'mmp' && this.mmpStatus === 'error') return;
    this.openId = id;
  };

  touchedForms = {
    distribution: false,
    monetization: false,
    uaSpend: false,
    mmp: false,
  };

  getJSON = () => {
    if (this.isSteamApplied) {
      return {
        distribution: this.distribution,
      };
    }

    if (!this.isMonetizationNotPremium) {
      return {
        distribution: this.distribution,
        monetization: this.monetization,
      };
    }

    return {
      distribution: this.distribution,
      monetization: this.monetization,
      uaSpend: this.uaSpend,
      mmp: this.mmp,
      additionalInfo: this.additionalInfo,
    };
  };

  get isValid() {
    if (!Object.keys(this.distribution).length || Object.entries(this.distribution).flat().some(val => !val)) {
      return false;
    }

    if (this.distribution.steam) {
      return true;
    }

    if (!this.monetization) return false;

    if (onboard.isMonetizationNotPremium) {
      if (!Object.keys(this.uaSpend).length) return false;
      if (!Object.keys(this.mmp).length) return false;
    }
    return true;
  }

  distribution:{[key: string]: string} = {};

  monetization = '';

  monetizationModel = '';

  uaSpend:{[key: string]: boolean} = {};

  mmp:{[key: string]: boolean} = {};

  additionalInfo = '';

  checkValidation = (form?: string) => {
    if (!form) return;
    switch (form) {
      case 'distribution': {
        if (Object.keys(this.distribution).length) {
          this.errors.distribution = {};
          for (const [key, value] of Object.entries(this.distribution)) {
            if (!value) {
              // @ts-ignore
              this.errors.distribution[key] = true;
            }
          }
        }
        break;
      }
      case 'monetization': {
        this.errors.monetization = false;
        if (!this.monetization) this.errors.monetization = true;
        break;
      }
      case 'uaSpend': {
        this.errors.uaSpend = false;
        if (!Object.keys(this.uaSpend).length) {
          this.errors.uaSpend = true;
        }
        break;
      }
      case 'mmp':
      default: {
        this.errors.mmp = false;
        if (!Object.keys(this.mmp).length) {
          this.errors.mmp = true;
        }
        break;
      }
    }
  };

  get getDistributionStatus() {
    if (!this.touchedForms.distribution) return 'notFilled';
    if (Object.keys(this.errors.distribution).length) return 'error';
    return 'valid';
  }

  get monetizationStatus() {
    if (!this.touchedForms.monetization) return 'notFilled';
    if (this.errors.monetization) return 'error';
    return 'valid';
  }

  get uaSpendStatus() {
    if (!this.touchedForms.uaSpend) return 'notFilled';
    if (this.errors.uaSpend) return 'error';
    return 'valid';
  }

  get mmpStatus() {
    if (!this.touchedForms.mmp) return 'notFilled';
    if (this.errors.mmp) return 'error';
    return 'valid';
  }

  setMonetization = (value: string) => {
    this.monetization = value;
  };

  setDistribution = (key:string) => {
    if (this.distribution.hasOwnProperty(key)) {
      return delete this.distribution[key];
    }
    this.distribution[key] = '';
    return '';
  };

  setUaSpend = (key:string) => {
    if (this.uaSpend.hasOwnProperty(key)) {
      return delete this.uaSpend[key];
    }
    this.uaSpend[key] = true;
    return '';
  };

  setMmp = (key:string) => {
    if (this.mmp.hasOwnProperty(key)) {
      return delete this.mmp[key];
    }
    this.mmp[key] = true;
    return '';
  };

  get isSteamApplied() {
    return isExist(this.distribution.steam);
  }

  get isAnyDistributionApplied() {
    return (isExist(this.distribution.appleStore) || isExist(this.distribution.googleStore) || isExist(this.distribution.other)) && !this.isSteamApplied;
  }

  get isMonetizationNotPremium() {
    return this.monetization && this.monetization !== 'Premium';
  }

  sendData = async (companyId: string) => {
    await authorizedRequest({
      url: `/v1/onboarding/${companyId}/questionary/app`,
      method: 'POST',
      service: "fca",
      data: this.getJSON(),
    });
  };
}

const onboard = new Onboarding();
export default onboard;
