import axios from "axios";
import { makeAutoObservable } from "mobx";

class Auth {
  constructor() {
    makeAutoObservable(this);
  }

  token: string | undefined = "";

  scaToken: string | undefined | null = "";

  refreshToken: string | undefined | null = "";

  authorize = () => {

    // alert(process.env.REACT_APP_VERSION)

    if (this.scaToken) return;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (!urlParams.get("access_token")) {
      window.location.href = `${process.env.REACT_APP_SCA_URL}/v1/auth/login?redirect_uri=${window.location.origin}`;
      // window.location.href = `${process.env.REACT_APP_URL}/sca/v1/auth/login?redirect_uri=${process.env.REACT_APP_URL}`;
    } else {
      this.scaToken = urlParams.get("access_token");
      this.refreshToken = urlParams.get("refresh_token");
    }
  };

  updateToken = () =>
    axios
      .post<{ access_token: string; refresh_token: string }>(
        `${process.env.REACT_APP_SCA_URL}/v1/auth/refresh`,
        {
          refresh_token: this.refreshToken,
        }
      )
      .then((result) => {
        this.scaToken = result.data.access_token;
        this.refreshToken = result.data.refresh_token;

        return result.data.access_token;
      });
}

const auth = new Auth();
export default auth;
