import React, { ReactNode, useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import useOnClickOutside from '../helpers/useOnClickOutside';
import { ReactComponent as ChevronDown } from './assets/chevron_down.svg';
import styles from './styles.module.scss';

interface Selectprops {
    title: string|number,
    children: ReactNode,
    label?: string,
}

type Itemprops = {
    children: string|number|React.ReactNode,
    onClick: () => void,
}

const SAFE_ZONE_BETWEEN_SELECT_AND_BOTTOM_SCREEN = 20;

const Select = ({ title, children, label }:Selectprops) => {
  const [isOpened, setOpened] = useState(false);
  const [isOpenAbove, setIsOpenAbove] = useState(false);
  const chevronClass = isOpened ? `${styles.chevron} ${styles.rotated}` : styles.chevron;
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const selectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(container, () => setOpened(false));

  useLayoutEffect(() => {
    if (isOpened && selectRef.current && container.current) {
      const currentCoordinateBottomSelect = container.current.getBoundingClientRect().bottom;
      if (!currentCoordinateBottomSelect) return;
      const currentHeightPage = document.documentElement.clientHeight;
      const showListAbove = (currentCoordinateBottomSelect + selectRef.current.clientHeight + SAFE_ZONE_BETWEEN_SELECT_AND_BOTTOM_SCREEN) > currentHeightPage;
      setIsOpenAbove(showListAbove);
    }
  }, [isOpened, selectRef.current, container.current]);

  return (
    <div className={styles.holder} onClick={() => setOpened(!isOpened)} ref={container}>
      <div className={styles.title}>
        {
          label &&
          <div>
            <div className={styles.label}>{label}</div>
            <div>{title}</div>
          </div> ||
          title
        }
        <span className={chevronClass}><ChevronDown /></span>
      </div>
      {
          isOpened
          && <div className={classNames(styles.list, isOpenAbove && styles.listAbove)} ref={selectRef}>{children}</div>
        }
    </div>
  );
};

const SelectItem = ({ children, onClick }:Itemprops) => {
  return (
    <div className={`${styles.title} ${styles.listItem}`} onClick={onClick}>{children}</div>
  );
};

export default Select;
export { SelectItem };
