import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import appStore from '../../store/App';
import style from './style.module.scss';

export const PortalWrapper = observer(({ isOpen = true, children }: {isOpen: boolean, children: React.ReactNode}) => {
  const [el, setEl] = useState<null|HTMLDivElement>(null);
  const [portalRoot, setPortalRoot] = useState<null|HTMLElement>(null);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    appStore.isPortalOpened = true;
    return () => {
      document.body.style.overflow = 'auto';
      appStore.isPortalOpened = false;
    };
  }, []);
  useEffect(() => {
    const holder = document.createElement('div');
    holder.style.position = 'fixed';
    holder.style.height = '100vh';
    holder.style.width = '100%';
    holder.style.top = '0px';
    setEl(holder);
    const root = document.getElementById('root');
    if (root) {
      setPortalRoot(root);
    }
  }, []);
  useEffect(() => {
    if (el && portalRoot) {
      portalRoot.appendChild(el);
    }
    return () => {
      portalRoot && el && portalRoot.removeChild(el);
    };
  }, [el, portalRoot]);
  if (!el || !isOpen) return null;
  return (
    ReactDOM.createPortal(
      children,
      el,
    )
  );
});

const Modal = ({ children, isOpen }: {children: any, isOpen: boolean}) => {
  return (
    <PortalWrapper isOpen={isOpen}>
      <div className={style.modalHolder}>
        {children}
      </div>
    </PortalWrapper>
  );
};

export default Modal;
