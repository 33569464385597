// @ts-nocheck
import React, { useEffect, useState } from "react";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import {
  getPrettyDateForLabel,
  dateForRequest,
  formatCurrency,
} from "utils/helpers";
import { Currency } from "types";
import DatePicker from "../../../components/DatePicker";
import Chart from "../Chart";
import { authorizedRequest } from "../../../utils/authorization";
import userStore from "../../../store/User";
import applaudStore from "../../../store/Applaud";
import connectSources from "../../../store/ConnectSources";
import SearchSelect, {
  SearchSelectItem,
} from "../../../components/SearchSelect";
import { useFilterFunction } from "../../../components/SearchSelect/hooks";
import ColorPass from "../../../components/SearchSelect/ColorPass";
import SplittedButtons, {
  SplittedButton,
} from "../../../components/SplittedButton";
import { BlockTitle } from "../../../components/Text";
import dashboardStore from "../../../store/Dashboard";
import styles from "./styles.module.scss";
import { ReactComponent as Empty } from "./assets/empty_state.svg";
import { DownloadIcon } from "@gamesb42/ui-kit";
import moment from "moment";

type Accounts = {
  storeId: string;
  storeName: string;
  credentials: string | null;
};

type RevenueItem = {
  date: string;
  sum: number;
  prettyLabel: string;
};

class ChartStore {
  constructor() {
    makeAutoObservable(this);
  }

  accounts: Accounts[] | [] = [];

  loadingRevenues = true;

  get accountsWithCredentials() {
    if (this.accounts.length)
      return this.accounts.filter((item) => item.credentials !== null);
    return [];
  }

  dates: [Date | null, Date | null] = [
    this.calendarDates.endPeriod,
    this.calendarDates.startPeriod,
  ];

  get calendarDates() {
    const startPeriod = new Date();
    const endPeriod = new Date();
    endPeriod.setDate(endPeriod.getDate() - 30);
    return {
      startPeriod,
      endPeriod,
    };
  }

  selectedAccountId: string | null = null;

  revenueData: RevenueItem[] | [] = [];

  maxChartSum = 0;

  getRevenues = async (currency?: Currency) => {
    if (userStore?.user?.company.id && dashboardStore.revenueCurrency?.value) {
      try {
        this.loadingRevenues = true;
        const revenues = await authorizedRequest({
          service: "pds",
          url: `/v1/revenue/by-days/${userStore.user.company.id}/${
            currency || dashboardStore.revenueCurrency?.value
          }`,
          params: {
            stores_ids: this.selectedAccountId
              ? [this.selectedAccountId]
              : null,
            date_from: dateForRequest(this.dates[0]),
            date_to: dateForRequest(this.dates[1]),
          },
        });
        if (revenues.proceeds) {
          this.revenueData = revenues.proceeds.map(
            (item: { date: string; sum: number }) => {
              return {
                ...item,
                prettyLabel: getPrettyDateForLabel(item.date),
              };
            }
          );
          const max = Math.max(...this.revenueData.map((o) => o.sum));
          this.maxChartSum = +(max + max / 4).toFixed(0);
        } else {
          this.revenueData = [];
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingRevenues = false;
      }
    } else {
      this.revenueData = [];
      this.loadingRevenues = false;
    }
  };

  get getSelectedAccountTitle() {
    return (
      this.accounts.find((item) => item.storeId === this.selectedAccountId)
        ?.storeName || "All Revenue Sources"
    );
  }

  getAccounts = async () => {
    if (userStore?.user?.company.id) {
      const accounts = await authorizedRequest({
        service: "pds",
        url: `/v1/accounts?company_id=${userStore.user.company.id}`,
      });
      this.accounts = accounts.items;
    }
  };
}

export const chartStore = new ChartStore();

const RevenueChart = () => {
  const [loading, setLoading] = useState(true);
  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    (async () => {
      await chartStore.getRevenues();
      await chartStore.getAccounts();
    })();
  }, [userStore?.user?.company.id]);

  useEffect(() => {
    chartStore.getRevenues();
  }, [
    chartStore.selectedAccountId,
    chartStore.dates[0],
    chartStore.dates[1],
    dashboardStore.revenueCurrency?.value,
  ]);
  useEffect(() => {
    dashboardStore.revenueCurrency = userStore.getDefaultCurrency;
  }, [userStore.getDefaultCurrency?.value]);

  const isDataLoadedAndEmpty =
    !chartStore.revenueData.length && !chartStore.loadingRevenues;
  const { filterData, filteredItems, search } = useFilterFunction({
    items: chartStore.accounts.map((item) => {
      return { label: item.storeName, value: item.storeId };
    }),
  });

  if (!connectSources.accounts.length) {
    return (
      <div className={styles.emptyWrapper}>
        <Empty />
        <p className={styles.emptyText}>
          Statistics will be here after the stores connection
        </p>
      </div>
    );
  }

  const getSumm = formatCurrency(
    //@ts-ignore
    chartStore.revenueData.reduce(
      (acc: number, cur: RevenueItem) => (acc += cur.sum),
      0
    ),
    dashboardStore.revenueCurrency?.value
  );

  // if (!applaudStore.availableProducts.length) return null;
  // if ((!chartStore.revenueData.length && !chartStore.loadingRevenues)) {
  //   return <div>Empty</div>;
  // }

  // console.log(userStore.user.company.id);

  const handleDownload = () => {
    const currency = dashboardStore.revenueCurrency?.value;
    const date_from = moment(chartStore.dates[0]).format("YYYY-MM-DD");
    const date_to = moment(chartStore.dates[1]).format("YYYY-MM-DD");

    fetch(
      `${process.env.REACT_APP_PDS_URL}/v1/revenue/by-days-csv/${userStore?.user?.company?.id}/${currency}?date_from=${date_from}&date_to=${date_to}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((result) => {
        const file = document.createElement("a");
        file.href = window.URL.createObjectURL(result);
        file.setAttribute(
          "download",
          `proceeds_${currency}_${date_from}_${date_to}.csv`
        );
        file.click();
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BlockTitle>Revenue</BlockTitle>
        <div onClick={handleDownload}>
          <DownloadIcon size={24} />
        </div>
      </div>
      {loading && !isDataLoadedAndEmpty && (
        <div className={styles.loading}>Loading data...</div>
      )}
      <div className={styles.controlsHolder}>
        <DatePicker
          onChange={(start, end) => {
            chartStore.dates = [start, end];
          }}
          startDay={chartStore.dates[1]}
          endDay={chartStore.dates[0]}
        />
        {chartStore.accounts.length && (
          <div className={styles.searchSelect}>
            <SearchSelect
              title={chartStore.getSelectedAccountTitle}
              onFilter={filterData}
              setOpened={setOpened}
              isOpened={isOpened}
              label="Platform"
            >
              {filteredItems.length > 1 && (
                <SearchSelectItem
                  onClick={() => {
                    chartStore.selectedAccountId = null;
                    setOpened(false);
                  }}
                  selected={chartStore.selectedAccountId === null}
                  key="allRevenueSources"
                >
                  <ColorPass splitText={search}>All revenue sources</ColorPass>
                </SearchSelectItem>
              )}
              {filteredItems.map((account) => {
                return (
                  <SearchSelectItem
                    onClick={() => {
                      chartStore.selectedAccountId = account.value;
                      setOpened(false);
                    }}
                    selected={account.value === chartStore.selectedAccountId}
                    key={account.label}
                  >
                    <ColorPass splitText={search}>{account.label}</ColorPass>
                  </SearchSelectItem>
                );
              })}
            </SearchSelect>
          </div>
        )}
        {applaudStore.isUsdEurAvailable && (
          <div className={styles.currencyHolder}>
            <div>
              {getSumm}
              {/* {formatCurrency(
                chartStore.revenueData.reduce(
                  (acc: number, cur: RevenueItem) => (acc += cur.sum),
                  0
                ),
                dashboardStore.revenueCurrency?.value
              )} */}
            </div>
            <SplittedButtons>
              <SplittedButton
                onClick={() => {
                  dashboardStore.revenueCurrency = {
                    label: "EUR",
                    value: "EUR",
                  };
                }}
                isActive={dashboardStore.revenueCurrency?.value === "EUR"}
              >
                EUR
              </SplittedButton>
              <SplittedButton
                onClick={() => {
                  dashboardStore.revenueCurrency = {
                    label: "USD",
                    value: "USD",
                  };
                }}
                isActive={dashboardStore.revenueCurrency?.value === "USD"}
              >
                USD
              </SplittedButton>
            </SplittedButtons>
          </div>
        )}
      </div>
      {isDataLoadedAndEmpty && <div className={styles.noData} />}
      {!isDataLoadedAndEmpty && (
        <div
          className={styles.chartLoaderContainer}
          style={{ display: loading ? "block" : "none" }}
        >
          <div className={styles.topLoader}>
            <div className={styles.sideChartsLoader}>
              <div className={styles.sideData} />
              <div className={styles.sideData} />
              <div className={styles.sideData} />
              <div className={styles.sideData} />
              <div className={styles.sideData} />
            </div>
            <div className={styles.mainChartsLoader}>
              <div className={`${styles.bar2} ${styles.chart1}`} />
              <div className={`${styles.bar3} ${styles.chart2}`} />
              <div className={`${styles.bar4} ${styles.chart2}`} />
              <div className={`${styles.bar1} ${styles.chart2}`} />
              <div className={`${styles.bar2} ${styles.chart2}`} />
              <div className={`${styles.bar3} ${styles.chart2}`} />
              <div className={`${styles.bar4} ${styles.chart2}`} />
              <div className={`${styles.bar1} ${styles.chart2}`} />
              <div className={`${styles.bar2} ${styles.chart2}`} />
              <div className={`${styles.bar3} ${styles.chart2}`} />
              <div className={`${styles.bar4} ${styles.chart2}`} />
              <div className={`${styles.bar1} ${styles.chart2}`} />
              <div className={`${styles.bar2} ${styles.chart2}`} />
              <div className={`${styles.bar3} ${styles.chart2}`} />
              <div className={`${styles.bar4} ${styles.chart2}`} />
              <div className={`${styles.bar1} ${styles.chart2}`} />
              <div className={`${styles.bar2} ${styles.chart2}`} />
              <div className={`${styles.bar3} ${styles.chart2}`} />
              <div className={`${styles.bar4} ${styles.chart2}`} />
              <div className={`${styles.bar1} ${styles.chart2}`} />
              <div className={`${styles.bar2} ${styles.chart2}`} />
              <div className={`${styles.bar3} ${styles.chart2}`} />
              <div className={`${styles.bar4} ${styles.chart2}`} />
            </div>
          </div>
          <div className={styles.bottomLoader}>
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
            <div className={styles.bottomData} />
          </div>
        </div>
      )}

      <div
        style={{
          visibility:
            loading || !chartStore.revenueData.length ? "hidden" : "visible",
          height: loading || !chartStore.revenueData.length ? 0 : "auto",
        }}
      >
        <Chart onAfterDraw={() => setLoading(false)} />
      </div>
    </div>
  );
};

export default observer(RevenueChart);
