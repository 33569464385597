import { makeAutoObservable } from "mobx";
import { authorizedRequest } from "../utils/authorization";
import { Currency } from "../types";
import { Company } from "../pages/Connection/components/AboutCompany";

type UserType = {
  username: string;
  email: string;
  full_name: string;
  roles: any;
  onboarding: boolean;
  company: {
    groups: string[];
    id: string;
    name: string;
  };
};

type Me = {
  id: string;
  email: string | null;
  status: string | null;
  role: string | null;
  first_name: string | null;
  last_name: string | null;
  last_login: string | null;
  created: string | null;
  modified: string | null;
  company: Company;
};

class User {
  constructor() {
    makeAutoObservable(this);
  }

  get getDefaultCurrency() {
    if (this.me?.company?.settings?.operating_currency) {
      return {
        label: this.me?.company?.settings?.operating_currency,
        value: this.me?.company?.settings?.operating_currency,
      };
    }
    return null;
  }

  user: UserType | null = null;

  me: Me | null = null;

  company = {};

  getUserInfo = async () => {
    this.user = await authorizedRequest({ url: "/ui/api/v1/current_user" });
  };

  getMe = async () => {
    this.me = await authorizedRequest({
      url: "/v1/auth/me",
      service: "fca",
    });
  };
}

const userStore = new User();
export default userStore;
