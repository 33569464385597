import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import useOnClickOutside from '../helpers/useOnClickOutside';
import { ReactComponent as ChevronDown } from './assets/chevron_down.svg';
import styles from './styles.module.scss';
import ColorPass from './ColorPass';

interface Selectprops {
    title: string|number,
    children: ReactNode,
    label: string,
    isOpened: boolean,
    onFilter: (e: string) => void,
    setOpened: (val: boolean) => void,
}

type Itemprops = {
    children: string|number|React.ReactNode,
    onClick: () => void,
    selected?: boolean,
}

const SearchSelect = ({
  title,
  children,
  label,
  isOpened,
  onFilter,
  setOpened,
}:Selectprops) => {
  const [searchText, setSearchText] = useState<string|number>('');
  const input = useRef() as React.MutableRefObject<HTMLInputElement>;
  useEffect(() => {
    if (title) setSearchText(title);
  }, [title]);
  useEffect(() => {
    if (isOpened && input.current) {
      input.current.select();
    }
    if (!isOpened) {
      setSearchText(title);
      onFilter && onFilter('');
    }
  }, [isOpened]);

  const chevronClass = isOpened ? `${styles.chevron} ${styles.rotated}` : styles.chevron;
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(container, () => setOpened && setOpened(false));
  return (
    <div
      className={styles.holder}
      ref={container}
    >
      <div
        className={styles.title}
        onClick={(e) => {
          setOpened && setOpened(!isOpened);
        }}
      >
        {
          label &&
          <div>
            <div className={styles.label}>{label}</div>
            {
              isOpened &&
              <input
                value={searchText}
                ref={input}
                className={styles.input}
                onChange={(e) => {
                  onFilter && onFilter(e.currentTarget.value);
                  setSearchText(e.currentTarget.value);
                }}
              /> || <div>{title}</div>
            }
          </div> ||
          title
        }
        <span className={chevronClass}><ChevronDown /></span>
      </div>
      {
          isOpened
          && <div className={styles.list}>{children}</div>
        }
    </div>
  );
};

// Сделано отделно для возможности переиспользования любого вида children-a
const SearchSelectItem = ({ children, onClick, selected }:Itemprops) => {
  const selectedStyle = selected ? styles.selected : '';
  return (
    <div className={`${styles.title} ${styles.listItem} ${selectedStyle}`} onClick={onClick}>{children}</div>
  );
};

export default SearchSelect;
export { SearchSelectItem };
