import React from 'react';
import styles from './styles.module.scss';

const Skeleton = ({ width = 100, height = 16, isRound }: { width: number, height: number, isRound?: boolean }) => {
  const appliedClass = isRound && `${styles.round} ${styles.loader}` || styles.loader;
  return (
    <div className={appliedClass} style={{ width, height }} />
  );
};

export default Skeleton;
