import React, { useEffect } from "react";
import { makeAutoObservable } from "mobx";
// @ts-ignore
import * as Validator from "validatorjs";
import { observer } from "mobx-react";
import { Title2 } from "../Typography";
import { authorizedRequest } from "../../../../utils/authorization";
import countrieslist from "../CountrySelect/countrieslist";
import { CurrencyCodes, Currency as CurrencyType } from "../../../../types";
import userStore from "../../../../store/User";
import styles from "./styles.module.scss";
import AccountUsd from "./AccountUsd";
import Currency from "./Currency";
import Signer from "./Signer";
import Company from "./Company";
import Registration from "./Registration";
import Address from "./Address";
import AccountEur from "./AccountEur";

interface ContactPerson {
  id?: number;
  type: "signer" | "contact" | null;
  full_name: string | null;
  position?: string | null;
  email: string | null;
  contact_phone: string | null;
}

type Address = {
  country: null | { name: string; code: string };
};

type Bank = {
  id?: number;
  currency: CurrencyType;
  master?: boolean;
  bank_name: string | null;
  bank_address?: string | null;
  account: string | null;
  swift: string | null;
  special_instructions?: string | null;
  correspondent_account?: string | null;
  correspondent_bank_name?: string | null;
  correspondent_swift?: string | null;
};

export type Company = {
  id: number;
  type: string | null;
  status: string | null;
  name: string;
  details: {
    legal_name: string | null;
    company_form: string | null;
    registration_number: string | null;
    tax_number: string | null;
    website: string | null;
    email: string | null;
    phone: string | null;
    country: string | null;
    zip: string | null;
    state: string | null;
    city: string | null;
    address: string | null;
    factor_iban: string | null;
    comment: string | null;
  };
  bank_details: Bank[];
  contacts: ContactPerson[];
  settings: {
    operating_currency: CurrencyType;
    questionnaire: boolean;
    get_funding_avalibility: boolean;
  };
};

class About {
  constructor() {
    makeAutoObservable(this);
  }

  address: Address = {
    country: null,
  };

  isModalFillShowed = false;

  sendCompanyInfoLoading = false;

  values: Company = {
    id: 0,
    type: "",
    status: "",
    name: "",
    details: {
      legal_name: null,
      company_form: null,
      registration_number: null,
      tax_number: null,
      website: null,
      email: null,
      phone: null,
      country: null,
      zip: null,
      state: null,
      city: null,
      address: null,
      factor_iban: null,
      comment: null,
    },
    bank_details: [],
    contacts: [
      {
        id: 0,
        type: null,
        full_name: null,
        position: null,
        email: null,
        contact_phone: null,
      },
    ],
    settings: {
      operating_currency: "EUR",
      questionnaire: false,
      get_funding_avalibility: false,
    },
  };

  touched: { [key: string]: boolean } = {};

  get currency() {
    const result = [];
    if (this.values.bank_details.find((item) => item.currency === "EUR")) {
      result.push("eur");
    }
    if (this.values.bank_details.find((item) => item.currency === "USD")) {
      result.push("usd");
    }
    return result;
  }

  sendCompanyInfo = async () => {
    const dataToSend = this.values.details;

    if (dataToSend.company_form === "sole-proprietor") {
      //@ts-ignore
      delete dataToSend.registration_number;
    }

    this.sendCompanyInfoLoading = true;
    try {
      if (this.values.id) {
        await authorizedRequest({
          url: `/v1/companies/${this.values.id}`,
          service: "fca",
          method: "PUT",
          data: this.values,
        });
      } else {
        await authorizedRequest({
          url: "/v1/companies",
          service: "fca",
          method: "POST",
          data: this.values,
        });
      }

      if (this.isPersonalDataValid && this.isCompanyInfoValid) {
        this.isModalFillShowed = true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      await userStore.getMe();
      await this.getCompanyInfo();
      this.sendCompanyInfoLoading = false;
    }
  };

  sendContact = async () => {
    if (this.contact?.id) {
      await authorizedRequest({
        url: `/v1/companies/${this.values.id}/contacts`,
        service: "fca",
        method: "POST",
        data: this.contact,
      });
    } else {
      await authorizedRequest({
        url: `/v1/companies/${this.values.id}/contacts`,
        service: "fca",
        method: "PATCH",
        data: this.contact,
      });
    }
  };

  get isLastStep() {
    if (this.page === 5 && about.currency.length === 1) return true;
    return this.page === 6;
  }

  isStepIsValid = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  };

  get signer() {
    return this.values.contacts.find((item) => item.type === "signer");
  }

  get eurBank() {
    return this.values.bank_details.find((item) => item.currency === "EUR");
  }

  get usdBank() {
    return this.values.bank_details.find((item) => item.currency === "USD");
  }

  get contact() {
    return this.values.contacts.find((item) => item.type === "contact");
  }

  get isCompanyInfoValid() {
    return !!(
      this.values.details.company_form &&
      this.values.details.legal_name &&
      this.values.details.email &&
      this.values.details.phone &&
      this.values.details.country &&
      this.values.details.zip &&
      this.values.details.city &&
      this.values.details.address &&
      (this.values.details.registration_number ||
        this.values.details.tax_number) &&
      // this.signer?.email && this.signer.position && this.signer.full_name &&
      ((this.eurBank?.id &&
        this.eurBank?.swift &&
        this.eurBank?.account &&
        this.eurBank?.bank_name) ||
        (this.usdBank?.id &&
          this.usdBank.swift &&
          this.usdBank?.account &&
          this.usdBank.bank_name))
    );
  }

  isPersonalDataValid = false;
  page = 1;
  isDataSended = false;

  getCompanyInfo = async () => {
    if (userStore.me) {
      this.values = userStore.me.company;
      if (userStore.me.company.bank_details.length > 0)
        this.isDataSended = true;
    }
    this.values.details.phone =
      this.values.details.phone?.replace(/\s/g, "") || null;

    if (this.values.details.country) {
      const selectedCountry = countrieslist.find(
        (country) => country.code === this.values.details.country
      );
      if (selectedCountry) {
        this.address.country = {
          code: selectedCountry.code,
          name: selectedCountry.name,
        };
      } else {
        console.error("Cant find country from the list");
      }
    }

    this.isPersonalDataValid = !!(
      this.contact?.email &&
      this.contact.contact_phone &&
      this.contact.full_name &&
      this.contact.position
    );
  };
}

export const about = new About();

const AboutCompany = observer(() => {
  const getPage = (page: number) => {
    if (
      page === 5 &&
      about.currency.length === 1 &&
      about.currency[0] === "usd"
    ) {
      return <AccountUsd />;
    }
    switch (page) {
      case 1:
        return <Company />;
      case 2:
        return <Address />;
      case 3:
        return <Registration />;
      case 4:
        return <Currency />;
      case 5:
        return <AccountEur />;
      case 6:
        return <AccountUsd />;
      default:
        return <div />;
    }
  };
  return <div className={styles.container}>{getPage(about.page)}</div>;
});

export default AboutCompany;
