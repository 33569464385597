// Стандартная функия поиска с подсветкой совпадающих элементов
// Возвращает массив отфильтрованных данных
import { useState } from 'react';

export type FilterItem = {value: string, label: string, type?: string}

export const useFilterFunction = ({ items }: { items: FilterItem[] }) => {
  const [data, setData] = useState(items);
  const [search, setSearch] = useState('');
  const filterData = (e: string) => {
    if (e) {
      setSearch(e);
      setData(items.filter(item => item.label.toLowerCase().includes(e.toLowerCase())));
    } else if (!e) {
      setData(items);
      setSearch(e);
    } else {
      setData([]);
      setSearch(e);
    }
  };
  return {
    filteredItems: data,
    filterData,
    search,
  };
};
