import { useEffect } from "react";
import { Radio, Input } from "@gamesb42/ui-kit";

import { corparateDoc } from "./index";
import { observer } from "mobx-react";
import UploadFile from "components/UploadFile";

import { ReactComponent as Pdf } from "./assets/pdf.svg";
import { ReactComponent as Close } from "./assets/close.svg";

import s from "./styles.module.scss";
import FileName from "./components/FileName";
import appStore from "store/App";

const Step1 = observer(() => {
  // appStore.showConfirmOnboardingModal = corparateDoc.isDataSended ? false : "";

  // validate step data
  useEffect(() => {
    const conditionForUpload =
      corparateDoc.values.step1Variant === "upload" &&
      corparateDoc.values.certificateOfIncorporationFile!.name.length > 0;
    const conditionForNotApplicable =
      corparateDoc.values.step1Variant === "notApplicable" &&
      corparateDoc.values.certificateOfIncorporationComment.length > 0;

    if (conditionForUpload || conditionForNotApplicable) {
      corparateDoc.isStepIsValid[1] = true;
    } else {
      corparateDoc.isStepIsValid[1] = false;
    }
  }, [
    corparateDoc.values.step1Variant,
    corparateDoc.values.certificateOfIncorporationFile!.name,
    corparateDoc.values.certificateOfIncorporationComment,
  ]);

  function delFileHandler() {
    if (corparateDoc.values.certificateOfIncorporationFile?.docId?.length > 0) {
      corparateDoc.values.needtoDelete.push(
        corparateDoc.values.certificateOfIncorporationFile.docId
      );
    }

    corparateDoc.values.certificateOfIncorporationFile = {
      id: "",
      docId: "",
      name: "",
      value: "",
      size: 0,
    };

    corparateDoc.values.certificateOfIncorporationComment = "";
  }

  return (
    <>
      <p>Certificate of Incorporation or equivalent*</p>
      <Radio.Group value={corparateDoc.values.step1Variant}>
        <Radio
          value="upload"
          onChange={() => {
            delFileHandler();
            corparateDoc.values.step1Variant = "upload";
          }}
        >
          Upload
        </Radio>
        <Radio
          value="notApplicable"
          onChange={() => {
            delFileHandler();
            corparateDoc.values.step1Variant = "notApplicable";
          }}
        >
          Not applicable
        </Radio>
      </Radio.Group>

      {corparateDoc.values.step1Variant === "upload" && (
        <div
          style={{
            marginTop: "32px",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <div className={s.uploadHolder}>
            {(corparateDoc.values.certificateOfIncorporationFile!.size ===
              0 && (
              <UploadFile
                dragText="Drag and drop file*"
                uploadText={`Certificate of incorporation and certificate of good standing of the legal person or equivalent 50 Mb max. weight`}
                onUpload={(e, file) => {
                  corparateDoc.values.certificateOfIncorporationFile!.name =
                    file.name;
                  corparateDoc.values.certificateOfIncorporationFile!.size =
                    file.size;
                  corparateDoc.values.certificateOfIncorporationFile!.value =
                    file;
                }}
              />
            )) || (
              <FileName
                fileName="certificateOfIncorporationFile"
                clearFiles={delFileHandler}
              />
            )}
          </div>
          <Input.TextArea
            style={{ height: "100px" }}
            label="Comment to the uploaded file"
            disabled={
              corparateDoc.values.certificateOfIncorporationFile?.docId
                ?.length > 0
            }
            showCount={true}
            maxLength={200}
            value={corparateDoc.values.certificateOfIncorporationComment}
            onChange={(e) => {
              corparateDoc.values.certificateOfIncorporationComment =
                e.target.value;
            }}
          />
        </div>
      )}

      {corparateDoc.values.step1Variant === "notApplicable" && (
        <div style={{ padding: "32px 0 0 0" }}>
          <Input.TextArea
            style={{ height: "100px" }}
            label="Not applicable? Please explain why*"
            disabled={false}
            showCount={true}
            maxLength={200}
            value={corparateDoc.values.certificateOfIncorporationComment}
            onChange={(e) => {
              corparateDoc.values.certificateOfIncorporationComment =
                e.target.value;
            }}
            placeholder="Not applicable? Please explain why*"
          />
        </div>
      )}
    </>
  );
});

export default Step1;
