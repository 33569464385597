// @ts-nocheck
import axios from "axios";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Auth from "store/Auth";
import userStore from "store/User";
import { director as directorInst } from "../Director";

import s from "./styles.module.scss";
import { authorizedRequest } from "utils/authorization";
import { companySignatoryInst } from "../CompanySignatory";

export type File = {
  id?: string;
  docId?: string;
  name: string;
  value: string | ArrayBuffer | null;
  size: number;
};
export type FileOrNull = File | null;

type CorporateDocument = {
  certificateOfIncorporationFile: any;
  certificateOfIncorporationComment: string;
  certificateOfRegisteredAddressFile: any;
  certificateOfRegisteredAddressComment: string;
  certificateOfDirectorsFile: any;
  certificateOfDirectorsComment: string;
  certificateOfShareholdersFiles: [any];
  certificateOfShareholdersComment: string;
  certificateOfGoodStandingFile: any;
  certificateOfGoodStandingComment: string;
  memorandumAndArticlesOfAssociationFile: any;
  memorandumAndArticlesOfAssociationComment: string;
  latestAuditedFinancialStatementFile: any;
  latestAuditedFinancialStatementComment: string;

  step1Variant: string;
  step2Variant: string;
  step3Variant: string;
  step4Variant: string;
  step4Variant2: string;
  step4DocId: string;
  step5Variant: string;
  step6Variant: string;
  step7Variant: string;

  step1VariantBefore: string;
  step2VariantBefore: string;
  step3VariantBefore: string;
  step4VariantBefore: string;
  step4Variant2Before: string;
  step5VariantBefore: string;
  step6VariantBefore: string;
  step7VariantBefore: string;

  responseData: any;

  needtoDelete: [string]; // docIds
};

class CorporateDocumentBC {
  constructor() {
    makeAutoObservable(this);
  }

  isModalFillShowed = false;
  page = 1;

  values: CorporateDocument = {
    certificateOfIncorporationFile: {
      name: "",
      value: "",
      size: 0,
    },
    certificateOfIncorporationComment: "",
    certificateOfRegisteredAddressFile: {
      name: "",
      value: "",
      size: 0,
    },
    certificateOfRegisteredAddressComment: "",
    certificateOfDirectorsFile: {
      name: "",
      value: "",
      size: 0,
    },
    certificateOfDirectorsComment: "",
    certificateOfShareholdersFiles: [],
    certificateOfShareholdersComment: "",
    certificateOfGoodStandingFile: {
      name: "",
      value: "",
      size: 0,
    },
    certificateOfGoodStandingComment: "",
    memorandumAndArticlesOfAssociationFile: {
      name: "",
      value: "",
      size: 0,
    },
    memorandumAndArticlesOfAssociationComment: "",
    latestAuditedFinancialStatementFile: {
      name: "",
      value: "",
      size: 0,
    },
    latestAuditedFinancialStatementComment: "",

    step1Variant: "",
    step2Variant: "",
    step3Variant: "",
    step4Variant: "",
    step4Variant2: "",
    step4DocId: "",
    step5Variant: "",
    step6Variant: "",
    step7Variant: "",

    step1VariantBefore: "",
    step2VariantBefore: "",
    step3VariantBefore: "",
    step4VariantBefore: "",
    step4Variant2Before: "",
    step5VariantBefore: "",
    step6VariantBefore: "",
    step7VariantBefore: "",

    responseData: {
      certificateOfIncorporationFile: { name: "" },
      certificateOfIncorporationComment: "",
      certificateOfRegisteredAddressFile: {},
      certificateOfRegisteredAddressComment: "",
      certificateOfDirectorsFile: {},
      certificateOfDirectorsComment: "",
      certificateOfShareholdersFiles: [],
      certificateOfShareholdersComment: "",
      certificateOfGoodStandingFile: {},
      certificateOfGoodStandingComment: "",
      memorandumAndArticlesOfAssociationFile: {},
      memorandumAndArticlesOfAssociationComment: "",
      latestAuditedFinancialStatementFile: {},
      latestAuditedFinancialStatementComment: "",
    },

    needtoDelete: [],
  };

  isStepIsValid = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  };

  isDataSended = false;

  get isLastStep() {
    return this.page === 7;
  }

  get isCorporateDocumentsInfoValid() {
    return (
      this.isStepIsValid[1] &&
      this.isStepIsValid[2] &&
      this.isStepIsValid[3] &&
      this.isStepIsValid[4] &&
      this.isStepIsValid[5] &&
      this.isStepIsValid[6] &&
      this.isStepIsValid[7]
    );
  }

  async sendFiles() {
    const mappingDocType = {
      certificateOfIncorporationFile: "certificate-of-incorporation",
      certificateOfRegisteredAddressFile: "certificate-of-registered-address",
      certificateOfDirectorsFile: "certificate-of-directors-and-secretary",
      certificateOfGoodStandingFile: "certificate-of-good-standing",
      memorandumAndArticlesOfAssociationFile:
        "memorandum-and-articles-of-association",
      latestAuditedFinancialStatementFile:
        "latest-audited-financial-statement-or-management-accounts",
    };

    const mappingComments = {
      certificateOfIncorporationFile: "certificateOfIncorporationComment",
      certificateOfRegisteredAddressFile:
        "certificateOfRegisteredAddressComment",
      certificateOfDirectorsFile: "certificateOfDirectorsComment",
      certificateOfGoodStandingFile: "certificateOfGoodStandingComment",
      memorandumAndArticlesOfAssociationFile:
        "memorandumAndArticlesOfAssociationComment",
      latestAuditedFinancialStatementFile:
        "latestAuditedFinancialStatementComment",
    };
    const reqArr = [];

    for (const [i, el] of Object.keys(mappingDocType).entries()) {
      const commentFieldName = mappingComments[el];
      const isCommentChange =
        this.values[mappingComments[el]] !==
        this.values.responseData[commentFieldName];

      const isVariantChange = () => {
        const currentI = i + 1;
        const nameOfKey = `step${currentI}Variant`;
        const nameOfKeyBefore = `step${currentI}VariantBefore`;

        return this.values[nameOfKey] !== this.values[nameOfKeyBefore];
      };

      if (
        this.values[el]?.value?.size > 0 ||
        isCommentChange ||
        isVariantChange()
      ) {
        this.values.needtoDelete.push(this.values[el]?.docId);
        const doc_type = mappingDocType[el];
        let data = new FormData();

        if (this.values[el].value?.size > 0) {
          data.append("file", this.values[el].value, this.values[el].name);
        } else {
          data.append("file", "");
        }

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_SCA_URL}/v1/documents/?doc_type=${doc_type}&comment=${this.values[commentFieldName]}`,
          headers: {
            accept: "application/json",
            authorization: `Bearer ${Auth.scaToken}`,
          },
          data,
        };

        reqArr.push(axios.request(config));
      }
    }

    // send logic for certificateOfShareholdersFiles files
    if (
      this.values.certificateOfShareholdersFiles.some(
        (el) => el?.value?.size > 0
      )
    ) {
      // del prev comment if exist
      if (this.values?.step4DocId?.length > 0) {
        this.values.needtoDelete.push(this.values?.step4DocId);
      }

      this.values.certificateOfShareholdersFiles
        .filter((el3) => el3?.value?.size > 0)
        .forEach((el2, i) => {
          let data = new FormData();

          if (el2?.value?.size > 0) {
            data.append("file", el2.value, el2.name);
          } else {
            data.append("file", "");
          }
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SCA_URL}/v1/documents/?doc_type=certificate-of-shareholders&comment=${this.values.certificateOfShareholdersComment}`,
            headers: {
              accept: "application/json",
              authorization: `Bearer ${Auth.scaToken}`,
            },
            data,
          };

          reqArr.push(axios.request(config));
        });
    } else if (
      this.values.certificateOfShareholdersComment !==
      this.values.responseData.certificateOfShareholdersComment
    ) {
      // delete all files if comment change
      this.values.responseData.certificateOfShareholdersFiles
        .map((el) => el?.docId)
        .forEach((el) => {
          if (el) this.values.needtoDelete.push(Element);
        });

      // del prev comment if exist
      if (this.values?.step4DocId?.length > 0) {
        this.values.needtoDelete.push(this.values?.step4DocId);
      }

      let data = new FormData();
      data.append("file", "");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SCA_URL}/v1/documents/?doc_type=certificate-of-shareholders&comment=${this.values.certificateOfShareholdersComment}`,
        headers: {
          accept: "application/json",
          authorization: `Bearer ${Auth.scaToken}`,
        },
        data,
      };

      reqArr.push(axios.request(config));
    }

    await Promise.all(
      [...new Set(this.values.needtoDelete)].map((docId) => {
        if (docId && docId.length > 0)
          authorizedRequest({
            url: `/v1/documents/${docId}`,
            method: "DELETE",
            service: "fca",
            data: {},
          });
      })
    ).finally(() => {
      this.values.needtoDelete = [];
    });

    await Promise.all(reqArr)
      .then((res) => {
        // res.forEach((el) => this.fillFields(el.data));
      })
      .catch((error) => {
        console.log(error);
      });

    await this.getCorporateDocData(userStore.me?.company?.id.toString() || "");
  }

  clearData() {
    this.values = {
      certificateOfIncorporationFile: {
        name: "",
        value: "",
        size: 0,
      },
      certificateOfIncorporationComment: "",
      certificateOfRegisteredAddressFile: {
        name: "",
        value: "",
        size: 0,
      },
      certificateOfRegisteredAddressComment: "",
      certificateOfDirectorsFile: {
        name: "",
        value: "",
        size: 0,
      },
      certificateOfDirectorsComment: "",
      certificateOfShareholdersFiles: [],
      certificateOfShareholdersComment: "",
      certificateOfGoodStandingFile: {
        name: "",
        value: "",
        size: 0,
      },
      certificateOfGoodStandingComment: "",
      memorandumAndArticlesOfAssociationFile: {
        name: "",
        value: "",
        size: 0,
      },
      memorandumAndArticlesOfAssociationComment: "",
      latestAuditedFinancialStatementFile: {
        name: "",
        value: "",
        size: 0,
      },
      latestAuditedFinancialStatementComment: "",

      step1Variant: "",
      step2Variant: "",
      step3Variant: "",
      step4Variant: "",
      step4Variant2: "",
      step4DocId: "",
      step5Variant: "",
      step6Variant: "",
      step7Variant: "",

      step1VariantBefore: "",
      step2VariantBefore: "",
      step3VariantBefore: "",
      step4VariantBefore: "",
      step4Variant2Before: "",
      step5VariantBefore: "",
      step6VariantBefore: "",
      step7VariantBefore: "",

      responseData: {
        certificateOfIncorporationFile: { name: "" },
        certificateOfIncorporationComment: "",
        certificateOfRegisteredAddressFile: {},
        certificateOfRegisteredAddressComment: "",
        certificateOfDirectorsFile: {},
        certificateOfDirectorsComment: "",
        certificateOfShareholdersFiles: [],
        certificateOfShareholdersComment: "",
        certificateOfGoodStandingFile: {},
        certificateOfGoodStandingComment: "",
        memorandumAndArticlesOfAssociationFile: {},
        memorandumAndArticlesOfAssociationComment: "",
        latestAuditedFinancialStatementFile: {},
        latestAuditedFinancialStatementComment: "",
      },

      needtoDelete: [],
    };
  }

  fillFields(el) {
    switch (el.doc_type) {
      case "certificate-of-incorporation":
        if (el.file) {
          this.values.certificateOfIncorporationFile.id = el.file.id;
          this.values.certificateOfIncorporationFile.name = el.file.name;
          this.values.certificateOfIncorporationFile.size = 1;
          this.values.step1Variant = "upload";
          this.values.step1VariantBefore = "upload";
        } else {
          this.values.step1Variant = "notApplicable";
          this.values.step1VariantBefore = "notApplicable";
        }

        this.values.certificateOfIncorporationFile.docId = el.id;
        this.values.certificateOfIncorporationComment = el.comment;

        this.values.responseData.certificateOfIncorporationFile.name =
          el?.file?.name;
        this.values.responseData.certificateOfIncorporationComment =
          el?.comment;
        break;
      case "certificate-of-registered-address":
        if (el.file) {
          this.values.certificateOfRegisteredAddressFile.id = el.file.id;
          this.values.certificateOfRegisteredAddressFile.name = el.file.name;
          this.values.certificateOfRegisteredAddressFile.size = 1;
          this.values.step2Variant = "upload";
          this.values.step2VariantBefore = "upload";
        } else {
          this.values.step2Variant = "notApplicable";
          this.values.step2VariantBefore = "notApplicable";
        }

        this.values.certificateOfRegisteredAddressFile.docId = el.id;
        this.values.certificateOfRegisteredAddressComment = el.comment;

        this.values.responseData.certificateOfRegisteredAddressFile.name =
          el?.file?.name;
        this.values.responseData.certificateOfRegisteredAddressComment =
          el?.comment;
        break;
      case "certificate-of-directors-and-secretary":
        if (el.file) {
          this.values.certificateOfDirectorsFile.id = el.file.id;
          this.values.certificateOfDirectorsFile.name = el.file.name;
          this.values.certificateOfDirectorsFile.size = 1;
          this.values.step3Variant = "upload";
          this.values.step3VariantBefore = "upload";
        } else {
          this.values.step3Variant = "notApplicable";
          this.values.step3VariantBefore = "notApplicable";
        }

        this.values.certificateOfDirectorsFile.docId = el.id;
        this.values.certificateOfDirectorsComment = el.comment;

        this.values.responseData.certificateOfDirectorsFile.name =
          el?.file?.name;
        this.values.responseData.certificateOfDirectorsComment = el?.comment;
        break;
      case "certificate-of-shareholders":
        if (el.file) {
          this.values.certificateOfShareholdersFiles.push({
            id: el?.file?.id,
            docId: el.id,
            name: el?.file?.name,
            size: 1,
          });

          if (this.values.certificateOfShareholdersFiles.length === 1) {
            this.values.step4Variant = "upload";
            this.values.step4VariantBefore = "upload";
            this.values.step4Variant2 = "uploadOne";
            this.values.step4Variant2Before = "uploadOne";
          }

          if (this.values.certificateOfShareholdersFiles.length > 1) {
            this.values.step4Variant = "upload";
            this.values.step4VariantBefore = "upload";
            this.values.step4Variant2 = "uploadMulti";
            this.values.step4Variant2Before = "uploadMulti";
          }
        }

        if (this.values.certificateOfShareholdersFiles.length === 0) {
          this.values.step4Variant = "notApplicable";
          this.values.step4VariantBefore = "notApplicable";
          this.values.step4Variant2 = "";
          this.values.step4Variant2Before = "";
          this.values.step4DocId = el.id;
        }

        this.values.certificateOfShareholdersComment = el.comment;
        this.values.responseData.certificateOfShareholdersFiles.push({
          id: el.file?.id,
          docId: el.id,
          name: el?.file?.name,
          size: 1,
        });
        this.values.responseData.certificateOfShareholdersComment = el?.comment;
        break;
      case "certificate-of-good-standing":
        if (el.file) {
          this.values.certificateOfGoodStandingFile.id = el.file.id;
          this.values.certificateOfGoodStandingFile.name = el.file.name;
          this.values.certificateOfGoodStandingFile.size = 1;
          this.values.step5Variant = "upload";
          this.values.step5VariantBefore = "upload";
        } else {
          this.values.step5Variant = "notApplicable";
          this.values.step5VariantBefore = "notApplicable";
        }

        this.values.certificateOfGoodStandingFile.docId = el.id;
        this.values.certificateOfGoodStandingComment = el.comment;

        this.values.responseData.certificateOfGoodStandingFile.name =
          el?.file?.name;
        this.values.responseData.certificateOfGoodStandingComment = el?.comment;
        break;
      case "memorandum-and-articles-of-association":
        if (el.file) {
          this.values.memorandumAndArticlesOfAssociationFile.id = el.file.id;
          this.values.memorandumAndArticlesOfAssociationFile.name =
            el.file.name;
          this.values.memorandumAndArticlesOfAssociationFile.size = 1;
          this.values.step6Variant = "upload";
          this.values.step6VariantBefore = "upload";
        } else {
          this.values.step6Variant = "notApplicable";
          this.values.step6VariantBefore = "notApplicable";
        }

        this.values.memorandumAndArticlesOfAssociationFile.docId = el.id;
        this.values.memorandumAndArticlesOfAssociationComment = el.comment;

        this.values.responseData.memorandumAndArticlesOfAssociationFile.name =
          el?.file?.name;
        this.values.responseData.memorandumAndArticlesOfAssociationComment =
          el?.comment;
        break;
      case "latest-audited-financial-statement-or-management-accounts":
        if (el.file) {
          this.values.latestAuditedFinancialStatementFile.id = el.file.id;
          this.values.latestAuditedFinancialStatementFile.name = el.file.name;
          this.values.latestAuditedFinancialStatementFile.size = 1;
          this.values.step7Variant = "upload";
          this.values.step7VariantBefore = "upload";
        } else {
          this.values.step7Variant = "notApplicable";
          this.values.step7VariantBefore = "notApplicable";
        }

        this.values.latestAuditedFinancialStatementFile.docId = el.id;
        this.values.latestAuditedFinancialStatementComment = el.comment;

        this.values.responseData.latestAuditedFinancialStatementFile.name =
          el?.file?.name;
        this.values.responseData.latestAuditedFinancialStatementComment =
          el?.comment;
        break;
      case "signatory-confirming-document":
        if (el.file) {
          companySignatoryInst.values.companySignatoryFile.id = el.file.id;
          companySignatoryInst.values.companySignatoryFile.name = el.file.name;
          companySignatoryInst.values.companySignatoryFile.size = 1;
          companySignatoryInst.values.companySignatoryFile.docId = el.id;
          companySignatoryInst.values.companySignatoryFile.type =
            el.file.media_type;
        }
      default:
        null;
    }
  }

  async getCorporateDocData(companyId: string) {
    this.clearData();

    const result = await authorizedRequest({
      url: `/v1/documents/company/${companyId}`,
      method: "GET",
      service: "fca",
      data: {},
    });

    const checkValidation = () => {
      // step 1 validation
      const conditionForUpload =
        this.values.step1Variant === "upload" &&
        this.values.certificateOfIncorporationFile!.name.length > 0;
      const conditionForNotApplicable =
        this.values.step1Variant === "notApplicable" &&
        this.values.certificateOfIncorporationComment.length > 0;

      if (conditionForUpload || conditionForNotApplicable) {
        this.isStepIsValid[1] = true;
      }

      // step 2 validation
      const conditionForUpload2 =
        this.values.step2Variant === "upload" &&
        this.values.certificateOfRegisteredAddressFile!.name.length > 0;
      const conditionForNotApplicable2 =
        this.values.step2Variant === "notApplicable" &&
        this.values.certificateOfRegisteredAddressComment.length > 0;

      if (conditionForUpload2 || conditionForNotApplicable2) {
        this.isStepIsValid[2] = true;
      }

      // step 3 validation
      const conditionForUpload3 =
        this.values.step3Variant === "upload" &&
        this.values.certificateOfDirectorsFile!.name.length > 0;
      const conditionForNotApplicable3 =
        this.values.step3Variant === "notApplicable" &&
        this.values.certificateOfDirectorsComment.length > 0;

      if (conditionForUpload3 || conditionForNotApplicable3) {
        this.isStepIsValid[3] = true;
      }

      // step 4 validation
      const conditionForUpload4 =
        ["uploadOne", "uploadMulti"].includes(this.values.step4Variant2) &&
        this.values.certificateOfShareholdersFiles!.length > 0;
      const conditionForNotApplicable4 =
        this.values.step4Variant === "notApplicable" &&
        this.values.certificateOfShareholdersComment.length > 0;

      if (conditionForUpload4 || conditionForNotApplicable4) {
        this.isStepIsValid[4] = true;
      }

      // step 5 validation
      const conditionForUpload5 =
        this.values.step5Variant === "upload" &&
        this.values.certificateOfGoodStandingFile!.name.length > 0;
      const conditionForNotApplicable5 =
        this.values.step5Variant === "notApplicable" &&
        this.values.certificateOfGoodStandingComment.length > 0;

      if (conditionForUpload5 || conditionForNotApplicable5) {
        this.isStepIsValid[5] = true;
      }

      // step 6 validation
      const conditionForUpload6 =
        this.values.step6Variant === "upload" &&
        this.values.memorandumAndArticlesOfAssociationFile!.name.length > 0;
      const conditionForNotApplicable6 =
        this.values.step6Variant === "notApplicable" &&
        this.values.memorandumAndArticlesOfAssociationComment.length > 0;

      if (conditionForUpload6 || conditionForNotApplicable6) {
        this.isStepIsValid[6] = true;
      }

      // strp 7 validation
      const conditionForUpload7 =
        this.values.step7Variant === "upload" &&
        this.values.latestAuditedFinancialStatementFile!.name.length > 0;
      const conditionForNotApplicable7 =
        this.values.step7Variant === "notApplicable" &&
        this.values.latestAuditedFinancialStatementComment.length > 0;

      if (conditionForUpload7 || conditionForNotApplicable7) {
        this.isStepIsValid[7] = true;
      }

      this.isDataSended = true;
      // this.page = 1;
      // appStore.showConfirmOnboardingModal = false;
    };

    result.forEach((el) => {
      this.fillFields(el);
      checkValidation();
    });
  }
}

export const corparateDoc = new CorporateDocumentBC();

const CorporateDocument = observer(() => {
  const getPage = (page: number) => {
    switch (page) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <Step5 />;
      case 6:
        return <Step6 />;
      case 7:
        return <Step7 />;
      default:
        return <div />;
    }
  };

  return <div className={s.container}>{getPage(corparateDoc.page)}</div>;
});

export default CorporateDocument;
