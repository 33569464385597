// @ts-nocheck
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  AddIcon,
  Button,
  CloseBigIcon,
  EditIcon,
  PencilIcon,
  DoneIcon,
  InputAmount,
  EurIcon,
  UsdIcon,
} from "@gamesb42/ui-kit";
import moment from "moment";
import { Input } from "antd";

import Modal from "components/Modal";
import Auth from "store/Auth";
import userStore from "store/User";
import platformStore from "../store";
import applaudStore from "../../../../store/Applaud";
import { authorizedRequest } from "utils/authorization";
import { formatCurrency } from "../../../../utils/helpers";

import s from "./SenderRequestModal.module.scss";

function PlatformRow({
  el,
  i,
  fundingData,
  setFundingData,
  originalFundingData,
}) {
  const [price, setPrice] = useState(el.ransom_amount);

  const formatDate = (dateIn: string) =>
    moment(dateIn, "YYYY-MM-DD").format("DD.MM.YYYY");

  return (
    <div className={`${s.platformRow} ${s.centerAlign}`} key={i}>
      <div
        className={`${s.centerAlign} ${s.platformEl}`}
        style={{ width: "180px", gap: "4px" }}
      >
        <img src={el.platform_logo} alt="platform" />
        {el.platform}
      </div>

      <div
        className={s.platformEl}
        style={{ width: "198px", padding: "0px 15px" }}
      >
        {`${formatDate(el.period_from)} -
    ${formatDate(el.period_to)}`}
      </div>

      <div
        className={s.platformEl}
        style={{ width: "167px", justifyContent: "flex-end" }}
      >
        {formatCurrency(
          el.available_receivable,
          userStore.getDefaultCurrency?.value
        )}
      </div>

      <PRCell
        el={el}
        i={i}
        fundingData={fundingData}
        setFundingData={setFundingData}
        setPrice={setPrice}
        originalFundingData={originalFundingData}
      />

      <div
        className={s.platformEl}
        style={{ width: "128px", justifyContent: "flex-end" }}
      >
        {formatCurrency(
          price * el.purchase_price_percentage,
          userStore.getDefaultCurrency?.value
        )}
      </div>
    </div>
  );
}

function PRCell({
  el,
  i,
  fundingData,
  setFundingData,
  setPrice,
  originalFundingData,
}) {
  const [form, setForm] = useState("default");
  const [value, setValue] = useState(el.ransom_amount);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const calculateCurrency = el.currency === "EUR" ? <EurIcon /> : <UsdIcon />;

  const handleChange = (e) => {
    setIsError(false);
    setValue(e.target.value);
  };

  const handleUpdate = () => {
    const foundOriginalIndex = originalFundingData.platforms.findIndex(
      (x) => x.platform_id == el.platform_id
    );

    if (
      parseFloat(value) >
        parseFloat(
          originalFundingData.platforms[foundOriginalIndex].ransom_amount
        ) ||
      parseFloat(value) < 4000
    ) {
      setIsError(true);
      return;
    }

    const foundIndex = fundingData.platforms.findIndex(
      (x) => x.platform_id == el.platform_id
    );

    let newFound = JSON.parse(JSON.stringify(originalFundingData.platforms));

    newFound[foundIndex].ransom_amount = value;
    newFound[foundIndex].available_advance = (
      value * el.purchase_price_percentage
    ).toFixed(2);

    setFundingData({ ...originalFundingData, platforms: [...newFound] });
    setPrice(value);

    setForm("default");
  };

  return (
    <div
      className={s.platformEl}
      style={{ width: "208px", justifyContent: "flex-end" }}
    >
      {form === "default" && (
        <div className={s.centerAlign} style={{ gap: "16px" }}>
          <div className={s.centerAlign}>
            {formatCurrency(value, userStore.getDefaultCurrency?.value)}
          </div>
          <Button
            onClick={() => setForm("edit")}
            icon={<PencilIcon />}
            size="small"
          />
        </div>
      )}
      {form === "edit" && (
        <div className={s.centerAlign} style={{ gap: "16px" }}>
          <div className={s.centerAlign} style={{ width: "125px" }}>
            <div className={s.column}>
              <Input
                className={isError ? s.redInputText : ""}
                size="small"
                prefix={calculateCurrency}
                value={value}
                onChange={handleChange}
                status={isError ? "error" : "default"}
              />
              {isError && <div className={s.errorMessage}>Amount is wrong</div>}
            </div>
          </div>
          <Button
            onClick={handleUpdate}
            icon={<DoneIcon size="16" />}
            size="small"
          />
        </div>
      )}
    </div>
  );
}

type SendRequestProps = {
  onClose: () => void;
  isOpen: boolean;
  setShowFundingModal: any;
};

const SendRequestModal = observer(
  ({ onClose, isOpen, setShowFundingModal }: SendRequestProps) => {
    const [fundingData, setFundingData] = useState<any>();
    const [originalFundingData, setOriginalFundingData] = useState<any>();

    const getFunding = () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Auth.scaToken}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        `${process.env.REACT_APP_URL}/ui/api/v1/purchase_request/available_advance/?currency=${userStore.getDefaultCurrency?.value}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setFundingData(result);
          setOriginalFundingData(result);
        })
        .catch(() => {});
    };

    const handleCreate = async () => {
      const available_advance = fundingData.platforms.map((el) => {
        const due_date = platformStore.data.platforms.filter(
          (platform) => platform.platform_id === el.platform_id
        )[0].payment_date;

        return {
          platform_id: el.platform_id,
          purchase_price: el.available_advance,
          currency: el.currency,
          period_from: el.period_from,
          period_to: el.period_to,
          available_receivables: el.available_receivable,
          purchased: el.ransom_amount,
          due_date,
        };
      });

      const { request_number } = await authorizedRequest({
        url: "/ui/api/v1/purchase_requests/now/",
        method: "POST",
        data: {
          currency: userStore.getDefaultCurrency?.value,
          available_advance,
        },
      });
      platformStore.requestCreated = request_number;
      await applaudStore.getApplaud();

      onClose();
      setShowFundingModal(true);
    };

    useEffect(() => {
      getFunding();
    }, []);

    return (
      <Modal isOpen={isOpen}>
        <div className={s.container}>
          <div className={s.titleBlock}>
            <div className={s.titleEl}>Get Funding</div>
            <div style={{ cursor: "pointer" }} onClick={onClose}>
              <CloseBigIcon />
            </div>
          </div>

          {/* labels */}
          <div className={s.labelBlock}>
            <div className={s.labelEl} style={{ width: "180px" }}>
              Distribution platform
            </div>
            <div className={s.labelEl} style={{ width: "198px" }}>
              Period
            </div>
            <div className={s.labelEl} style={{ width: "167px" }}>
              Available receivables
            </div>
            <div
              className={s.labelEl}
              style={{ width: "208px", justifyContent: "flex-end" }}
            >
              Purchased receivables
            </div>
            <div className={s.labelEl} style={{ width: "128px" }}>
              Purchase price
            </div>
          </div>

          {fundingData && (
            <>
              {/* total */}
              <div className={s.totalBlock}>
                <div className={s.totalEl} style={{ width: "378px" }}>
                  Total amount:
                </div>
                <div
                  className={s.totalEl}
                  style={{ width: "167px", justifyContent: "flex-end" }}
                >
                  {formatCurrency(
                    fundingData?.available_receivable,
                    userStore.getDefaultCurrency?.value
                  )}
                </div>
                <div
                  className={s.totalEl}
                  style={{ width: "208px", justifyContent: "flex-end" }}
                ></div>
                <div
                  className={s.totalEl}
                  style={{ width: "128px", justifyContent: "flex-end" }}
                ></div>
              </div>

              {/* platform */}
              <div className={s.platformBlock}>
                {fundingData?.platforms?.map((el, i) => (
                  <PlatformRow
                    el={el}
                    i={i}
                    fundingData={fundingData}
                    setFundingData={setFundingData}
                    originalFundingData={originalFundingData}
                  />
                ))}
              </div>
            </>
          )}

          {/* btns  */}
          <div className={s.btsBlock}>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              violet
              style={{ color: "#fff" }}
              onClick={handleCreate}
              loading={!fundingData}
            >
              Create request
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
);

export default SendRequestModal;
