import { about } from "./components/AboutCompany";
import { appInfoInst } from "./components/AppInfo";
import { companySignatoryInst } from "./components/CompanySignatory";
import { contactPersonInst } from "./components/ContactPerson";
import { corparateDoc } from "./components/CorporateDocument";
import { director } from "./components/Director";

export default function isCompleteAllSteps(isIE: boolean) {
  let textState = false

  textState =
    appInfoInst.isDataSended &&
    about.isDataSended &&
    companySignatoryInst.isDataSended &&
    contactPersonInst.isDataSended &&
    (isIE ? true : corparateDoc.isDataSended) && director.isDataSended;

  return textState;
}