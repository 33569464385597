import { makeAutoObservable } from "mobx";

type Notification = {
  type: string;
  title: string;
  message?: string;
  width: number;
  visible?: boolean;
};

class App {
  constructor() {
    makeAutoObservable(this);
  }

  requestId: string | null = null;

  showAccountsDrawer = false;

  isLoadedMainInfo = false;

  isError = false;

  isPortalOpened = false;

  showConfirmOnboardingModal = false;

  notification: Notification = {
    type: "",
    title: "",
    message: "",
    width: 0,
    visible: false,
  };

  //@ts-ignore
  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  setNotification = async (notification: Notification) => {
    if (this.notification.visible) {
      await this.resetNotification();
    }
    this.notification = {
      ...notification,
      visible: true,
    };

    await this.delay(4000);
    appStore.resetNotification();
  };

  resetNotification = () => {
    this.notification.visible = false;
    return new Promise<void>((res) => {
      window.setTimeout(() => {
        this.notification = {
          type: "",
          title: "",
          message: "",
          width: 0,
        };
        res();
      }, 1000);
    });
  };
}

const appStore = new App();
export default appStore;
