//@ts-nocheck
import { observer } from "mobx-react";
import { useEffect } from "react";
import { Input } from "@gamesb42/ui-kit";

import UploadFile from "components/UploadFile";
import { director } from ".";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as Pdf } from "./assets/pdf.svg";
import userStore from "../../../../store/User";
import CorpDocs from "./CorpDocs";
import FileName from "./components/FileName";

import s from "./styles.module.scss";

const IEDocs = observer(() => {
  useEffect(() => {
    const conditionForUpload =
      director.values.profOfId!.name.length > 0 &&
      director.values.profOfAddress!.name.length > 0 &&
      director.values.profOfFunds!.name.length > 0;

    if (conditionForUpload) {
      director.isIEDataValid = true;
    } else {
      director.isIEDataValid = false;
    }
  }, [
    director.values.profOfId!.name,
    director.values.profOfAddress!.name,
    director.values.profOfFunds!.name,
  ]);

  // clear files on inputs change
  function clearFiles() {
    director.values.profOfId.size = 0;
    director.values.profOfAddress.size = 0;
    director.values.resume.size = 0;
    director.values.profOfFunds.size = 0;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <div>
          <div className={s.label}>Proof of ID*</div>
          <div className={s.uploadHolder}>
            {(director.values.profOfId!.size === 0 && (
              <UploadFile
                dragText="Drag and drop file*"
                uploadText={`Upload a clear copy of the person’s passport
              <br/>50 Mb max. weight`}
                onUpload={(e, file) => {
                  director.values.profOfId!.name = file.name;
                  director.values.profOfId!.size = file.size;
                  director.values.profOfId!.value = file;
                }}
              />
            )) || <FileName fileName="profOfId" clearFiles={clearFiles} />}
          </div>
        </div>

        <div>
          <div className={s.label}>Proof of Address*</div>
          <div className={s.uploadHolder}>
            {(director.values.profOfAddress!.size === 0 && (
              <UploadFile
                dragText="Drag and drop file*"
                uploadText={`Upload utility bill or bank statement issued in last 90 days (mobile phones bills and P.O. Box addresses <br />are not accepted). 50 Mb max. weight`}
                onUpload={(e, file) => {
                  director.values.profOfAddress!.name = file.name;
                  director.values.profOfAddress!.size = file.size;
                  director.values.profOfAddress!.value = file;
                }}
              />
            )) || <FileName fileName="profOfAddress" clearFiles={clearFiles} />}
          </div>
        </div>

        <div>
          <div className={s.label}>Proof of Funds*</div>
          <div className={s.uploadHolder}>
            {(director.values.profOfFunds!.size === 0 && (
              <UploadFile
                dragText="Drag and drop file*"
                uploadText={`Upload proof of funds (A bank statement, tax declarations, security statement, or custody statement <br />usually qualify as proof of funds). 50 Mb max. weight`}
                onUpload={(e, file) => {
                  director.values.profOfFunds!.name = file.name;
                  director.values.profOfFunds!.size = file.size;
                  director.values.profOfFunds!.value = file;
                }}
              />
            )) || <FileName fileName="profOfFunds" clearFiles={clearFiles} />}
          </div>
        </div>
      </div>
    </>
  );
});

export default IEDocs;
