import { observer } from "mobx-react";
import React from "react";
import { Button, ArrowLeftIcon, Modal } from "@gamesb42/ui-kit";
import { corparateDoc } from "../../index";
// import connectionStore from "../../../connectionStore";
// import connectSources from "../../../../../../store/ConnectSources";
// import appStore from "../../../../../../store/App";
import s from "./styles.module.scss";
import connectionStore from "pages/Connection/components/connectionStore";
import appStore from "store/App";

type Page = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const Paginator = observer(() => {
  const emptyArr: number[] = [];
  emptyArr.length = 8;
  emptyArr.fill(1, 1);

  return (
    <div className={s.paginatorHolder}>
      <Button
        icon={<ArrowLeftIcon size={16} />}
        size="large"
        onClick={() => {
          if (corparateDoc.page > 1) {
            corparateDoc.page -= 1;
          } else {
            connectionStore.currentScreen = "infoScreen";
            appStore.showAccountsDrawer = false;
          }
        }}
        className={s.backBtn}
      >
        Go back
      </Button>

      <div className={s.dotsHolder}>
        {emptyArr.map((item, idx) => {
          return (
            <div
              className={idx === corparateDoc.page ? s.active : s.inactive}
            />
          );
        })}
      </div>

      <Button
        violet
        type="primary"
        size="large"
        onClick={async () => {
          if (corparateDoc.isLastStep) {
            // corparateDoc.page = 1;
            appStore.showConfirmOnboardingModal = true;
            return;
          }
          corparateDoc.page += 1;
        }}
        className={s.nextBtn}
        disabled={!corparateDoc.isStepIsValid[corparateDoc.page as Page]}
      >
        {corparateDoc.isLastStep ? "Submit" : "Proceed"}
      </Button>
    </div>
  );
});
