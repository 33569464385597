import React, { useEffect, useState } from "react";

import { formatCurrency } from "utils/helpers";
import { Status } from "store/Applaud";
import userStore from "store/User";
import appStore from "store/App";
import { ReactComponent as New } from "../../assets/new.svg";
import { ReactComponent as Funded } from "../../assets/funded.svg";
import { ReactComponent as Pending } from "../../assets/pending.svg";
import { ReactComponent as Rejected } from "../../assets/rejected.svg";
import { ReactComponent as Signed } from "../../assets/signed.svg";
import { ReactComponent as SignatureRequired } from "../../assets/signature-required.svg";
import { about } from "pages/Connection/components/AboutCompany";

import styles from "../styles.module.scss";

export const Icon = ({ status }: { status: Status }) => {
  if (status === "new") return <SignatureRequired />;
  if (status === "rejected") return <Rejected />;
  if (status === "pending") return <Pending />;
  if (status === "signed") return <Pending />;
  if (status === "funded") return <Funded />;
  return null;
};

const textByStatus = (status: string) => {
  if (status === "new")
    return `Link for signature was sent to ${about?.signer?.email}`;
  if (["signed", "pending"].includes(status)) return "being processed";
  if (status === "rejected") return "was rejected.";
  return "pending processing by manager.";
};

const Request = ({
  status,
  request,
  purchase_price,
  id,
}: {
  status: Status;
  request: number;
  purchase_price: string;
  id: string;
}) => {
  return (
    <div
      className={`${styles.editRequestHolder} ${styles[status]}`}
      onClick={() => {
        appStore.requestId = id;
      }}
    >
      <div className={styles.newText}>
        <div>
          <span className={styles.link}>Request No {request} </span>
          <span className={styles.regularText}>&nbsp;for&nbsp;&nbsp;</span>
          <span className={styles.amount}>
            {formatCurrency(
              +purchase_price,
              userStore.getDefaultCurrency?.value
            )}
          </span>
          <span className={styles.regularText}>
            &nbsp;&nbsp;{textByStatus(status)}
          </span>
        </div>
        <Icon status={status} />
      </div>
    </div>
  );
};

export default Request;
