import { makeAutoObservable } from "mobx";

export type DrawerScreens =
  | "revenuesList"
  | "aboutCompany"
  | "connectAccount"
  | "infoScreen"
  | "personInfo"
  | "corporateDocs"
  | "appInfo"
  | "companySignatory"
  | "directors";

class ConnectionStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentScreen: DrawerScreens = "revenuesList";

  reset = () => {
    this.currentScreen = "revenuesList";
  };
}

const connectionStore = new ConnectionStore();
export default connectionStore;
