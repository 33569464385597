import { makeAutoObservable } from "mobx";
import { authorizedRequest } from "../utils/authorization";
import userStore from "./User";

export type Accounts = {
  storeId: string;
  storeName: string;
  credentials: string | null;
  status: string;
  connected: boolean;
  companyId: string;
  currency: string;
  activationDate: string | null;
  syncStatus: string | null;
  lastSync: string | null;
  lastStartSync: string | null;
  connectionErrorText: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  id: string;
  storeSyncType: string | null;
};

export type Source = {
  activationCutoffPeriod: number;
  advanceCutoffPeriod: number;
  calType: string;
  code: null | string;
  createdAt: string;
  id: string;
  logo: string;
  name: string;
  netDay: number;
  status: string;
  syncType: string;
  updatedAt: string;
  syncStatus: string;
};

class ConnectSources {
  constructor() {
    makeAutoObservable(this);
  }

  sourcesList: [] | Source[] = [];

  sourcesListSelf: [] | Source[] = [];

  accounts: Accounts[] | [] = [];

  selectedSourcesList: string[] = [];

  totalAccountsWithCredentials = 0;

  hasRevenueSource = false;

  get accountsById() {
    const result: any = {};
    if (this.accounts.length) {
      this.accounts.forEach((account) => {
        result[account.storeId] = account;
      });
    }
    return result;
  }

  get existingAccounts() {
    const result: { [key: string]: Accounts } = {};
    this.accounts.forEach((account) => {
      if (account.status !== "deleted") result[account.storeId] = account;
    });
    return result;
  }

  getAccounts = async () => {
    if (userStore?.user?.company.id) {
      const accounts = await authorizedRequest({
        service: "pds",
        url: "/v1/accounts",
        params: {
          company_id: userStore.user.company.id,
          size: "2147483647",
        },
      });
      this.accounts = accounts.items;
    }
  };

  get accountsWithCredentials() {
    if (this.accounts.length)
      return this.accounts.filter((item) => item.credentials !== null);
    return [];
  }

  getSources = async () => {
    if (this.sourcesListSelf.length) return;
    if (!this.accounts.length) await this.getAccounts();
    const sources = await authorizedRequest({
      service: "pds",
      url: "/v1/stores",
    });
    this.sourcesListSelf = sources.filter(
      (source: Source) =>
        source.syncType === "auto" || this.existingAccounts[source.id]
    );

    this.hasRevenueSource = this.accounts.some(
      (el) =>
        (el?.syncStatus !== "credentials_not_filled" &&
          el?.storeSyncType === "auto") ||
        el?.storeSyncType === "file"
    );
  };

  getTotalAccountsWithCredentials = async () => {
    const response = await authorizedRequest({
      service: "pds",
      url: "/v1/accounts?page=1&size=1&show_without_credentials=false",
    });
    this.totalAccountsWithCredentials = response.total;
  };

  customSource = "";
}

const connectSources = new ConnectSources();
export default connectSources;
