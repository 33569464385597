import React from 'react';
import { observer } from 'mobx-react';
import connectSources, { Source } from 'store/ConnectSources';
import CheckBox from 'components/CheckBox';
import styles from './styles.module.scss';

const RevenueListItem = ({ id, name, logo }: Source) => {
  return (
    <div className={`${styles.holder} ${(connectSources.accountsById[id] || connectSources.selectedSourcesList.includes(id)) ? styles.connected : ''}`}>
      {
        logo && <img className={styles.logo} alt={name} src={logo} />
      }
      <div className={styles.name}>{name}</div>
      <CheckBox
        className={styles.checkbox}
        checked={connectSources.accountsById[id] || connectSources.selectedSourcesList.includes(id)}
        onChange={() => {
          // если аккаунта нет в списке приконнекченных, разрешаем добавить или убрать его
          if (!connectSources.accountsById[id]) {
            if (connectSources.selectedSourcesList.includes(id)) {
              // @ts-ignore
              connectSources.selectedSourcesList.remove(id);
            } else {
              connectSources.selectedSourcesList.push(id);
            }
          }
        }}
      />
    </div>
  );
};

export default observer(RevenueListItem);
