//@ts-nocheck
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
// @ts-ignore
import * as Validator from "validatorjs";
import TextInput from "../TextInput";
import { Title3 } from "../Typography";
import { about } from "../AboutCompany/index";
import styles from "./styles.module.scss";
import { contactPersonInst } from ".";

const Step2 = observer(() => {
  const contact = about.values.contacts.find((item) => item.type === "contact");

  // console.log("-->");
  // console.log(about.values.contacts);
  // console.log("<--");

  const rules = {
    full_name: "required",
    position: "required",
    email: "required|email",
    contact_phone: [
      "required",
      "regex:/^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,8}$/",
    ],
  };

  const validation = useMemo(
    () =>
      new Validator(
        {
          full_name: contact?.full_name,
          position: contact?.position,
          email: contact?.email,
          contact_phone: contact?.contact_phone,
        },
        rules,
        {
          required: "This field is required",
          email: "The email format is invalid",
          digits_between: "Field should contain from 10 up to 14 digits only",
          "regex.contact_phone": "Phone number with country code",
        }
      ),
    [
      contact?.full_name,
      contact?.position,
      contact?.email,
      contact?.contact_phone,
    ]
  );
  const isValid = validation.passes();

  useEffect(() => {
    const currentSigner = about.values.contacts.find(
      (item) => item.type === "signer"
    );
    const currentContact = about.values.contacts.find(
      (item) => item.type === "contact"
    );
    const currentSignertIndx = about.values.contacts.indexOf(currentSigner);
    const currentContactIndx = about.values.contacts.indexOf(currentContact);

    // надо заполнить данными из падписанта если он есть
    if (contactPersonInst.values.step1Variant === "yes") {
      if (currentSignertIndx > -1) {
        if (!contactPersonInst.hasContact) {
          about.values.contacts.push({
            type: "contact",
            full_name: currentSigner?.full_name,
            email: currentSigner?.email,
            contact_phone: currentSigner?.contact_phone,
            position: currentSigner?.position,
          });
        }
      }
    } else {
      // надо заполнить данными из контакта если он есть
      if (contactPersonInst.hasContact) {
        about.values.contacts[currentContactIndx] = {
          type: "contact",
          full_name: currentContact?.full_name,
          email: currentContact?.email,
          contact_phone: currentContact?.contact_phone,
          position: currentContact?.position,
        };
      } else {
        about.values.contacts.push({
          type: "contact",
          full_name: currentContact?.full_name,
          email: currentContact?.email,
          contact_phone: currentContact?.contact_phone,
          position: currentContact?.position,
        });
      }
    }
  }, []);

  useEffect(() => {
    about.isPersonalDataValid = isValid;
    contactPersonInst.isStepIsValid[2] = isValid;
  }, [isValid]);

  if (!contact) return null;

  return (
    <div className={styles.container}>
      <div>
        <Title3 className={styles.subTitle}>
          Please provide information about the main contact of your company*
        </Title3>
        <TextInput
          onChange={(e) => {
            contact.full_name = e;
          }}
          label="Full name*"
          dataTest="fullName"
          className={styles.textField}
          value={contact?.full_name}
          error={validation.errors.first("full_name")}
        />

        <TextInput
          onChange={(e) => {
            contact.position = e;
          }}
          label="Position*"
          dataTest="position"
          className={styles.textField}
          value={contact?.position}
          error={validation.errors.first("position")}
        />

        <TextInput
          onChange={(e) => {
            contact.email = e;
          }}
          label="Email*"
          dataTest="email"
          className={styles.textField}
          value={contact?.email}
          error={validation.errors.first("email")}
        />

        <TextInput
          onChange={(e) => {
            contact.contact_phone = e;
          }}
          label="Phone number*"
          dataTest="phone"
          className={styles.textField}
          value={contact?.contact_phone}
          error={validation.errors.first("contact_phone")}
        />
      </div>
    </div>
  );
});

export default Step2;
