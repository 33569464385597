import { observer } from "mobx-react";
import { Button, ArrowLeftIcon, Modal } from "@gamesb42/ui-kit";
// import { corparateDoc } from "../../index";
import connectionStore from "pages/Connection/components/connectionStore";
import appStore from "store/App";

import s from "./styles.module.scss";
import { appInfoInst } from "../..";
import userStore from "store/User";

type Page = 1 | 2 | 3 | 4 | 5;

export const Paginator = observer(() => {
  const emptyArr: number[] = [];
  emptyArr.length = 6;
  emptyArr.fill(1, 1);

  return (
    <div className={s.paginatorHolder}>
      <Button
        icon={<ArrowLeftIcon size={16} />}
        size="large"
        onClick={() => {
          if (appInfoInst.page > 1) {
            appInfoInst.page -= 1;
          } else {
            connectionStore.currentScreen = "infoScreen";
            appStore.showAccountsDrawer = false;
          }
        }}
        className={s.backBtn}
      >
        Go back
      </Button>

      <div className={s.dotsHolder}>
        {emptyArr.map((item, idx) => {
          return (
            <div className={idx === appInfoInst.page ? s.active : s.inactive} />
          );
        })}
      </div>

      <Button
        violet
        type="primary"
        size="large"
        onClick={async () => {
          if (appInfoInst.isLastStep) {
            // appStore.showConfirmOnboardingModal = true;
            await appInfoInst.sendData(
              userStore.me?.company?.id.toString() || ""
            );
            appStore.showConfirmOnboardingModal = false;
            appStore.showAccountsDrawer = false;
            appInfoInst.page = 1;
            connectionStore.currentScreen = "infoScreen";
            appInfoInst.isDataSended = true;

            return;
          }
          appInfoInst.page += 1;
        }}
        className={s.nextBtn}
        disabled={!appInfoInst.isStepIsValid[appInfoInst.page as Page]}
      >
        {appInfoInst.isLastStep ? "Submit" : "Proceed"}
      </Button>
    </div>
  );
});
