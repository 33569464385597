import { observer } from "mobx-react";
import { Button, ArrowLeftIcon, Modal } from "@gamesb42/ui-kit";
import connectionStore from "pages/Connection/components/connectionStore";
import appStore from "store/App";

import s from "./styles.module.scss";
import { companySignatoryInst } from "../..";

export const Paginator = observer(() => {
  return (
    <div className={s.paginatorHolder}>
      <Button
        icon={<ArrowLeftIcon size={16} />}
        size="large"
        onClick={() => {
          connectionStore.currentScreen = "infoScreen";
          appStore.showAccountsDrawer = false;
        }}
        className={s.backBtn}
      >
        Go back
      </Button>

      <Button
        violet
        type="primary"
        size="large"
        onClick={async () => {
          await companySignatoryInst.sendFile();
          connectionStore.currentScreen = "infoScreen";
          companySignatoryInst.isDataSended = true;
          appStore.showAccountsDrawer = false;
        }}
        className={s.nextBtn}
        disabled={
          companySignatoryInst.values.companySignatoryFile!.size === 0 ||
          !companySignatoryInst.isStepIsValid[1]
        }
      >
        Submit
      </Button>
    </div>
  );
});
