import { makeAutoObservable } from 'mobx';
import { Currency } from 'types';
import { codeToCurrency, currencyToCode, formatCurrency } from 'utils/helpers';
import { authorizedRequest } from '../utils/authorization';

interface Account {
  account: string,
  balance: number,
  balance_date: string,
  company_id: string,
  company_name: string,
  created: string,
  currency: '840' | '978',
  id: string,
  modified: string,
  type: 'counterparty-client' | 'bank',
}

class Accounts {
  constructor() {
    makeAutoObservable(this);
  }

  accounts: Account[] = [];

  isLoading = true;

  getAccounts = async (companyId: string) => {
    this.isLoading = true;
    this.accounts = await authorizedRequest({
      service: 'transactions',
      url: `/v1/accounts/company-id/${companyId}`,
    });
    this.isLoading = false;
  };

  get getAccountBalance() {
    const result:{[key: string]: number} = {};
    this.accounts.forEach(account => {
      if (account.type === 'counterparty-client') {
        result[String(codeToCurrency(account.currency))] = +account.balance;
      }
    });
    return result;
  }
}

const accountsStore = new Accounts();
export default accountsStore;
