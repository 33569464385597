import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { Title2 } from '../Typography';
import accountConnect from '../accountConnect';
import connectionStore from '../connectionStore';
import connectSources from '../../../../store/ConnectSources';
import { RevenueBody } from '../../../RevenueSources';
import styles from './styles.module.scss';
import { ReactComponent as Clip } from './assets/clip.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Loading } from './assets/loading.svg';

const ConnectAccount = observer(() => {
  useEffect(() => {
    accountConnect.getFields(accountConnect.account?.code || '');
    return () => {
      accountConnect.reset();
    };
  }, []);

  const history = useHistory();

  return (
    <div className={styles.container}>
      {
        accountConnect.uploading &&
        <div className={styles.loadingHolder}><Loading /></div>
      }

      <div className={styles.titleHolder}>
        { accountConnect.account?.logo && <img alt="src" src={accountConnect.account?.logo} className={styles.icon} /> || null }
        <Title2>
          Connect your {accountConnect.account?.name} account
        </Title2>
      </div>

      {
        accountConnect.instructionUrl &&
        <a className={styles.link} rel="noreferrer" target="_blank" href={accountConnect.instructionUrl}>Connection instructions</a>
      }
      <div className={styles.whiteBlock}>
        <RevenueBody />
      </div>
      <div className={styles.btnsHolder}>
        <Button
          type="flat"
          className={styles.connectBtn}
          onClick={() => {
            connectionStore.currentScreen = 'revenuesList';
          }}
        >Connect other source
        </Button>
        <Button
          type="submit"
          withArrow
          className={styles.submit}
          onClick={async () => {
            await accountConnect.addAccount();
            if (connectSources.sourcesListSelf.length) {
              history.push('/');
            }
            connectionStore.currentScreen = 'infoScreen';
          }}
          disabled={!accountConnect.canSend}
        >
          Connect
        </Button>
      </div>
    </div>
  );
});

const AttachedFile = observer(() => {
  return (
    <div className={styles.attachedContainer}>
      <Clip /><div className={styles.name}>{accountConnect.file.name}</div>
      <button
        className={styles.close}
        onClick={() => {
          accountConnect.file = {
            name: '',
            value: '',
            size: 0,
          };
        }}
      ><Close className={styles.closeIcon} />
      </button>
    </div>
  );
});

export default ConnectAccount;
