import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode,
  className?: string,
  delay?: number,
  animation: string,
}

const AnimatedContainer = ({ children, className, delay, animation }:Props) => {
  const [delayTimeout, setDelay] = useState(delay || false);
  useEffect(() => {
    if (delayTimeout) {
      setTimeout(() => { setDelay(false); }, delay);
    }
  }, []);

  if (delayTimeout) return null;

  return (
    <div className={cn(styles[animation], className)}>
      {children}
    </div>
  );
};

export default AnimatedContainer;
