import React, { useRef } from "react";
import styles from "../../pages/Connection/components/ConnectAccount/styles.module.scss";
import Button from "../Button";

const UploadFile = ({
  onUpload,
  dragText,
  uploadText,
}: {
  onUpload: (
    e: ProgressEvent<FileReader>,
    file: any //{ name: string; size: number }
  ) => void;
  dragText?: string;
  uploadText?: string;
}) => {
  const fileEl = useRef() as React.MutableRefObject<HTMLInputElement>;

  const readFile = (files: any) => {
    try {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        onUpload(e, file);
      };

      reader.readAsText(file);
    } catch (ev: any) {
      console.error(ev);
    }
  };

  const drag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onDropFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    readFile(e.dataTransfer.files);
    e.dataTransfer.clearData();
  };

  return (
    <div
      className={styles.uploadHolder}
      onDragOver={drag}
      onDragEnter={drag}
      onDragLeave={drag}
      onDrop={onDropFile}
    >
      <div className={styles.dragText}>
        {dragText ? dragText : "Drag and drop file"}
      </div>
      {uploadText && (
        <div
          className={styles.uploadText}
          dangerouslySetInnerHTML={{ __html: uploadText }}
        ></div>
      )}
      {!uploadText && (
        <div className={styles.uploadText}>
          "you can upload file (50 Mb total weight)"
        </div>
      )}
      <input
        type="file"
        ref={fileEl}
        className={styles.filesInput}
        onChange={(e) => readFile(e.target.files)}
      />
      <Button
        type="flat"
        className={styles.uploadBtn}
        onClick={() => {
          if (fileEl.current) {
            fileEl.current.click();
          }
        }}
      >
        Click to Upload
      </Button>
    </div>
  );
};

export default UploadFile;
