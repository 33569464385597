import { useEffect } from "react";
import { observer } from "mobx-react";

import CheckBox from "components/CheckBox";
import { appInfoInst } from ".";

import s from "./styles.module.scss";
import TextArea from "components/TextArea";
import { Radio } from "@gamesb42/ui-kit";

const Step3 = observer(() => {
  useEffect(() => {
    appInfoInst.isStepIsValid[3] = Object.values(
      appInfoInst.values.uaSpend
    ).some((el) => el === true);
  }, [
    appInfoInst.values.uaSpend.facebook,
    appInfoInst.values.uaSpend.googleAds,
    appInfoInst.values.uaSpend.appleSearchAds,
    appInfoInst.values.uaSpend.snapchat,
    appInfoInst.values.uaSpend.tikTok,
    appInfoInst.values.uaSpend.unityAds,
    appInfoInst.values.uaSpend.dontKnow,
    appInfoInst.values.uaSpend.other,
  ]);

  return (
    <>
      <h3>
        <b>UA spends sources*</b>
      </h3>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <CheckBox
          checked={appInfoInst.values.uaSpend.facebook}
          onChange={() =>
            (appInfoInst.values.uaSpend.facebook =
              !appInfoInst.values.uaSpend.facebook)
          }
          label="Facebook"
        />
        <CheckBox
          checked={appInfoInst.values.uaSpend.googleAds}
          onChange={() =>
            (appInfoInst.values.uaSpend.googleAds =
              !appInfoInst.values.uaSpend.googleAds)
          }
          label="Google Ads"
        />
        <CheckBox
          checked={appInfoInst.values.uaSpend.appleSearchAds}
          onChange={() =>
            (appInfoInst.values.uaSpend.appleSearchAds =
              !appInfoInst.values.uaSpend.appleSearchAds)
          }
          label="Apple Search Ads"
        />
        <CheckBox
          checked={appInfoInst.values.uaSpend.snapchat}
          onChange={() =>
            (appInfoInst.values.uaSpend.snapchat =
              !appInfoInst.values.uaSpend.snapchat)
          }
          label="Snapchat"
        />
        <CheckBox
          checked={appInfoInst.values.uaSpend.tikTok}
          onChange={() =>
            (appInfoInst.values.uaSpend.tikTok =
              !appInfoInst.values.uaSpend.tikTok)
          }
          label="Tik Tok"
        />
        <CheckBox
          checked={appInfoInst.values.uaSpend.unityAds}
          onChange={() =>
            (appInfoInst.values.uaSpend.unityAds =
              !appInfoInst.values.uaSpend.unityAds)
          }
          label="UnityAds"
        />
        <CheckBox
          checked={appInfoInst.values.uaSpend.dontKnow}
          onChange={() =>
            (appInfoInst.values.uaSpend.dontKnow =
              !appInfoInst.values.uaSpend.dontKnow)
          }
          label="I don’t know"
        />
        <CheckBox
          checked={appInfoInst.values.uaSpend.other}
          onChange={() =>
            (appInfoInst.values.uaSpend.other =
              !appInfoInst.values.uaSpend.other)
          }
          label="Other"
        />
      </div>
    </>
  );
});

export default Step3;
