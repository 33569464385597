// @ts-nocheck
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { CountryIcon, EditIcon, Input } from "@gamesb42/ui-kit";
// import Button from "../../components/Button";
import { Button } from "@gamesb42/ui-kit";
import connectSources from "../../store/ConnectSources";
import appStore from "../../store/App";
import userStore from "../../store/User";
import ConfirmModal, { InfoBlock } from "../../components/ConfirmModal";
import { Status } from "../../store/Applaud";
import { Title2, Text1, Title3, Text2 } from "./components/Typography";
import Drawer from "./components/Drawer";
import RevenueCard from "./components/RevenueCard";
import ConnectAccount from "./components/ConnectAccount";
import RevenueListItem from "./components/RevenueList";
import TextArea from "components/TextArea";
import AboutCompany, { about } from "./components/AboutCompany";
import { ReactComponent as OpenDrawer } from "./assets/openDrawer.svg";
import connectionStore, { DrawerScreens } from "./components/connectionStore";
import accountConnect from "./components/accountConnect";
import countries from "./components/CountrySelect/countrieslist";
import CorporateDocument, {
  corparateDoc,
} from "./components/CorporateDocument";

import ConfirmModalCorporateDocs from "components/ConfirmModalCorporateDocs";
import ConfirmModalDirector from "components/ConfirmModalDirector";
import AppInfo, { appInfoInst } from "./components/AppInfo";
import CheckBox from "components/CheckBox";
import ConfirmModalAppInfo from "components/ConfirmModalAppInfo";
import CompanySignatory, {
  companySignatoryInst,
} from "./components/CompanySignatory";
import ContactPerson, { contactPersonInst } from "./components/ContactPerson";
import { director, director as directorInst } from "./components/Director";
import { default as FileNameCorp } from "./components/CorporateDocument/components/FileName";
import { default as FileNameMulty } from "./components/CorporateDocument/components/FileNameMulty";

import isCompleteAllSteps from "./helper";
import UBODirectors from "./components/Director";
import { ReactComponent as Pdf } from "./components/CorporateDocument/assets/pdf.svg";
import { ReactComponent as FileIcon } from "./assets/file.svg";
import { ReactComponent as PdfIcon } from "./assets/pdf.svg";
import { ReactComponent as PngIcon } from "./assets/png.svg";
import { ReactComponent as JpegIcon } from "./assets/jpeg.svg";
import { ReactComponent as GifIcon } from "./assets/gif.svg";
import { ReactComponent as DocIcon } from "./assets/doc.svg";
import { ReactComponent as DocxIcon } from "./assets/docx.svg";
import { ReactComponent as XlsIcon } from "./assets/xls.svg";
import { ReactComponent as XlsxIcon } from "./assets/xlsx.svg";
import { ReactComponent as DjvuIcon } from "./assets/djvu.svg";

import st from "./components/CorporateDocument/styles.module.scss";
import styles from "./styles.module.scss";

const Connection = () => {
  function renderHeader() {
    const isIE = about?.values?.details?.company_form === "sole-proprietor";

    if (userStore.me.company.status === "rejected") {
      return (
        <span>
          We regret to inform you that{" "}
          <span style={{ color: "#EC3E72" }}>verification has failed</span>
        </span>
      );
    }

    return isCompleteAllSteps(isIE) ? (
      <span>
        Thanks for your efforts!
        <br /> Your data{" "}
        <span style={{ color: "#26B634" }}>
          has been successfully
          <br /> collected
        </span>
      </span>
    ) : (
      "Please provide information about your company"
    );
  }

  function renderMain() {
    const isIE = about?.values?.details?.company_form === "sole-proprietor";

    if (userStore.me.company.status === "rejected") {
      return <span>Unfortunately, we will not be able to cooperate</span>;
    }

    return isCompleteAllSteps(isIE)
      ? `Please allow us 48 hours to verify the 
    information provided. Once it has been verified, you can proceed with 
    your funding request.`
      : `To process your advance payment, we need to gather information about your company's financial health and assess your risk score. Rest assured, your data will remain confidential.`;
  }

  function renderMail() {
    const isIE = about?.values?.details?.company_form === "sole-proprietor";

    if (userStore.me.company.status === "rejected") {
      return <></>;
    }

    return !isCompleteAllSteps(isIE) ? (
      <Text1>
        Experiencing any issues? <br />
        Please contact us at{" "}
        <a href="mailto:support@gamesboost42.com">support@gamesboost42.com.</a>
      </Text1>
    ) : (
      ""
    );
  }

  return userStore.me.company.status === "rejected" ? (
    <div className={styles.containerNoDrawer}>
      <div className={styles.blockNoDrawer}>
        <Title2 className={styles.mainTitle}>{renderHeader()}</Title2>
        <Text1 className={styles.mainTitle}>{renderMain()}</Text1>
        {renderMail()}
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.block}>
        <Title2 className={styles.mainTitle}>{renderHeader()}</Title2>
        <Text1 className={styles.mainTitle}>{renderMain()}</Text1>
        {renderMail()}
      </div>
      <InfoScreen />
    </div>
  );
};

export const InfoDrawer = observer(
  ({ initialScreen }: { initialScreen?: DrawerScreens }) => {
    useEffect(() => {
      if (initialScreen) {
        connectionStore.currentScreen = initialScreen;
      }
    }, [initialScreen]);

    const country = countries.find(
      (item) => item.code === about.address.country?.code
    );
    const Country =
      (country && CountryIcon?.[country.code]) || CountryIcon.UNKNOWN;

    const getScreen = () => {
      switch (connectionStore.currentScreen) {
        case "personInfo":
          return <ContactPerson />;
        case "corporateDocs":
          return <CorporateDocument />;
        case "directors":
          return <UBODirectors />;
        case "appInfo":
          return <AppInfo />;
        case "companySignatory":
          return <CompanySignatory />;
        case "aboutCompany":
          return <AboutCompany />;
        case "infoScreen":
          //@ts-ignore
          return <InfoScreen />;
        case "revenuesList":
          return (
            <>
              <ChooseRevenue />
              {/*<SelectRevenue />*/}
              {/*<CustomRevenue />*/}
            </>
          );
        case "connectAccount":
        default:
          return <ConnectAccount />;
      }
    };

    const cutLongName = (name, length) => {
      if (name.length > length) return name.substring(0, length - 1) + "...";

      return name;
    };

    const calcTypeIcon = (type) => {
      switch (type) {
        case "image/png":
          return <PngIcon />;
        case "application/pdf":
          return <PdfIcon />;
        case "image/jpeg":
          return <JpegIcon />;
        case "image/gif":
          return <GifIcon />;
        case "application/msword":
          return <DocIcon />;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return <DocxIcon />;
        case "application/vnd.ms-excel":
          return <XlsIcon />;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return <XlsxIcon />;
        case "image/vnd.djvu":
          return <DjvuIcon />;
        default:
          return <FileIcon />;
      }
    };

    if (appStore.showAccountsDrawer) {
      const isIE = about.values.details.company_form === "sole-proprietor";

      return (
        <Drawer initialScreen={initialScreen}>
          {getScreen()}

          {connectionStore.currentScreen !== "corporateDocs" &&
            connectionStore.currentScreen !== "appInfo" &&
            connectionStore.currentScreen !== "directors" && (
              <ConfirmModal title="Please double-check your data before you submit">
                <InfoBlock
                  title="Company information"
                  content={[
                    {
                      leftContent: "Company form",
                      rightContent:
                        about.values.details.company_form === "sole-proprietor"
                          ? "Individual Entrepreneur (IE)"
                          : about.values.details.company_form,
                    },
                    {
                      leftContent: isIE ? "IE Full name" : "Legal name",
                      rightContent: about.values.details.legal_name,
                    },
                    {
                      leftContent: isIE ? "IE email" : "Corporate email",
                      rightContent: about.values.details.email,
                    },
                    {
                      leftContent: isIE ? "IE Phone number" : "Phone number",
                      rightContent: about.values.details.phone,
                    },
                    {
                      leftContent: "Web-site",
                      rightContent: about.values.details.website,
                    },
                  ]}
                />
                <InfoBlock
                  title={
                    isIE
                      ? "Individual Entrepreneur full address (registrated)"
                      : "Company full legal address"
                  }
                  content={[
                    {
                      leftContent: "Country",
                      rightContent: country ? (
                        <div className={styles.countryHolder}>
                          {Country && (
                            <Country className={styles.icon} size={16} />
                          )}
                          {country.name}
                        </div>
                      ) : undefined,
                    },
                    {
                      leftContent: "Postal/ZIP code",
                      rightContent: about.values.details.zip,
                    },
                    {
                      leftContent: "State",
                      rightContent: about.values.details.state,
                    },
                    {
                      leftContent: "City",
                      rightContent: about.values.details.city,
                    },
                    {
                      leftContent: "Street Address",
                      rightContent: about.values.details.address,
                    },
                  ]}
                />
                {isIE && (
                  <InfoBlock
                    title="Individual Entrepreneur (IE) TAX number"
                    content={[
                      {
                        leftContent: "TAX number (VAT/TIN/EIN/INN)",
                        rightContent: about.values.details.tax_number,
                      },
                    ]}
                  />
                )}
                {!isIE && (
                  <InfoBlock
                    title="Company registration and TAX number"
                    content={[
                      {
                        leftContent: "Registration number",
                        rightContent: about.values.details.registration_number,
                      },
                      {
                        leftContent: "TAX number (VAT/TIN/EIN/INN)",
                        rightContent: about.values.details.tax_number,
                      },
                    ]}
                  />
                )}

                {/* <InfoBlock
              title="Signer information"
              content={[
                {
                  leftContent: "Full name",
                  rightContent: about.signer?.full_name,
                },
                {
                  leftContent: "Position",
                  rightContent: about.signer?.position,
                },
                {
                  leftContent: "Email",
                  rightContent: about.signer?.email,
                },
              ]}
            /> */}
                {about.currency.includes("eur") && (
                  <InfoBlock
                    title="EUR bank account"
                    content={[
                      {
                        leftContent: "Bank name",
                        rightContent: about.eurBank?.bank_name,
                      },
                      {
                        leftContent: "SWIFT code",
                        rightContent: about.eurBank?.swift,
                      },
                      {
                        leftContent: "IBAN or account number",
                        rightContent: about.eurBank?.account,
                      },
                      {
                        leftContent: "Bank address",
                        rightContent: about.eurBank?.bank_address,
                      },
                    ]}
                  />
                )}

                {about.currency.includes("usd") && (
                  <InfoBlock
                    title="USD bank account"
                    content={[
                      {
                        leftContent: "Bank name",
                        rightContent: about.usdBank?.bank_name,
                      },
                      {
                        leftContent: "SWIFT code",
                        rightContent: about.usdBank?.swift,
                      },
                      {
                        leftContent: "IBAN or account number",
                        rightContent: about.usdBank?.account,
                      },
                      {
                        leftContent: "Bank address",
                        rightContent: about.usdBank?.bank_address,
                      },
                      {
                        leftContent: "Correspondent bank",
                        rightContent: about.usdBank?.correspondent_bank_name,
                      },
                      {
                        leftContent: "Correspondent SWIFT code",
                        rightContent: about.usdBank?.correspondent_swift,
                      },
                      {
                        leftContent: "Correspondent IBAN or account number",
                        rightContent: about.usdBank?.correspondent_account,
                      },
                      {
                        leftContent: "Special instructions:",
                        rightContent: about.usdBank?.special_instructions,
                      },
                    ]}
                  />
                )}
              </ConfirmModal>
            )}

          {connectionStore.currentScreen === "corporateDocs" && (
            <ConfirmModalCorporateDocs title="Please double-check your datа before you submit">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "32px",
                }}
              >
                {/* 1 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h3>
                    <b>Certificate of Incorporation or equivalent</b>
                  </h3>

                  <div className={st.confirmBlock}>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        File:
                      </div>
                      <div style={{ width: "654px" }}>
                        {corparateDoc.values?.certificateOfIncorporationFile
                          ?.name?.length! > 0 && (
                          <FileNameCorp
                            fileName="certificateOfIncorporationFile"
                            clearFiles={() => {}}
                            needDel={false}
                          />
                        )}

                        {corparateDoc.values?.certificateOfIncorporationFile
                          ?.name?.length! === 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            -
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        Comment:
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {corparateDoc.values?.certificateOfIncorporationComment
                          .length > 0
                          ? corparateDoc.values
                              ?.certificateOfIncorporationComment
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 2 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h3>
                    <b>
                      Certificate of registered address, e.g. bank statement/
                      utility bill
                    </b>
                  </h3>

                  <div className={st.confirmBlock}>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        File:
                      </div>
                      <div style={{ width: "654px" }}>
                        {corparateDoc.values?.certificateOfRegisteredAddressFile
                          ?.name?.length! > 0 && (
                          <FileNameCorp
                            fileName="certificateOfRegisteredAddressFile"
                            clearFiles={() => {}}
                            needDel={false}
                          />
                        )}
                        {corparateDoc.values?.certificateOfRegisteredAddressFile
                          ?.name?.length! === 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            -
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        Comment:
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {corparateDoc.values
                          ?.certificateOfRegisteredAddressComment.length > 0
                          ? corparateDoc.values
                              ?.certificateOfRegisteredAddressComment
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 3 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h3>
                    <b>Certificate of Directors and Secretary or equivalent</b>
                  </h3>

                  <div className={st.confirmBlock}>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        File:
                      </div>
                      <div style={{ width: "654px" }}>
                        {corparateDoc.values?.certificateOfDirectorsFile?.name
                          ?.length! > 0 && (
                          <FileNameCorp
                            fileName="certificateOfDirectorsFile"
                            clearFiles={() => {}}
                            needDel={false}
                          />
                        )}
                        {corparateDoc.values?.certificateOfDirectorsFile?.name
                          ?.length! === 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            -
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        Comment:
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {corparateDoc.values?.certificateOfDirectorsComment
                          .length > 0
                          ? corparateDoc.values?.certificateOfDirectorsComment
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 4 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h3>
                    <b>Certificate of Shareholders</b>
                  </h3>

                  <div className={st.confirmBlock}>
                    {corparateDoc.values.certificateOfShareholdersFiles.length >
                      0 &&
                      corparateDoc.values.certificateOfShareholdersFiles.map(
                        (el, i) => (
                          <div className={st.row}>
                            <div
                              style={{ width: "195px" }}
                              className={st.cellleft}
                            >
                              File:
                            </div>
                            <div style={{ width: "654px" }}>
                              <FileNameMulty
                                fileIndex={i}
                                clearFiles={() => {}}
                                needDel={false}
                              />
                            </div>
                          </div>
                        )
                      )}

                    {!corparateDoc.values.certificateOfShareholdersFiles
                      .length && (
                      <div className={st.row}>
                        <div style={{ width: "250px" }} className={st.cellleft}>
                          File:
                        </div>
                        <div className={st.uploadedforConfirm}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        Comment:
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {corparateDoc.values?.certificateOfShareholdersComment
                          .length > 0
                          ? corparateDoc.values
                              ?.certificateOfShareholdersComment
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 5 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h3>
                    <b>Certificate of Good Standing or equivalent</b>
                  </h3>

                  <div className={st.confirmBlock}>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        File:
                      </div>
                      <div style={{ width: "654px" }}>
                        {corparateDoc.values?.certificateOfGoodStandingFile
                          ?.name?.length! > 0 && (
                          <FileNameCorp
                            fileName="certificateOfGoodStandingFile"
                            clearFiles={() => {}}
                            needDel={false}
                          />
                        )}
                        {corparateDoc.values?.certificateOfGoodStandingFile
                          ?.name?.length! === 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            -
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        Comment:
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {corparateDoc.values?.certificateOfGoodStandingComment
                          .length > 0
                          ? corparateDoc.values
                              ?.certificateOfGoodStandingComment
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 6 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h3>
                    <b>Memorandum and Articles of Association</b>
                  </h3>

                  <div className={st.confirmBlock}>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        File:
                      </div>
                      <div style={{ width: "654px" }}>
                        {corparateDoc.values
                          ?.memorandumAndArticlesOfAssociationFile?.name
                          ?.length! > 0 && (
                          <FileNameCorp
                            fileName="memorandumAndArticlesOfAssociationFile"
                            clearFiles={() => {}}
                            needDel={false}
                          />
                        )}
                        {corparateDoc.values
                          ?.memorandumAndArticlesOfAssociationFile?.name
                          ?.length! === 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            -
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        Comment:
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {corparateDoc.values
                          ?.memorandumAndArticlesOfAssociationComment.length > 0
                          ? corparateDoc.values
                              ?.memorandumAndArticlesOfAssociationComment
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 7 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h3>
                    <b>
                      Latest Audited Financial Statement or Management Accounts
                    </b>
                  </h3>

                  <div className={st.confirmBlock}>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        File:
                      </div>
                      <div style={{ width: "654px" }}>
                        {corparateDoc.values
                          ?.latestAuditedFinancialStatementFile?.name?.length! >
                          0 && (
                          <FileNameCorp
                            fileName="latestAuditedFinancialStatementFile"
                            clearFiles={() => {}}
                            needDel={false}
                          />
                        )}
                        {corparateDoc.values
                          ?.latestAuditedFinancialStatementFile?.name
                          ?.length! === 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "10px",
                            }}
                          >
                            -
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div style={{ width: "195px" }} className={st.cellleft}>
                        Comment:
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {corparateDoc.values
                          ?.latestAuditedFinancialStatementComment.length > 0
                          ? corparateDoc.values
                              ?.latestAuditedFinancialStatementComment
                          : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ConfirmModalCorporateDocs>
          )}

          {connectionStore.currentScreen === "directors" && (
            <ConfirmModalDirector title="Please double-check your datа before you submit">
              {userStore.me.company.details.company_form !== "corporation" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // gap: "32px",
                  }}
                >
                  {/* 1 */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div className={st.confirmBlock}>
                      <div className={st.row}>
                        <div style={{ width: "250px" }} className={st.cellleft}>
                          Proof of ID:
                        </div>
                        <div className={st.uploadedforConfirm}>
                          {director?.values?.profOfId?.name?.length! > 0 && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {calcTypeIcon(
                                director?.values?.profOfId?.value?.type
                              )}
                              <span className={st.confirmLink}>
                                {cutLongName(
                                  director?.values?.profOfId?.name,
                                  50
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 2 */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div className={st.confirmBlock}>
                      <div className={st.row}>
                        <div style={{ width: "250px" }} className={st.cellleft}>
                          Proof of Address:
                        </div>
                        <div className={st.uploadedforConfirm}>
                          {director?.values?.profOfAddress?.name?.length! >
                            0 && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {calcTypeIcon(
                                director?.values?.profOfAddress?.value.type
                              )}
                              <span className={st.confirmLink}>
                                {cutLongName(
                                  director?.values?.profOfAddress?.name,
                                  50
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 3 */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div className={st.confirmBlock}>
                      <div className={st.row}>
                        <div style={{ width: "250px" }} className={st.cellleft}>
                          Proof of Funds:
                        </div>
                        <div className={st.uploadedforConfirm}>
                          {director?.values?.profOfFunds?.name?.length! > 0 && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {calcTypeIcon(
                                director?.values?.profOfFunds?.value.type
                              )}
                              <span className={st.confirmLink}>
                                {cutLongName(
                                  director?.values?.profOfFunds?.name,
                                  50
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {userStore.me.company.details.company_form === "corporation" && (
                <>
                  {director.values.persons?.map((el) => {
                    let content = [
                      {
                        leftContent: "Full name",
                        rightContent: `${el?.fullName}`,
                      },
                      {
                        leftContent: "Position",
                        rightContent: `${el?.positionInCompany}`,
                      },
                    ];

                    if (el?.profOfId?.name.length > 0) {
                      content = [
                        ...content,
                        {
                          leftContent: "Proof of ID",
                          rightContent: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {calcTypeIcon(el?.profOfId?.value.type)}
                              <span className={st.textUploaded}>
                                {cutLongName(el?.profOfId?.name, 17)}
                              </span>
                            </div>
                          ),
                        },
                      ];
                    }

                    if (el?.profOfAddress?.name.length > 0) {
                      content = [
                        ...content,
                        {
                          leftContent: "Proof of Address",
                          rightContent: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {calcTypeIcon(el?.profOfAddress?.value.type)}
                              <span className={st.textUploaded}>
                                {cutLongName(el?.profOfAddress?.name, 17)}
                              </span>
                            </div>
                          ),
                        },
                      ];
                    }

                    if (el?.resume?.name.length > 0) {
                      content = [
                        ...content,
                        {
                          leftContent: "Resume/CV",
                          rightContent: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {calcTypeIcon(el?.resume?.value.type)}
                              <span className={st.textUploaded}>
                                {cutLongName(el?.resume?.name, 17)}
                              </span>
                            </div>
                          ),
                        },
                      ];
                    }

                    if (el?.profOfFunds?.name.length > 0) {
                      content = [
                        ...content,
                        {
                          leftContent: "Proof of Funds",
                          rightContent: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {calcTypeIcon(el?.profOfFunds?.value.type)}
                              <span className={st.textUploaded}>
                                {cutLongName(el?.profOfFunds?.name, 17)}
                              </span>
                            </div>
                          ),
                        },
                      ];
                    }

                    if (el?.businessControl) {
                      content = [
                        ...content,
                        {
                          leftContent: (
                            <span>
                              More than 10% shares
                              <br /> or exercises control
                              <br /> over the business?
                            </span>
                          ),
                          rightContent: "yes",
                        },
                      ];
                    }

                    return <InfoBlock title={el?.fullName} content={content} />;
                  })}
                </>
              )}
            </ConfirmModalDirector>
          )}

          {connectionStore.currentScreen === "appInfo" && (
            <ConfirmModalAppInfo title="Please double-check your datа before you submit">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "32px",
                }}
              >
                {/* 1 */}
                <h3>
                  <b>Where is your app or game distributed now?*</b>
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <CheckBox
                    label="Apple App Store"
                    onChange={() =>
                      (appInfoInst.values.distribution.appleStore =
                        !appInfoInst.values.distribution.appleStore)
                    }
                    checked={appInfoInst.values.distribution.appleStore}
                  />
                  {appInfoInst.values.distribution.appleStore && (
                    <TextArea
                      value={appInfoInst.values.distribution.appleStoreComment}
                      label="Link to your game/app at Apple App Store*"
                      onChange={(value) => {
                        appInfoInst.values.distribution.appleStoreComment =
                          value;
                      }}
                    />
                  )}

                  <CheckBox
                    label="Google Play"
                    onChange={() =>
                      (appInfoInst.values.distribution.googleStore =
                        !appInfoInst.values.distribution.googleStore)
                    }
                    checked={appInfoInst.values.distribution.googleStore}
                  />
                  {appInfoInst.values.distribution.googleStore && (
                    <TextArea
                      value={appInfoInst.values.distribution.googleStoreComment}
                      label="Link to your game/app at Google Play*"
                      onChange={(value) => {
                        appInfoInst.values.distribution.googleStoreComment =
                          value;
                      }}
                    />
                  )}

                  <CheckBox
                    label="Steam"
                    onChange={() =>
                      (appInfoInst.values.distribution.steam =
                        !appInfoInst.values.distribution.steam)
                    }
                    checked={appInfoInst.values.distribution.steam}
                  />
                  {appInfoInst.values.distribution.steam && (
                    <TextArea
                      value={appInfoInst.values.distribution.steamComment}
                      label="Link to your game/app at Steam*"
                      onChange={(value) => {
                        appInfoInst.values.distribution.steamComment = value;
                      }}
                    />
                  )}

                  <CheckBox
                    label="Other"
                    onChange={() =>
                      (appInfoInst.values.distribution.other =
                        !appInfoInst.values.distribution.other)
                    }
                    checked={appInfoInst.values.distribution.other}
                  />
                  {appInfoInst.values.distribution.other && (
                    <TextArea
                      value={appInfoInst.values.distribution.otherComment}
                      label="Enter your distribution platform"
                      onChange={(value) => {
                        appInfoInst.values.distribution.otherComment = value;
                      }}
                    />
                  )}
                </div>

                {/* 2 */}
                <h3>
                  <b>Monetization model of your app/game?*</b>
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <CheckBox
                    checked={
                      appInfoInst.values.monetization === "In-app purchase"
                    }
                    type="round"
                    label="In-app purchase"
                    onChange={() => {
                      appInfoInst.values.monetization = "In-app purchase";
                    }}
                  />
                  <CheckBox
                    checked={appInfoInst.values.monetization === "Ads"}
                    type="round"
                    label="Ads"
                    onChange={() => {
                      appInfoInst.values.monetization = "Ads";
                    }}
                  />
                  <CheckBox
                    checked={
                      appInfoInst.values.monetization ===
                      "In-app purchase & Ads"
                    }
                    type="round"
                    label="In-app purchase & Ads"
                    onChange={() => {
                      appInfoInst.values.monetization = "In-app purchase & Ads";
                    }}
                  />
                  <CheckBox
                    checked={appInfoInst.values.monetization === "Premium"}
                    type="round"
                    label="Premium"
                    onChange={() => {
                      appInfoInst.values.monetization = "Premium";
                    }}
                  />
                  <CheckBox
                    checked={appInfoInst.values.monetization === "Subscription"}
                    type="round"
                    label="Subscription"
                    onChange={() => {
                      appInfoInst.values.monetization = "Subscription";
                    }}
                  />
                  <CheckBox
                    checked={appInfoInst.values.monetization === "I don’t know"}
                    type="round"
                    label="I don’t know"
                    onChange={() => {
                      appInfoInst.values.monetization = "I don’t know";
                    }}
                  />
                  <CheckBox
                    checked={appInfoInst.values.monetization === "Other"}
                    type="round"
                    label="Other"
                    onChange={() => {
                      appInfoInst.values.monetization = "Other";
                    }}
                  />
                </div>

                {/* 3 */}
                <h3>
                  <b>UA spends sources*</b>
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.facebook}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.facebook =
                        !appInfoInst.values.uaSpend.facebook)
                    }
                    label="Facebook"
                  />
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.googleAds}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.googleAds =
                        !appInfoInst.values.uaSpend.googleAds)
                    }
                    label="Google Ads"
                  />
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.appleSearchAds}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.appleSearchAds =
                        !appInfoInst.values.uaSpend.appleSearchAds)
                    }
                    label="Apple Search Ads"
                  />
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.snapchat}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.snapchat =
                        !appInfoInst.values.uaSpend.snapchat)
                    }
                    label="Snapchat"
                  />
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.tikTok}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.tikTok =
                        !appInfoInst.values.uaSpend.tikTok)
                    }
                    label="Tik Tok"
                  />
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.unityAds}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.unityAds =
                        !appInfoInst.values.uaSpend.unityAds)
                    }
                    label="UnityAds"
                  />
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.dontKnow}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.dontKnow =
                        !appInfoInst.values.uaSpend.dontKnow)
                    }
                    label="I don’t know"
                  />
                  <CheckBox
                    checked={appInfoInst.values.uaSpend.other}
                    onChange={() =>
                      (appInfoInst.values.uaSpend.other =
                        !appInfoInst.values.uaSpend.other)
                    }
                    label="Other"
                  />
                </div>

                {/* 4 */}
                <h3>
                  <b>Your current MMP (tracking service)*</b>
                </h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <CheckBox
                    label="Adjust"
                    onChange={() =>
                      (appInfoInst.values.mmp.adjust =
                        !appInfoInst.values.mmp.adjust)
                    }
                    checked={appInfoInst.values.mmp.adjust}
                  />
                  <CheckBox
                    onChange={() =>
                      (appInfoInst.values.mmp.appsflyer =
                        !appInfoInst.values.mmp.appsflyer)
                    }
                    checked={appInfoInst.values.mmp.appsflyer}
                    label="Appsflyer"
                  />
                  <CheckBox
                    onChange={() =>
                      (appInfoInst.values.mmp.tenjin =
                        !appInfoInst.values.mmp.tenjin)
                    }
                    checked={appInfoInst.values.mmp.tenjin}
                    label="Tenjin"
                  />
                  <CheckBox
                    onChange={() =>
                      (appInfoInst.values.mmp.kochava =
                        !appInfoInst.values.mmp.kochava)
                    }
                    checked={appInfoInst.values.mmp.kochava}
                    label="Kochava"
                  />
                  <CheckBox
                    onChange={() =>
                      (appInfoInst.values.mmp.branch =
                        !appInfoInst.values.mmp.branch)
                    }
                    checked={appInfoInst.values.mmp.branch}
                    label="Branch"
                  />
                  <CheckBox
                    onChange={() =>
                      (appInfoInst.values.mmp.firebase =
                        !appInfoInst.values.mmp.firebase)
                    }
                    checked={appInfoInst.values.mmp.firebase}
                    label="Firebase"
                  />
                  <CheckBox
                    onChange={() =>
                      (appInfoInst.values.mmp.dontKnow =
                        !appInfoInst.values.mmp.dontKnow)
                    }
                    checked={appInfoInst.values.mmp.dontKnow}
                    label="I don’t know"
                  />
                  <CheckBox
                    onChange={() =>
                      (appInfoInst.values.mmp.other =
                        !appInfoInst.values.mmp.other)
                    }
                    checked={appInfoInst.values.mmp.other}
                    label="Other"
                  />
                </div>

                {/* 5 */}
                <h3>
                  <b>Additional info about the project</b>
                </h3>
                <Input.TextArea
                  label="Comment"
                  disabled={false}
                  showCount={true}
                  maxLength={400}
                  value={appInfoInst.values.additionalInfo}
                  onChange={(e) => {
                    appInfoInst.values.additionalInfo = e.target.value;
                  }}
                />
              </div>
            </ConfirmModalAppInfo>
          )}
        </Drawer>
      );
    }

    return null;
  }
);

//@ts-ignore
const InfoScreen = observer(() => {
  const [sourcesLoaded, setLoaded] = useState(false);
  const isCompanyReviewing = userStore.me?.company?.status === "in-review";
  const isIE = about?.values?.details?.company_form === "sole-proprietor";

  useEffect(() => {
    (async () => {
      await connectSources.getSources();
      await connectSources.getTotalAccountsWithCredentials();
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (
      userStore.me?.company.status !== Status.new &&
      userStore.me?.company.status !== Status.rejected
    ) {
      appStore.showAccountsDrawer = false;
    }
  }, [userStore.me?.company.status]);

  return (
    <div className={styles.block}>
      {/* <div className={styles.whiteBlock}>
        <Title3
          className={
            connectSources.accountsWithCredentials.length
              ? `${styles.whiteBlockTitleAdded}`
              : `${styles.whiteBlockTitle}`
          }
        >
          Connect your revenue
        </Title3>
        <Text2>
          This allows us to automatically identify your recurring revenue and
          sync it with Gamesboost42.
        </Text2>
        <div className={styles.bottom}>
          <Button
            type="submit"
            className={styles.submitBtn}
            onClick={() => {
              connectionStore.currentScreen = "revenuesList";
              appStore.showAccountsDrawer = true;
            }}
          >
            {connectSources.accounts.length ? "Connect revenue" : "Add source"}
          </Button>
          {sourcesLoaded && (
            <Text2>
              {connectSources.accountsWithCredentials.length}/
              {connectSources.sourcesListSelf.length} added
            </Text2>
          )}
        </div>
      </div> */}

      <div className={styles.whiteBlock}>
        {/* no app info data */}
        {!appInfoInst.isDataSended && (
          <>
            <Title3 className={styles.whiteBlockTitle}>App/Game Info</Title3>
            <Text2>
              Please provide information about your applications or/and games.
            </Text2>
            <div className={styles.bottom} style={{ marginTop: "36px" }}>
              <Button
                violet
                type="primary"
                htmlType="submit"
                onClick={() => {
                  connectionStore.currentScreen = "appInfo";
                  appStore.showAccountsDrawer = true;
                }}
                className={styles.submitBtn}
                // disabled={
                //   !about.isPersonalDataValid || !about.isCompanyInfoValid
                // }
              >
                Add information
              </Button>
            </div>
          </>
        )}
        {/* app info data */}
        {appInfoInst.isDataSended && (
          <>
            <Title3 className={styles.whiteBlockTitleAdded}>
              <span>App/Game info</span>
              {!isCompleteAllSteps(isIE) && (
                <Button
                  type="iconBtn"
                  onClick={() => {
                    connectionStore.currentScreen = "appInfo";
                    appStore.showAccountsDrawer = true;
                  }}
                  icon={<EditIcon />}
                />
              )}
            </Title3>
          </>
        )}
      </div>

      <div className={styles.whiteBlock}>
        {/* no  Basic company info*/}
        {!about.isDataSended && (
          <>
            <Title3 className={styles.whiteBlockTitle}>
              Basic company info
            </Title3>
            <Text2>
              We will review the data and then send you the contracts for
              signature.
            </Text2>
            <div className={styles.bottom} style={{ marginTop: "36px" }}>
              <Button
                violet
                type="primary"
                htmlType="submit"
                className={styles.submitBtn}
                onClick={() => {
                  connectionStore.currentScreen = "aboutCompany";
                  appStore.showAccountsDrawer = true;
                }}
              >
                Add information
              </Button>
            </div>
          </>
        )}
        {/* Basic company info data */}
        {about.isDataSended && (
          <Title3 className={styles.whiteBlockTitleAdded}>
            <span>Basic company info</span>
            {!isCompleteAllSteps(isIE) && (
              <Button
                type="iconBtn"
                onClick={() => {
                  connectionStore.currentScreen = "aboutCompany";
                  appStore.showAccountsDrawer = true;
                }}
                icon={<EditIcon />}
              />
            )}
          </Title3>
        )}
      </div>

      {/* Company Signatory */}
      <div className={styles.whiteBlock}>
        {!companySignatoryInst.isDataSended ? (
          <>
            <Title3 className={styles.whiteBlockTitle}>
              Company Signatory
            </Title3>
            <Text2>
              Please provide the details of the person who is entitled to sign,
              execute and approve business contracts on behalf of your company.
            </Text2>
            <div className={styles.bottom} style={{ marginTop: "36px" }}>
              <Button
                violet
                type="primary"
                htmlType="submit"
                onClick={() => {
                  connectionStore.currentScreen = "companySignatory";
                  appStore.showAccountsDrawer = true;
                }}
                className={styles.submitBtn}
              >
                Add information
              </Button>
            </div>
          </>
        ) : (
          <Title3 className={styles.whiteBlockTitleAdded}>
            <span>Company Signatory</span>
            {!isCompleteAllSteps(isIE) && (
              <Button
                type="iconBtn"
                onClick={() => {
                  connectionStore.currentScreen = "companySignatory";
                  appStore.showAccountsDrawer = true;
                }}
                icon={<EditIcon />}
              />
            )}
          </Title3>
        )}
      </div>

      <div className={styles.whiteBlock}>
        {/* no Contact Person info*/}
        {!contactPersonInst.isDataSended && (
          <>
            <Title3 className={styles.whiteBlockTitle}>Contact Person</Title3>
            <Text2>Please provide the details of your contact person</Text2>
            <div className={styles.bottom} style={{ marginTop: "36px" }}>
              <Button
                violet
                type="primary"
                htmlType="submit"
                onClick={() => {
                  connectionStore.currentScreen = "personInfo";
                  appStore.showAccountsDrawer = true;
                }}
                className={styles.submitBtn}
              >
                Add information
              </Button>
            </div>
          </>
        )}
        {/* Contact Person info data*/}
        {contactPersonInst.isDataSended && (
          <Title3 className={styles.whiteBlockTitleAdded}>
            <span>Contact Person</span>
            {!isCompleteAllSteps(isIE) && (
              <Button
                type="iconBtn"
                onClick={() => {
                  connectionStore.currentScreen = "personInfo";
                  appStore.showAccountsDrawer = true;
                }}
                icon={<EditIcon />}
              />
            )}
          </Title3>
        )}
      </div>

      {["partnership", "corporation"].includes(
        about.values.details.company_form ?? ""
      ) && (
        <div className={styles.whiteBlock}>
          {/* noCorp docs data */}
          {!corparateDoc.isDataSended && (
            <>
              <Title3 className={styles.whiteBlockTitle}>
                Set of corporate documents
              </Title3>
              {(!about.isPersonalDataValid || !about.isCompanyInfoValid) && (
                <Text2>
                  This section will be available after completing the sections
                  above.
                </Text2>
              )}
              {about.isPersonalDataValid && about.isCompanyInfoValid && (
                <Text2>
                  Anti-money laundering and terrorist financing regulations
                  require GamesBoost42 LTD to fulfill certain Know Your Customer
                  identification requirements. The information provided here to
                  Gamesboost42 LTD and its affiliates shall only be used to
                  satisfy those requirements.
                </Text2>
              )}
              <div className={styles.bottom} style={{ marginTop: "36px" }}>
                <Button
                  violet
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    connectionStore.currentScreen = "corporateDocs";
                    appStore.showAccountsDrawer = true;
                  }}
                  className={styles.submitBtn}
                  disabled={
                    !about.isPersonalDataValid ||
                    !about.isCompanyInfoValid ||
                    !appInfoInst.isAppInfoValid
                  }
                >
                  Upload documents
                </Button>
              </div>
            </>
          )}
          {/* Corp docs data */}
          {corparateDoc.isDataSended && (
            <>
              <Title3 className={styles.whiteBlockTitleAdded}>
                <span>Set of corporate documents</span>
                {!isCompleteAllSteps(isIE) && (
                  <Button
                    type="iconBtn"
                    onClick={() => {
                      connectionStore.currentScreen = "corporateDocs";
                      appStore.showAccountsDrawer = true;
                    }}
                    icon={<EditIcon />}
                  />
                )}
              </Title3>
            </>
          )}
        </div>
      )}

      <div className={styles.whiteBlock}>
        {/* no UBOs&Directors info*/}
        {!directorInst.isDataSended && (
          <>
            <Title3 className={styles.whiteBlockTitle}>UBOs&Directors</Title3>
            <Text2>
              Anti-money laundering and terrorist financing regulations require
              GamesBoost42 LTD to fulfill certain Know Your Customer
              identification requirements. The information provided here to
              Gamesboost42 LTD and its affiliates shall only be used to satisfy
              those requirements.
            </Text2>
            <div className={styles.bottom} style={{ marginTop: "36px" }}>
              <Button
                violet
                type="primary"
                htmlType="submit"
                onClick={() => {
                  connectionStore.currentScreen = "directors";
                  appStore.showAccountsDrawer = true;
                }}
                className={styles.submitBtn}
                disabled={
                  !(
                    appInfoInst.isDataSended &&
                    about.isDataSended &&
                    companySignatoryInst.isDataSended &&
                    contactPersonInst.isDataSended &&
                    (isIE ? true : corparateDoc.isDataSended)
                  )
                }
              >
                Upload documents
              </Button>
            </div>
          </>
        )}
        {/* UBOs&Directors info data*/}
        {directorInst.isDataSended && (
          <Title3 className={styles.whiteBlockTitleAdded}>
            <span>UBOs&Directors</span>
            {!isCompleteAllSteps(isIE) && (
              <Button
                type="iconBtn"
                onClick={async () => {
                  connectionStore.currentScreen = "directors";
                  appStore.showAccountsDrawer = true;
                }}
                icon={<EditIcon />}
              />
            )}
          </Title3>
        )}
      </div>
    </div>
  );
});

const ChooseRevenue = observer(() => {
  useEffect(() => {
    connectSources.getAccounts();
    connectSources.getSources();
  }, [userStore?.user?.company.id]);
  return (
    <div>
      <Title2 className={styles.connectTitle}>
        Select your revenue sources
      </Title2>
      <div className={styles.revenueCards}>
        {connectSources.sourcesListSelf.map((source) => {
          const { id, name, logo, code } = source;
          const onClick = () => {
            accountConnect.account = {
              id,
              name,
              logo,
              code,
            };
            connectionStore.currentScreen = "connectAccount";
          };
          return <RevenueCard {...source} onClick={onClick} />;
        })}
      </div>
      <Text1>
        We are constantly updating the list of Revenue Source available
        <br />
        for connection. If your Revenue Source is not on the list above, we will
        <br />
        contact you.
      </Text1>
    </div>
  );
});

const SelectRevenue = observer(() => {
  return (
    <div>
      <Title2 className={`${styles.connectTitle} ${styles.blockTitle}`}>
        Select your Revenue Sources (will be connected soon)
      </Title2>
      <div className={styles.revenueCards}>
        {connectSources.sourcesList.map((source) => {
          return <RevenueListItem {...source} />;
        })}
      </div>
    </div>
  );
});

const CustomRevenue = observer(() => {
  return (
    <div>
      <Title2 className={`${styles.connectTitle} ${styles.blockTitle}`}>
        Did’t find your Revenue Source in the list?
      </Title2>
      <TextArea
        value={connectSources.customSource}
        onChange={(e) => {
          connectSources.customSource = e;
        }}
        placeholder="Information about your Revenue Sources"
      />
    </div>
  );
});

export default observer(Connection);
