import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const BlockTitle = ({ children, className }: { children: ReactNode, className?: string }) => {
  return (
    <h3 className={classNames(styles.blockTitle, className)}>{children}</h3>
  );
};

export const PageTitle = ({ children, className }: { children: ReactNode, className?: string }) => {
  return (
    <h1 className={classNames(styles.title, { className })}>{children}</h1>
  );
};
