import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
// @ts-ignore
import * as Validator from "validatorjs";
import { useHistory } from "react-router-dom";
import TextArea from "../../components/TextArea";
import CheckBox from "../../components/CheckBox";
import Opener from "../../components/Opener";
import Button from "../../components/Button";
import onboard from "../../store/Onboarding";
import userStore from "../../store/User";
import applaudStore, { Status } from "../../store/Applaud";
import { about } from "../Connection/components/AboutCompany";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "./assets/logo.svg";

export const isExist = (value: any) => !(value === null || value === undefined);

const Onboarding = () => {
  const history = useHistory();
  const {
    setDistribution,
    monetization,
    setUaSpend,
    setMmp,
    openId,
    setOpenId,
    getJSON,
  } = onboard;
  const rules = {
    distribution: {
      googleStore: "url|required_unless:distribution.googleStore",
      appleStore: "url|required_unless:distribution.appleStore",
      steam: "url|required_unless:distribution.steam",
    },
  };
  const validation = new Validator(getJSON(), rules, {
    url: "The link format is invalid",
    required_unless: "This field is required",
  });
  const isValid = validation.passes();
  return (
    <div>
      <header className={styles.header}>
        <Logo className={styles.logo} />
      </header>
      <div className={styles.container}>
        <h1 className={styles.title}>
          Tell us about{" "}
          <span className={styles.titleHighlight}>your Game/Application</span>
        </h1>
        <Opener
          status={onboard.getDistributionStatus}
          title="Where is your app or game distributed now?*"
          onOpen={() => setOpenId("distribution")}
          openId={openId}
          id="distribution"
        >
          <CheckBox
            checked={isExist(onboard.distribution.appleStore)}
            label="Apple App Store"
            onChange={() => {
              setDistribution("appleStore");
            }}
          />
          {isExist(onboard.distribution.appleStore) && (
            <div className={styles.item}>
              <TextArea
                value={onboard.distribution.appleStore}
                label="Link to your game/app at Apple App Store*"
                onChange={action((value) => {
                  onboard.distribution.appleStore = value;
                })}
                error={validation.errors.first("distribution.appleStore")}
              />
            </div>
          )}

          <CheckBox
            checked={isExist(onboard.distribution.googleStore)}
            label="Google Play"
            className={styles.item}
            onChange={() => {
              setDistribution("googleStore");
            }}
          />
          {isExist(onboard.distribution.googleStore) && (
            <TextArea
              onChange={(value) => {
                onboard.distribution.googleStore = value;
              }}
              value={onboard.distribution.googleStore}
              label="Link to your game/app at Google Play*"
              error={validation.errors.first("distribution.googleStore")}
            />
          )}

          <CheckBox
            checked={isExist(onboard.distribution.steam)}
            label="Steam"
            dataTest="steam"
            className={styles.item}
            onChange={() => {
              setDistribution("steam");
              action(() => {
                onboard.monetization = "";
                onboard.monetizationModel = "";
                onboard.uaSpend = {};
                onboard.mmp = {};
                onboard.additionalInfo = "";
              });
            }}
          />
          {isExist(onboard.distribution.steam) && (
            <TextArea
              onChange={action((value) => {
                onboard.distribution.steam = value;
              })}
              dataTest="steam-text"
              value={onboard.distribution.steam}
              label="Link to your game/app at Steam*"
              error={validation.errors.first("distribution.steam")}
            />
          )}

          <CheckBox
            checked={isExist(onboard.distribution.other)}
            label="Other"
            className={styles.item}
            onChange={() => {
              setDistribution("other");
            }}
          />
          {isExist(onboard.distribution.other) && (
            <TextArea
              value={onboard.distribution.other}
              placeholder="Enter your distribution platform"
              onChange={action((value) => {
                onboard.distribution.other = value;
              })}
            />
          )}
        </Opener>

        {onboard.isAnyDistributionApplied && (
          <div className={styles.opener}>
            <Opener
              id="monetization"
              openId={openId}
              onOpen={() => setOpenId("monetization")}
              status={onboard.monetizationStatus}
              title="Monetization model of your app/game?*"
            >
              <CheckBox
                checked={monetization === "In-app purchase"}
                type="round"
                label="In-app purchase"
                onChange={action(() => {
                  onboard.monetization = "In-app purchase";
                })}
              />
              <CheckBox
                checked={monetization === "Ads"}
                type="round"
                label="Ads"
                className={styles.item}
                onChange={action(() => {
                  onboard.monetization = "Ads";
                })}
              />
              <CheckBox
                checked={monetization === "In-app purchase & Ads"}
                type="round"
                label="In-app purchase & Ads"
                className={styles.item}
                onChange={action(() => {
                  onboard.monetization = "In-app purchase & Ads";
                })}
              />
              <CheckBox
                checked={monetization === "Premium"}
                type="round"
                label="Premium"
                className={styles.item}
                onChange={action(() => {
                  onboard.monetization = "Premium";
                })}
              />
              <CheckBox
                checked={monetization === "Subscription"}
                type="round"
                label="Subscription"
                className={styles.item}
                onChange={action(() => {
                  onboard.monetization = "Subscription";
                })}
              />
              <CheckBox
                checked={monetization === "I don’t know"}
                type="round"
                label="I don’t know"
                className={styles.item}
                onChange={action(() => {
                  onboard.monetization = "I don’t know";
                })}
              />
              <CheckBox
                checked={monetization === "Other"}
                type="round"
                label="Other"
                className={styles.item}
                onChange={action(() => {
                  onboard.monetization = "Other";
                })}
              />
              {onboard.monetization === "Other" && (
                <TextArea
                  value={onboard.monetizationModel}
                  label="Monetization model"
                  onChange={(value) => {
                    onboard.monetizationModel = value;
                  }}
                />
              )}
            </Opener>
          </div>
        )}

        {onboard.isMonetizationNotPremium && onboard.isAnyDistributionApplied && (
          <>
            <div className={styles.opener}>
              <Opener
                openId={openId}
                id="uaSpend"
                onOpen={() => setOpenId("uaSpend")}
                status={onboard.uaSpendStatus}
                title="UA spends sources*"
              >
                <CheckBox
                  checked={onboard.uaSpend.facebook}
                  onChange={() => setUaSpend("facebook")}
                  label="Facebook"
                />
                <CheckBox
                  checked={onboard.uaSpend.googleAds}
                  onChange={() => setUaSpend("googleAds")}
                  label="Google Ads"
                  className={styles.item}
                />
                <CheckBox
                  checked={onboard.uaSpend.appleSearchAds}
                  onChange={() => setUaSpend("appleSearchAds")}
                  label="Apple Search Ads"
                  className={styles.item}
                />
                <CheckBox
                  checked={onboard.uaSpend.snapchat}
                  onChange={() => setUaSpend("snapchat")}
                  label="Snapchat"
                  className={styles.item}
                />
                <CheckBox
                  checked={onboard.uaSpend.tikTok}
                  onChange={() => setUaSpend("tikTok")}
                  label="Tik Tok"
                  className={styles.item}
                />
                <CheckBox
                  checked={onboard.uaSpend.unityAds}
                  onChange={() => setUaSpend("unityAds")}
                  label="UnityAds"
                  className={styles.item}
                />
                <CheckBox
                  checked={onboard.uaSpend.dontKnow}
                  onChange={() => setUaSpend("dontKnow")}
                  label="I don’t know"
                  className={styles.item}
                />
                <CheckBox
                  checked={onboard.uaSpend.other}
                  onChange={() => setUaSpend("other")}
                  label="Other"
                />
              </Opener>
            </div>

            <div className={styles.opener}>
              <Opener
                openId={openId}
                id="mmp"
                onOpen={() => setOpenId("mmp")}
                status={onboard.mmpStatus}
                title="Your current MMP (tracking service)*"
              >
                <CheckBox
                  label="Adjust"
                  onChange={() => setMmp("adjust")}
                  checked={onboard.mmp.adjust}
                />
                <CheckBox
                  onChange={() => setMmp("appsflyer")}
                  checked={onboard.mmp.appsflyer}
                  label="Appsflyer"
                  className={styles.item}
                />
                <CheckBox
                  onChange={() => setMmp("tenjin")}
                  checked={onboard.mmp.tenjin}
                  label="Tenjin"
                  className={styles.item}
                />
                <CheckBox
                  onChange={() => setMmp("kochava")}
                  checked={onboard.mmp.kochava}
                  label="Kochava"
                  className={styles.item}
                />
                <CheckBox
                  onChange={() => setMmp("branch")}
                  checked={onboard.mmp.branch}
                  label="Branch"
                  className={styles.item}
                />
                <CheckBox
                  onChange={() => setMmp("firebase")}
                  checked={onboard.mmp.firebase}
                  label="Firebase"
                  className={styles.item}
                />
                <CheckBox
                  onChange={() => setMmp("dontKnow")}
                  checked={onboard.mmp.dontKnow}
                  label="I don’t know"
                  className={styles.item}
                />
                <CheckBox
                  onChange={() => setMmp("other")}
                  checked={onboard.mmp.other}
                  label="Other"
                />
              </Opener>
            </div>

            <div className={styles.opener}>
              <Opener
                openId={openId}
                id="info"
                onOpen={() => setOpenId("info")}
                status="notFilled"
                title="Additional info about the project"
              >
                <TextArea
                  value={onboard.additionalInfo}
                  max={400}
                  onChange={action((value) => {
                    onboard.additionalInfo = value;
                  })}
                  textareaClassName={styles.counterText}
                />
              </Opener>
            </div>
          </>
        )}

        <div className={styles.btnHolder}>
          <Button
            className={styles.btn}
            type="submit"
            withArrow
            disabled={!onboard.isValid || !isValid}
            onClick={async () => {
              await userStore.getMe();
              await onboard.sendData(
                userStore.me?.company?.id.toString() || ""
              );
              if (
                // comment this check for fix bug GB42-2905
                // applaudStore.isOnlyClassicAvailable &&
                userStore.me?.status !== Status.new &&
                userStore.me?.status !== Status.rejected
              ) {
                history.push("/transactions");
              }
            }}
          >
            Continue
          </Button>
        </div>
        {/*{*/}
        {/*  notification &&*/}
        {/*  <div className={styles.notification}>*/}
        {/*    E-mail was verified successful!*/}
        {/*    <button className={styles.closeBtn} onClick={() => showNotification(false)}><Close /></button>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    </div>
  );
};

export default observer(Onboarding);
