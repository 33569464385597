import { observer } from "mobx-react";
// @ts-ignore
import * as Validator from "validatorjs";
import React, { useEffect, useMemo } from "react";
import { toJS } from "mobx";
import { Form } from "antd";
import { Title3 } from "../Typography";
import CheckBox from "../../../../components/CheckBox";
import { about } from "./index";
import styles from "./styles.module.scss";

// about.values.bank_details.push({
//   currency: 'USD',
//   bank_name: '',
//   account: '',
//   swift: '',
//   master: !about.values.bank_details.find(item => item.currency === 'EUR'),
// });

const Currency = observer(() => {
  useEffect(() => {
    about.isStepIsValid[4] = !!about.currency.length;
  }, [about.currency]);

  return (
    <Form>
      <Title3 className={styles.subTitle}>Your account currency*</Title3>
      <div className={styles.form}>
        <CheckBox
          onChange={() => {
            if (about.currency.includes("eur")) {
              about.values.bank_details = about.values.bank_details.filter(
                (item) => item.currency !== "EUR"
              );
              return;
            }
            about.values.bank_details.push({
              currency: "EUR",
              bank_name: "",
              account: "",
              swift: "",
              master: true,
            });
          }}
          checked={about.currency.includes("eur")}
          className={styles.labelCheckbox}
        >
          EUR
        </CheckBox>
        <CheckBox
          onChange={() => {
            if (about.currency.includes("usd")) {
              about.values.bank_details = about.values.bank_details.filter(
                (item) => item.currency !== "USD"
              );
              return;
            }
            about.values.bank_details.push({
              currency: "USD",
              bank_name: "",
              account: "",
              swift: "",
              master: !about.values.bank_details.find(
                (item) => item.currency === "EUR"
              ),
            });
          }}
          checked={about.currency.includes("usd")}
        >
          USD
        </CheckBox>
      </div>
    </Form>
  );
});

export default Currency;
