import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import './calendar.css';
// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import useOnClickOutside from 'components/helpers/useOnClickOutside';
import styles from './styles.module.scss';
import { ReactComponent as Icon } from './assests/calendar.svg';

const DatePickerWrapper = ({ onChange, startDay, endDay }:{ onChange: (start: Date|null, end: Date|null) => void, startDay?: Date|null, endDay?: Date|null }) => {
  const [state, setState] = useState([
    {
      startDate: endDay || null,
      endDate: startDay || null,
      key: 'selection',
    },
  ]);
  useEffect(() => {
    if (state[0].startDate) {
      onChange(state[0].startDate, state[0].endDate);
    }
  }, [state[0].endDate, state[0].startDate]);
  const datesRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [visible, setVisible] = useState(false);

  useOnClickOutside(datesRef, () => setVisible(false));

  return (
    <div className={styles.holder} ref={datesRef}>
      <div className={styles.wrapper} onClick={() => setVisible(!visible)}>
        <div className={styles.controlsHolder}>
          <span className={styles.label}>Select period</span>
          {
            state[0].startDate && state[0].endDate &&
            <div className={styles.input}>{format(state[0].startDate, 'dd.MM.yyyy')} - {format(state[0].endDate, 'dd.MM.yyyy')}</div>
          }
        </div>
        <button className={styles.icon}><Icon /></button>
      </div>
      {
        visible &&
          <div className={styles.dates}>
            <DateRangePicker
              onChange={(item:any) => {
                setState([item.selection]);
              }}
              showSelectionPreview
              inputRanges={[]}
              rangeColors={['#7C55EB']}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />
          </div>
      }
    </div>
  );
};

export default DatePickerWrapper;
