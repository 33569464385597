import { useEffect } from "react";
import { Input, Radio } from "@gamesb42/ui-kit";

import { corparateDoc } from "./index";
import { observer } from "mobx-react";
import TextArea from "../TextArea";
import UploadFile from "components/UploadFile";

import { ReactComponent as Pdf } from "./assets/pdf.svg";
import { ReactComponent as Close } from "./assets/close.svg";

import s from "./styles.module.scss";
import FileName from "./components/FileName";

const Step3 = observer(() => {
  useEffect(() => {
    const conditionForUpload =
      corparateDoc.values.step3Variant === "upload" &&
      corparateDoc.values.certificateOfDirectorsFile!.name.length > 0;
    const conditionForNotApplicable =
      corparateDoc.values.step3Variant === "notApplicable" &&
      corparateDoc.values.certificateOfDirectorsComment.length > 0;

    if (conditionForUpload || conditionForNotApplicable) {
      corparateDoc.isStepIsValid[3] = true;
    } else {
      corparateDoc.isStepIsValid[3] = false;
    }
  }, [
    corparateDoc.values.step3Variant,
    corparateDoc.values.certificateOfDirectorsComment,
    corparateDoc.values.certificateOfDirectorsFile!.name,
  ]);

  function delFileHandler() {
    if (corparateDoc.values.certificateOfDirectorsFile?.docId?.length > 0) {
      corparateDoc.values.needtoDelete.push(
        corparateDoc.values.certificateOfDirectorsFile.docId
      );
    }

    corparateDoc.values.certificateOfDirectorsFile = {
      id: "",
      docId: "",
      name: "",
      value: "",
      size: 0,
    };

    corparateDoc.values.certificateOfDirectorsComment = "";
  }

  return (
    <>
      <p>Certificate of Directors and Secretary or equivalent*</p>
      <Radio.Group value={corparateDoc.values.step3Variant}>
        <Radio
          value="upload"
          onChange={() => {
            delFileHandler();
            corparateDoc.values.step3Variant = "upload";
          }}
        >
          Upload
        </Radio>
        <Radio
          value="notApplicable"
          onChange={() => {
            delFileHandler();
            corparateDoc.values.step3Variant = "notApplicable";
          }}
        >
          Not applicable
        </Radio>
      </Radio.Group>

      {corparateDoc.values.step3Variant === "upload" && (
        <div
          style={{
            marginTop: "32px",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <div className={s.uploadHolder}>
            {(corparateDoc.values.certificateOfDirectorsFile!.size === 0 && (
              <UploadFile
                dragText="Drag and drop file*"
                uploadText={`50 Mb max. weight`}
                onUpload={(e, file) => {
                  corparateDoc.values.certificateOfDirectorsFile!.name =
                    file.name;
                  corparateDoc.values.certificateOfDirectorsFile!.size =
                    file.size;
                  corparateDoc.values.certificateOfDirectorsFile!.value = file;
                }}
              />
            )) || (
              <FileName
                fileName="certificateOfDirectorsFile"
                clearFiles={delFileHandler}
              />
            )}
          </div>
          <Input.TextArea
            style={{ height: "100px" }}
            label="Comment to the uploaded file"
            disabled={
              corparateDoc.values.certificateOfDirectorsFile?.docId?.length > 0
            }
            showCount={true}
            maxLength={200}
            value={corparateDoc.values.certificateOfDirectorsComment}
            onChange={(e) => {
              corparateDoc.values.certificateOfDirectorsComment =
                e.target.value;
            }}
          />
        </div>
      )}

      {corparateDoc.values.step3Variant === "notApplicable" && (
        <div style={{ padding: "32px 0 0 0" }}>
          <Input.TextArea
            style={{ height: "100px" }}
            label="Not applicable? Please explain why*"
            disabled={false}
            showCount={true}
            maxLength={200}
            value={corparateDoc.values.certificateOfDirectorsComment}
            onChange={(e) => {
              corparateDoc.values.certificateOfDirectorsComment =
                e.target.value;
            }}
            placeholder="Not applicable? Please explain why*"
          />
        </div>
      )}
    </>
  );
});

export default Step3;
