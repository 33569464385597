import { makeAutoObservable, reaction } from 'mobx';
import applaudStore, { AvailableProduct } from './Applaud';
import User from './User';

export type Claim = 'NOW'|'FRE';

class Dashboard {
  constructor() {
    makeAutoObservable(this);
  }

  revenueCurrency:AvailableProduct|null = User.getDefaultCurrency

  fundingCurrency:AvailableProduct|null = User.getDefaultCurrency;

  nowOrFuture:Claim|null = null;
}

const dashboardStore = new Dashboard();

reaction(() => dashboardStore.fundingCurrency,
  () => {
    if (dashboardStore.nowOrFuture === 'FRE') {
      applaudStore.getFutureReceivables();
    } else if (dashboardStore.nowOrFuture === 'NOW') {
      applaudStore.getClaims();
    }
  },
);

export default dashboardStore;
