import React from 'react';
import { Currency } from 'types';
import { CURRENCY_CODE_EUR, CURRENCY_CODE_USD } from 'const';

type FormatNumber = {
  z: number,
  n: number,
  x: number,
  s: string|number,
  c: string|number,
}
/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param number number: число
 * @param integer decimalLength: длина доли
 * @param integer partLength: длина каждой части
 * @param mixed   sectionDelimiter: разделитель секций
 * @param mixed   decimalDelimiter: разделитель долей
 */
// eslint-disable-next-line max-len

export const commonIntl = new Intl.NumberFormat(window.navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export function formatNumber(number:number, decimalLength?:number, partLength?:number, sectionDelimiter?:number|string, decimalDelimiter?:number|string) {
  // @ts-ignore
  const re = `\\d(?=(\\d{${partLength || 3}})+${decimalLength > 0 ? '\\D' : '$'})`;
  // @ts-ignore
  // eslint-disable-next-line no-bitwise
  const num = number.toFixed(Math.max(0, ~~decimalLength));
  return (decimalDelimiter ? num.replace('.', decimalDelimiter as string) : num).replace(new RegExp(re, 'g'), `$&${sectionDelimiter || ','}`);
}

export function formatCurrency(amount: number | string, currency: Currency | 'FRE' | undefined) {
  if (currency === 'FRE' || !currency) return amount;
  const res = commonIntl.format(Math.abs(+amount));
  const minus = Number(amount) < 0 ? '—' : '';
  return `${minus} ${getCurrencySymbol(currency)}${res}`;
}

export function stringToNumber(value: string):number {
  const res = value.replace(',', '').replace('.', '');
  return +res;
}

export function prettyDate(dateString: string|null) {
  if (!dateString) return '';
  try {
    const fullDate = new Date(dateString);
    const date = fullDate.getDate().toString().length === 1 ? `0${fullDate.getDate()}` : fullDate.getDate();
    const actualMonth = fullDate.getMonth() + 1;
    const month = actualMonth.toString().length === 1 ? `0${actualMonth}` : actualMonth;
    return `${date}.${month}.${fullDate.getFullYear()}`;
  } catch (e) {
    console.log(e);
    return '-';
  }
}

export function getPrettyDateForChart(date: string) {
  const dateObj = new Date(date);
  let dateName = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
  dateName = dateName.charAt(0).toUpperCase() + dateName.slice(1);
  const dateArray = date.split('-');
  return `${dateArray[2]}.${dateArray[1]} ${dateName}`;
}

export function getPrettyDateForLabel(date: string) {
  const dateArray = date.split('-');
  return `${dateArray[2]}.${dateArray[1]}`;
}

export function dateForRequest(fullDate: any):string|null {
  if (!fullDate) return null;
  try {
    const date = fullDate.getDate().toString().length === 1 ? `0${fullDate.getDate()}` : fullDate.getDate();
    const actualMonth = fullDate.getMonth() + 1;
    const month = actualMonth.toString().length === 1 ? `0${actualMonth}` : actualMonth;
    return `${fullDate.getFullYear()}-${month}-${date}`;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function getCurrencySymbol(value:string|undefined|null) {
  if (value?.toLowerCase() === 'eur') return '€';
  if (value?.toLowerCase() === 'usd') return '$';
  return '';
}

export function downloadBase64File(contentType: string, base64Data: string, fileName: string) {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = 'report.pdf';
  downloadLink.click();
}

export const downloadFileOfBlob = (blob: Blob, type: string, name: string, openFile?: boolean) => {
  const filelocal = new Blob([blob], { type });
  const blobUrl = URL.createObjectURL(filelocal);
  const link = document.createElement('a');
  link.href = blobUrl;
  if (!openFile) {
    link.download = name;
  }

  if (openFile) {
    link.target = '_blank';
  }
  link.click();
  link.remove();
  URL.revokeObjectURL(link.href);
};

export function numFormatter(num:number) {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(0)}k`;
  } if (num > 1000000) {
    return `${(num / 1000000).toFixed(0)}M`;
  }
  return num;
}

export function codeToCurrency(code: string) {
  if (+code === 978) return 'EUR';
  if (+code === 840) return 'USD';
  return 'EUR';
}

export function currencyToCode(currency: Currency) {
  if (currency === 'EUR') return CURRENCY_CODE_EUR;
  return CURRENCY_CODE_USD;
}

export function getServiceUrlPath() {
  if (window.location.origin.split('.')[1] === 'stage') {
    return 'https://stage.api.gamesboost42.com';
  }

  if (window.location.origin.split('.')[1] === 'app') {
    return 'https://api.gamesboost42.com';
  }

  return 'https://dev.api.gamesboost42.com';
}

export const prettyContent = (content: string|number|React.ReactNode) => {
  if (typeof content === 'number' && isNaN(content) || !content) return '—';
  return content;
};
