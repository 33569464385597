//@ts-nocheck
import { observer } from "mobx-react";

import s from "./styles.module.scss";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { makeAutoObservable } from "mobx";
import { about } from "../AboutCompany";

class ContactPersonBC {
  constructor() {
    makeAutoObservable(this);
  }

  page = 1;

  values = { step1Variant: "" };

  isStepIsValid = {
    1: false,
    2: false,
  };

  isDataSended = false;
  contactId = "";

  get isContactPersonValid() {
    return this.isStepIsValid[2];
  }

  get isLastStep() {
    if (contactPersonInst.isDataSended) return this.page === 1;
    return this.page === 2;
  }

  get hasContact() {
    return about.values.contacts.find((item) => item.type === "contact");
  }

  //@ts-ignore
  init(contact) {
    // console.log({ contact });

    if (contact && contact?.full_name) {
      this.isDataSended = true;
      // this.values.fullName = contact.full_name;
      // this.values.position = contact.position;
      // this.values.email = contact.email;
      // this.values.contact_phone = contact.contact_phone;

      this.contactId = contact.id;
    }
  }
}

export const contactPersonInst = new ContactPersonBC();

const ContactPerson = observer(() => {
  const getPage = (page: number) => {
    const currentContact = about.values.contacts.find(
      (item) => item.type === "contact"
    );

    if (currentContact) {
      return <Step2 />;
    }

    // if (contactPersonInst.isDataSended) {
    //   return <Step2 />;
    // }

    switch (page) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      default:
        return <div />;
    }
  };

  return (
    <div>
      <div className={s.container}>{getPage(contactPersonInst.page)}</div>
    </div>
  );
});

export default ContactPerson;
