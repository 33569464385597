import React, { useRef, useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { observer } from 'mobx-react';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Filler, Tooltip } from 'chart.js';
import applaudStore from 'store/Applaud';
import { getCurrencySymbol, getPrettyDateForChart, getPrettyDateForLabel, numFormatter } from '../../../utils/helpers';
import { chartStore } from '../RevenueChart';
import dashboardStore from '../../../store/Dashboard';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Filler, Tooltip);

//@ts-ignore
Tooltip.positioners.custom = (elements, eventPosition) => {
  return {
    x: eventPosition.x,
    y: eventPosition.y,
  };
};
const title = (tooltipItems:any) => {
  const date = chartStore.revenueData[tooltipItems[0].dataIndex]?.date;

  return date ? `${getPrettyDateForChart(chartStore.revenueData[tooltipItems[0].dataIndex]?.date)}` : '';
};

const label = (tooltipItem:any) => {
  return `${getCurrencySymbol(dashboardStore.revenueCurrency?.value.toLowerCase())}${tooltipItem.formattedValue}`;
};

const Chart = ({ onAfterDraw }: { onAfterDraw: () => void }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      // @ts-ignore
      const ctx = chart.canvas.getContext('2d');
      const gradientFill = ctx.createLinearGradient(0, 0, 0, 300);
      gradientFill.addColorStop(0, 'rgba(124, 85, 235, 0.21)');
      gradientFill.addColorStop(1, 'rgba(124, 85, 235, 0)');

      setChartData({
        datasets: [{
          label: 'Data',
          borderColor: '#7C55EB',
          pointBorderWidth: 0,
          pointHoverRadius: 7,
          pointBackgroundColor: 'rgb(136 140 151 / 0%)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHoverBackgroundColor: 'rgb(255 255 255)',
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          data: chartStore.revenueData.map(item => {
            return item.sum;
          }),
        }],
        labels: chartStore.revenueData.map((item, idx) => {
          return chartStore.revenueData[idx].prettyLabel;
        }),
      });
    }
  }, [chartStore.revenueData]);

  const options = {
    maintainAspectRatio: false,
    onHover: (e: any, chartElement: any) => {
      const target = e.native ? e.native.target : e.target;
      target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    title: {
      fontColor: '#70717C',
    },
    interaction: {
      mode: 'index',
      axis: 'x',
      intersect: false,
    },
    plugins: {
      tooltip: {
        displayColors: false,
        enabled: true,
        position: 'custom',
        usePointStyle: true,
        backgroundColor: '#ffffff',
        borderColor: '#7C55EB',
        borderWidth: 1,
        titleFontColor: '#70717C',
        titleFontSize: 30,
        borderRadius: 8,
        titleColor: '#70717C',
        titleFont: {
          color: '#70717C',
          family: 'MullerRegular',
        },
        bodyFont: {
          family: 'MullerBold',
          size: 16,
        },
        bodyColor: '#131416',
        callbacks: {
          title,
          label,
        },
      },
      legend: {
        labels: {
          font: {
            size: 30,
            fontColor: '#7C55EB',
          },
        },
      },
    },
    beginAtZero: true,
    scales: {
      y: {
        beginAtZero: true,
        grace: 100,
        min: 0,
        max: chartStore.maxChartSum,
        grid: {
          borderDash: [3, 3],
        },
        ticks: {
          callback(value:any, index:any, ticks:any) {
            return `${numFormatter(value)}`;
          },
          font: {
            size: 12,
            family: 'MullerRegular',
            color: '#888C97',
          },
        },
      },
      x: {
        grid: {
          borderDash: [3, 3],
        },
        beginAtZero: true,
        ticks: {
          font: {
            size: 12,
            family: 'MullerRegular',
            color: '#888C97',
          },
        },
      },
    },
  };

  const plugins = [{
    id: 'lineOnHover',
    afterDraw: (chart:any) => {
      if (chart.config._config.data.labels.length) {
        onAfterDraw();
      }
      if (chart.tooltip?._active?.length) {
        const x = chart.tooltip._active[0].element.x;
        const yAxis = chart.scales.y;
        const ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#888C97';
        ctx.stroke();
        ctx.restore();
      }
    },
  }];

  return (
    <div style={{ height: 226 }}>
      <Line
        data={chartData}
        ref={chartRef}
        // @ts-ignore
        options={options}
        plugins={plugins}
      />
    </div>
  );
};

export default observer(Chart);
