import React, { ReactNode } from 'react';
import { ReactComponent as ArrowLeft } from './assets/arrowLeft.svg';
import { ReactComponent as Loading } from './assets/loading.svg';
import styles from './styles.module.scss';

type BProps = {
  children: React.ReactNode|string,
  onClick?: () => void,
  withArrow?: boolean,
  disabled?: boolean,
  className?: string,
  type: 'submit'|'cancel'|'delete'|'flat',
  isLoading?: boolean,
  dataTest?: string,
}

const Button = ({ dataTest, children, onClick, withArrow, disabled, isLoading, className = '', type = 'submit' }:BProps) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.container} ${styles[type]} ${className}`}
      disabled={disabled}
      data-test={dataTest}
    >
      {children}
      {/*<Loading />*/}
      {withArrow && <ArrowLeft style={{ marginLeft: 8 }} />}
    </button>
  );
};

type BIcon = {
  disabled?: boolean,
  children: ReactNode|string,
  renderIcon?: () => ReactNode,
  onClick: () => void
}

const ButtonMedium = ({ disabled, children, renderIcon, onClick }: BIcon) => {
  const icon = renderIcon && renderIcon();
  return (
    <button
      onClick={onClick}
      className={`${styles.container} ${styles.submit} ${styles.iconBtn}`}
      disabled={disabled}
    >
      {
        icon && <span className={styles.icon}>{icon}</span>
      }
      {children}
    </button>
  );
};

export default Button;
export { ButtonMedium };
