import React, { useState } from "react";
import { observer } from "mobx-react";
import { Modal, Button, ArrowLeftIcon } from "@gamesb42/ui-kit";
import appStore from "store/App";
import { about } from "pages/Connection/components/AboutCompany";
import { prettyContent } from "utils/helpers";
import connectionStore from "../../pages/Connection/components/connectionStore";

import styles from "./styles.module.scss";
import { corparateDoc } from "pages/Connection/components/CorporateDocument";

const ConfirmModalCorporateDocs = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <div>
      <Modal
        title={title}
        okButtonProps={{}}
        open={appStore.showConfirmOnboardingModal}
        onCancel={() => {
          appStore.showConfirmOnboardingModal = false;
        }}
        className={styles.container}
        footer={
          <div className={styles.btnsHolder}>
            <Button
              size="middle"
              icon={<ArrowLeftIcon size={16} />}
              className={styles.backBtn}
              onClick={() => {
                appStore.showConfirmOnboardingModal = false;
              }}
            >
              Go back and edit
            </Button>
            <Button
              size="middle"
              violet
              type="primary"
              disabled={disabled}
              onClick={async () => {
                setDisabled(true);
                await corparateDoc.sendFiles();
                connectionStore.currentScreen = "infoScreen";
                corparateDoc.page = 1;
                appStore.showAccountsDrawer = false;
                appStore.showConfirmOnboardingModal = false;
                corparateDoc.isDataSended = true;
                setDisabled(false);
              }}
            >
              Send
            </Button>
          </div>
        }
      >
        <div className={styles.contentWrapper}>{children}</div>
      </Modal>
    </div>
  );
};

export const InfoBlock = ({
  title,
  content,
}: {
  title: string;
  content: Array<{
    leftContent: React.ReactNode | string | number;
    rightContent: React.ReactNode | string | number;
  }>;
}) => {
  return (
    <div className={styles.infoBlock}>
      <div className={styles.infoTitle}>{title}</div>
      <div className={styles.card}>
        {content.map((item) => {
          return (
            <div className={styles.cardRow}>
              <div className={styles.leftCell}>{item.leftContent}:</div>
              <div className={styles.rightCell}>
                {prettyContent(item.rightContent)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(ConfirmModalCorporateDocs);
