import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

const TextArea = ({
  value,
  dataTest,
  label = "",
  error = "",
  placeholder,
  max,
  textareaClassName = "",
  onChange,
  onBlur,
}: {
  dataTest?: string;
  value: string;
  label?: string;
  error?: string;
  placeholder?: string;
  max?: number;
  textareaClassName?: string;
  onChange?: (inp: string) => void;
  onBlur?: () => void;
}) => {
  useEffect(() => {
    if (max) {
      onChange && onChange(value.slice(0, max));
    }
  }, [max, value]);
  const [isError, showError] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <textarea
        autoFocus={true}
        placeholder={placeholder}
        value={value}
        data-test={dataTest}
        className={`${textareaClassName} ${styles.textarea} ${
          error && isError ? styles.error : ""
        } ${label ? styles.withLabel : ""} ${max ? styles.withCounter : ""}`}
        rows={1}
        onChange={(e) => onChange && onChange(e.currentTarget.value)}
        onBlur={() => {
          if (error) {
            showError(true);
          } else {
            showError(false);
          }
        }}
      />
      {max && (
        <div className={styles.counter}>
          {value?.length || 0}/{max}
        </div>
      )}
      {isError && error && <div className={styles.errorNotice}>{error}</div>}
    </div>
  );
};

export default TextArea;
