import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
// @ts-ignore
import * as Validator from "validatorjs";
import { Title3 } from "../Typography";
import TextInput from "../TextInput";
import { about } from "./index";
import styles from "./styles.module.scss";

const Registration = observer(() => {
  let rules = {};
  if (
    ["partnership", "corporation"].includes(
      about.values.details.company_form ?? ""
    )
  ) {
    rules = {
      registration_number: "required",
      tax_number: "required",
    };
  } else {
    rules = {
      tax_number: "required",
    };
  }

  const validation = useMemo(
    () =>
      new Validator(about.values.details, rules, {
        required: "This field is required",
      }),
    [about.values.details.registration_number, about.values.details.tax_number]
  );
  const isValid = validation.passes();

  useEffect(() => {
    about.isStepIsValid[3] = isValid;
  }, [isValid]);

  return (
    <>
      {["partnership", "corporation"].includes(
        about.values.details.company_form ?? ""
      ) && (
        <>
          <div style={{ marginBottom: "20px" }}>
            <Title3 className={styles.subTitle}>
              Company Registration and TAX number*
            </Title3>
          </div>
          <TextInput
            onChange={(e) => {
              about.values.details.registration_number = e;
            }}
            label="Registration number*"
            dataTest="registrationNumber"
            className={styles.textField}
            value={about.values.details.registration_number}
            error={validation.errors.first("registration_number")}
          />
          <TextInput
            onChange={(e) => {
              about.values.details.tax_number = e;
            }}
            label="TAX number (VAT/TIN/EIN/INN)*"
            dataTest="tax"
            className={styles.textField}
            value={about.values.details.tax_number}
            error={validation.errors.first("tax_number")}
          />
        </>
      )}
      {about.values.details.company_form === "sole-proprietor" && (
        <>
          <div style={{ marginBottom: "20px" }}>
            <Title3 className={styles.subTitle}>
              Individual Entrepreneur TAX number*
            </Title3>
          </div>
          <TextInput
            onChange={(e) => {
              about.values.details.tax_number = e;
            }}
            label="TAX number (VAT/TIN/EIN/INN)*"
            dataTest="tax"
            className={styles.textField}
            value={about.values.details.tax_number}
            error={validation.errors.first("tax_number")}
          />
        </>
      )}
    </>
  );
});

export default Registration;
