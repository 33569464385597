import React, { useEffect, useRef, useState } from "react";
import { format, subDays } from "date-fns";
import classNames from "classnames";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { observer } from "mobx-react";
import { debounce } from "chart.js/helpers";
import DatePicker from "components/DatePicker";
import SearchSelect, { SearchSelectItem } from "components/SearchSelect";
import SideMenu from "../../components/SideMenu";
import Main from "../../layout/Main";
import Paginator from "../../components/Paginator";
import { codeToCurrency, formatCurrency } from "../../utils/helpers";
import { authorizedRequest } from "../../utils/authorization";
import User from "../../store/User";
import Skeleton from "../../components/Skeleton";
import {
  FilterItem,
  useFilterFunction,
} from "../../components/SearchSelect/hooks";
import ColorPass from "../../components/SearchSelect/ColorPass";
import { PageTitle } from "../../components/Text";
import { InfoDrawer } from "../Connection";
import styles from "./styles.module.scss";
import { ReactComponent as Down } from "./assets/down.svg";
import {
  Button,
  DownloadIcon,
  Input,
  MoreControlsIcon,
  Select,
} from "@gamesb42/ui-kit";
import moment from "moment";

const transactionsTypes: FilterItem[] = [
  {
    label: "All types",
    value: "All types",
  },
  {
    label: "Incoming",
    value: "incoming",
    type: "type",
  },
  {
    label: "Outgoing",
    value: "outgoing",
    type: "type",
  },
  {
    label: "Fee",
    value: "fee",
    type: "type",
  },
  {
    label: "Conversion transaction",
    value: "conversion",
    type: "type",
  },
  {
    label: "Adjusting transaction",
    value: "adjustment",
    type: "type",
  },
  {
    label: "Purchase Price",
    value: "outgoing-purchase-price",
    type: "category",
  },
  {
    label: "Balance Payable",
    value: "outgoing-balance-payable",
    type: "category",
  },
  {
    label: "Transit",
    value: "outgoing-transit",
    type: "category",
  },
  {
    label: "Commission",
    value: "internal-commission",
    type: "category",
  },
  {
    label: "Principal Amount",
    value: "internal-principal-amount",
    type: "category",
  },
  {
    label: "Default Interest",
    value: "internal-default-interest",
    type: "category",
  },
  {
    label: "Capitalization",
    value: "internal-capitalization",
    type: "category",
  },
  {
    label: "Factoring Services Fee",
    value: "internal-factoring-services-fee",
    type: "category",
  },
  // {
  //   label: 'Purchase price',
  //   value: 'outgoing-purchase-price',
  // },
];

export type TransactionItem = {
  value_datetime: string;
  type?: string;
  category?: string;
  created: string;
  details: string;
  id: string;
  amount: {
    currency: string;
    amount: number;
    currency_buy: string;
    currency_sell: string;
    amount_sell: number;
    amount_buy: number;
  };
};

class TransactionsStore {
  constructor() {
    makeAutoObservable(this);
  }

  transactions: TransactionItem[] | [] = [];

  loading = true;

  currentTransaction = "All types";

  currentTransactionType = "type";

  currentTransactionCurrency = "";

  currentTransactionCounterparty = "";

  currentPage = 1;

  totalPages = 0;

  startDate = new Date();

  rows = 25;

  endDate = subDays(this.startDate, 30);

  totalItems = 0;

  get shouldDisplayPagination() {
    return !this.loading && this.totalPages > 1;
  }

  get shouldDisplayDown() {
    return !localStore.loading && this.transactions.length > 12;
  }
}

const localStore = new TransactionsStore();

const ROWS = 25;

const getTransactionWithParams = async () => {
  if (!User.user?.company.id) return;
  localStore.loading = true;

  const filter = [];

  filter.push({
    property: "client_id",
    operator: "=",
    value: User.user?.company.id,
  });

  if (localStore.currentTransaction !== "All types") {
    filter.push({
      property: localStore.currentTransactionType,
      operator: "=",
      value: localStore.currentTransaction,
    });
  }

  const copyStartDate = new Date(localStore.startDate.getTime());
  const startDate = copyStartDate.setDate(copyStartDate.getDate() + 1);

  filter.push({
    property: "value_datetime",
    operator: ">=",
    value: format(localStore.endDate, "yyyy-MM-dd"),
  });
  filter.push({
    property: "value_datetime",
    operator: "<=",
    value: format(startDate, "yyyy-MM-dd"),
  });
  filter.push({
    property: "client_id",
    operator: "=",
    value: User?.user?.company.id,
  });

  if (localStore.currentTransactionCurrency.length > 0)
    filter.push({
      property: "currency",
      operator: "=",
      value: localStore.currentTransactionCurrency,
    });

  if (localStore.currentTransactionCounterparty.length > 0)
    filter.push({
      property: "counterparty",
      operator: "=",
      value: localStore.currentTransactionCounterparty,
    });

  const result = await authorizedRequest({
    service: "transactions",
    url: `/v1/transactions?page=${localStore.currentPage}&size=${
      localStore.rows
    }&filter=${JSON.stringify(filter)}`,
  });
  runInAction(() => {
    localStore.transactions = result.items;
    localStore.totalPages = Math.ceil(result.total / result.size);
    localStore.currentPage = result.page;
    localStore.totalItems = result.total;
  });
  localStore.loading = false;
};

const Transactions = () => {
  const [visible, setVisible] = useState(true);
  const [isOpened, setOpened] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [currency, setCurrency] = useState();
  const tableRef = useRef<HTMLTableElement>(null);
  const onScroll = (event: React.UIEvent<HTMLTableElement>) => {
    const element = event.currentTarget;

    if (element.scrollHeight - element.scrollTop - element.clientHeight < 4) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    getTransactionWithParams();
  }, [
    localStore.currentTransaction,
    localStore.startDate,
    localStore.endDate,
    localStore.rows,
    localStore.currentPage,
    localStore.currentTransactionCurrency,
    localStore.currentTransactionCounterparty,
    User.user?.company.id,
  ]);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const isEmpty = !localStore.loading && !localStore.transactions.length;
  const emptyArr: number[] = [];
  emptyArr.length = 3;
  emptyArr.fill(1, 0, 3);

  const getCurrency = (item: TransactionItem) => {
    if (item.type === "conversion") {
      const buyCurrency = codeToCurrency(item.amount.currency_buy);
      const sellCurrency = codeToCurrency(item.amount.currency_sell);
      return (
        <span>
          {formatCurrency(item.amount.amount_sell, sellCurrency)}{" "}
          <span className={styles.arrow} />{" "}
          {formatCurrency(item.amount.amount_buy, buyCurrency)}
        </span>
      );
    }

    if (
      item.amount.amount > 0 &&
      item.type !== "internal" &&
      item.type !== "fee" &&
      item.type !== "outgoing"
    )
      return (
        <span className={styles.positive}>
          +
          {formatCurrency(
            item.amount.amount,
            codeToCurrency(item.amount.currency)
          )}
        </span>
      );
    if (item.type === "internal") {
      const onlyAmount = `${item.amount.amount}`
        .replace("+", "")
        .replace("-", "");
      return (
        <>—{formatCurrency(onlyAmount, codeToCurrency(item.amount.currency))}</>
      );
    }
    if (item.type === "fee") {
      const symbol = (item.amount.amount > 0 && "—") || "+";
      const onlyAmount = `${item.amount.amount}`
        .replace("+", "")
        .replace("-", "");
      if (symbol === "—")
        return (
          <>
            —{formatCurrency(+onlyAmount, codeToCurrency(item.amount.currency))}
          </>
        );
      return (
        <span className={styles.positive}>
          +{formatCurrency(+onlyAmount, codeToCurrency(item.amount.currency))}
        </span>
      );
    }
    if (item.type === "outgoing") {
      const onlyAmount = `${item.amount.amount}`
        .replace("+", "")
        .replace("-", "");
      return (
        <>
          {+onlyAmount !== 0 && "—"}
          {formatCurrency(+onlyAmount, codeToCurrency(item.amount.currency))}
        </>
      );
    }
    return (
      <>
        {formatCurrency(
          item.amount.amount,
          codeToCurrency(item.amount.currency)
        )}
      </>
    );
  };

  const { filterData, filteredItems, search } = useFilterFunction({
    items: transactionsTypes,
  });

  const handleDownload = () => {
    const filter = [];
    filter.push({
      property: "client_id",
      operator: "=",
      value: User.user?.company.id,
    });

    if (localStore.currentTransaction !== "All types") {
      filter.push({
        property: localStore.currentTransactionType,
        operator: "=",
        value: localStore.currentTransaction,
      });
    }

    const copyStartDate = new Date(localStore.startDate.getTime());
    const startDate = copyStartDate.setDate(copyStartDate.getDate() + 1);

    filter.push({
      property: "value_datetime",
      operator: ">=",
      value: format(localStore.endDate, "yyyy-MM-dd"),
    });
    filter.push({
      property: "value_datetime",
      operator: "<=",
      value: format(startDate, "yyyy-MM-dd"),
    });
    filter.push({
      property: "client_id",
      operator: "=",
      value: User?.user?.company.id,
    });

    if (localStore.currentTransactionCurrency.length > 0)
      filter.push({
        property: "currency",
        operator: "=",
        value: localStore.currentTransactionCurrency,
      });

    if (localStore.currentTransactionCounterparty.length > 0)
      filter.push({
        property: "counterparty",
        operator: "=",
        value: localStore.currentTransactionCounterparty,
      });

    fetch(
      `${
        process.env.REACT_APP_TS_URL
      }/v1/transactions/export?format=xlsx&filter=${JSON.stringify(filter)}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.blob())
      .then((result) => {
        const file = document.createElement("a");
        file.href = window.URL.createObjectURL(result);
        file.setAttribute(
          "download",
          `transactions_${moment(localStore.endDate).format(
            "YYYY-MM-DD"
          )}_${moment(localStore.startDate).format("YYYY-MM-DD")}.xlsx`
        );
        file.click();
      })
      .catch((error) => console.error(error));
  };

  const scrollTableDown = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }
  };

  const [value, setValue] = useState("1");

  return (
    <Main>
      <SideMenu />
      <InfoDrawer initialScreen="infoScreen" />
      <div className={styles.container}>
        <PageTitle>Transactions</PageTitle>
        <div className={styles.controls}>
          <div style={{ flexGrow: 1 }}>
            <Input
              label="Counterparty"
              value={localStore.currentTransactionCounterparty}
              onChange={(e) => {
                localStore.currentTransactionCounterparty = e.target.value;
              }}
            />
            {/* <Select
              size="middle"
              label="Counterparty"
              value={value}
              onChange={setValue}
              showSearch
            >
              <Select.Option value="1">Value 1</Select.Option>
              <Select.Option value="2">Value 2</Select.Option>
            </Select> */}
          </div>

          <DatePicker
            onChange={(start, end) => {
              // TODO: тут нет ошибки? может localStore.startDate = start?
              // Если да, после правок нужно еще исправить строки 164-168
              if (start) localStore.endDate = start;
              if (end) localStore.startDate = end;
              localStore.currentPage = 1;
            }}
            startDay={localStore.startDate}
            endDay={localStore.endDate}
          />

          <Button
            disabled={false}
            onClick={handleDownload}
            icon={<DownloadIcon size={16} />}
          />
          <Button
            square={true}
            style={{ background: "#9197AD" }}
            disabled={false}
            onClick={() => setShowMoreFilters(!showMoreFilters)}
            icon={<MoreControlsIcon size={16} color={"#fff"} />}
          />
        </div>

        {showMoreFilters && (
          <div className={styles.moreControls}>
            <div style={{ width: "256px" }}>
              <SearchSelect
                label="Type"
                title={
                  transactionsTypes?.find(
                    (item) => item.value === localStore.currentTransaction
                  )?.label || ""
                }
                onFilter={filterData}
                isOpened={isOpened}
                setOpened={setOpened}
              >
                {filteredItems.map((transactionItem) => {
                  return (
                    <SearchSelectItem
                      onClick={() => {
                        localStore.currentTransaction = transactionItem.value;
                        localStore.currentTransactionType =
                          transactionItem.type || "type";
                        localStore.currentPage = 1;
                        setOpened(false);
                      }}
                      selected={
                        localStore.currentTransaction === transactionItem.value
                      }
                    >
                      <ColorPass splitText={search}>
                        {transactionItem.label}
                      </ColorPass>
                    </SearchSelectItem>
                  );
                })}
              </SearchSelect>
            </div>
            <div style={{ width: "128px" }}>
              <Select
                size="middle"
                value={localStore.currentTransactionCurrency}
                label="Currency"
                onChange={(data) => {
                  localStore.currentTransactionCurrency = data;
                }}
              >
                <Select.Option value="978">EUR</Select.Option>
                <Select.Option value="840">USD</Select.Option>
              </Select>
            </div>
          </div>
        )}
        <div className={styles.tableWrapper}>
          <table className={styles.table} ref={tableRef} onScroll={onScroll}>
            <thead className={styles.thead}>
              <tr className={styles.th}>
                <th className={styles.cell} style={{ width: "20%" }}>
                  <div className={styles.cellInner}>
                    <span className={styles.headerText}>Date</span>
                  </div>
                </th>
                <th className={styles.cell} style={{ width: "50%" }}>
                  <div className={styles.cellInner}>
                    <span className={styles.headerText}>Details</span>
                  </div>
                </th>
                <th className={styles.cell} style={{ width: "30%" }}>
                  <div className={styles.cellInnerLast}>
                    <span className={styles.headerText}>Amount</span>
                  </div>
                </th>
              </tr>
            </thead>
            {!isEmpty && (
              <tbody className={styles.tbody}>
                {localStore.transactions.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td
                        style={{ width: "20%" }}
                        className={`${styles.cell} ${styles.cellInner}`}
                      >
                        <div>
                          {format(new Date(item.value_datetime), "dd.MM.yyyy")}
                        </div>
                      </td>
                      <td
                        style={{ width: "50%" }}
                        className={`${styles.cell} ${styles.cellInner}`}
                      >
                        <div className={styles.conversionHolder}>
                          <span
                            className={classNames(
                              styles.iconHolder,
                              item?.type && styles[item?.type],
                              item?.category && styles[item?.category]
                            )}
                          />
                          {item.details}
                        </div>
                      </td>
                      <td style={{ width: "30%" }} className={styles.cell}>
                        <div className={styles.cellInnerLast}>
                          {getCurrency(item)}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {localStore.loading &&
                  emptyArr.map((item) => {
                    return (
                      <tr>
                        <td
                          style={{ width: "20%" }}
                          className={`${styles.cell} ${styles.cellInner}`}
                        >
                          <div>
                            <Skeleton width={88} height={16} />
                          </div>
                        </td>
                        <td
                          style={{ width: "50%" }}
                          className={`${styles.cell} ${styles.cellInner}`}
                        >
                          <div>
                            <Skeleton width={202} height={16} />
                          </div>
                        </td>
                        <td style={{ width: "30%" }} className={styles.cell}>
                          <div className={styles.cellInnerLast}>
                            <Skeleton width={88} height={16} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}

            {isEmpty && (
              <tbody className={styles.emptyPlaceholder}>
                <tr>
                  <td>You have no transactions for the selected period</td>
                </tr>
              </tbody>
            )}
            {/*{*/}
            {/*  !isEmpty && visible && !localStore.loading && localStore.transactions.length >= 25 && <div className={styles.down}><Down /></div>*/}
            {/*}*/}
          </table>
          {localStore.shouldDisplayDown && visible && (
            <div className={styles.down} onClick={scrollTableDown}>
              <Down />
            </div>
          )}
        </div>

        {localStore.totalItems > 0 && (
          <Paginator
            initialRows={ROWS}
            onRowsChange={(rows) => {
              localStore.rows = rows;
            }}
            totalPages={localStore.totalPages}
            currentPage={localStore.currentPage}
            onPageChange={(page) => {
              localStore.currentPage = page;
            }}
            hasPrev={localStore.currentPage !== 1}
            hasNext={localStore.currentPage !== localStore.totalPages}
            totalItems={localStore.totalItems}
          />
        )}
      </div>
    </Main>
  );
};

export default observer(Transactions);
