import React, { useState } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as Btn } from './assets/btn.svg';

const Opener = (
  { status, title, children, onOpen, id, openId }:
    {status: 'notFilled'|'error'|'valid', title: string, children: React.ReactNode, onOpen: () => void, id: string, openId: string}) => {
  const isOpen = true;
  return (
    <div>
      <div className={styles.container}>
        <div className={styles[status]} />
        <div className={styles.title}>{title}</div>
      </div>
      {
        isOpen &&
          <div className={styles.content}>
            {children}
          </div>
        }
    </div>
  );
};

export default Opener;
