import { log } from "util";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
// @ts-ignore
import * as Validator from "validatorjs";
import { toJS } from "mobx";
import { DownloadIcon, Radio, Tooltip } from "@gamesb42/ui-kit";
import { Title3 } from "../Typography";
import TextInput from "../TextInput";
import { about } from "./index";
import info from "./assets/info.svg";

import styles from "./styles.module.scss";
import { director } from "../Director";

const Company = observer(() => {
  const [isCompanyTooltipOpen, setIsCompanyTooltipOpen] = useState(false);
  const [isPhoneTooltipOpen, setIsPhoneTooltipOpen] = useState(false);

  const rules = {
    legal_name: "required|string",
    email: "required|email",
    phone: [
      "required",
      "regex:/^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,8}$/",
    ],
    website:
      "regex:/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi",
    company_form: "required",
  };

  const validation = useMemo(
    () =>
      new Validator(toJS(about.values.details), rules, {
        required: "This field is required",
        email: "The email format is invalid",
        digits_between: "Field should contain from 10 up to 14 digits only",
        website:
          "The website format is invalid. For example: https://www.example.com",
        // "regex.phone": "+(country code) phone number",
        // "required.legal_name":
        //   "Please provide the full name of the registered company including the corporate form (e.g. Apple inc)",
      }),
    [
      about.values.details.legal_name,
      about.values.details.email,
      about.values.details.phone,
      about.values.details.website,
      about.values.details.company_form,
    ]
  );

  const isValid = validation.passes();

  useEffect(() => {
    about.isStepIsValid[1] = isValid;
  }, [isValid]);

  return (
    <>
      <div>
        <Title3 className={styles.subTitle}>Company form*</Title3>
        <div className={styles.form}>
          <Radio.Group value={about.values.details.company_form}>
            <Radio
              onChange={() => {
                about.values.details.company_form = "corporation";
                director.clearData();
              }}
              value="corporation"
            >
              Corporation
            </Radio>
            <Radio
              value="sole-proprietor"
              onChange={() => {
                about.values.details.company_form = "sole-proprietor";
                director.clearData();
              }}
            >
              Individual Entrepreneur (IE)
            </Radio>
            <Radio
              onChange={() => {
                about.values.details.company_form = "partnership";
                director.clearData();
              }}
              value="partnership"
            >
              Partnership
            </Radio>
          </Radio.Group>
        </div>
      </div>
      {["partnership", "corporation"].includes(
        about.values.details.company_form ?? ""
      ) && (
        <div>
          <div style={{ marginBottom: "20px" }}>
            <Title3 className={styles.subTitle}>Company info*</Title3>
          </div>
          <div style={{ height: "60px", marginBottom: "32px" }}>
            <TextInput
              onChange={(e) => {
                about.values.details.legal_name = e;
              }}
              className={styles.textField}
              label="Company legal name*"
              dataTest="Company legal name"
              value={about.values.details.legal_name}
              error={validation.errors.first("legal_name")}
            />
            <div
              style={{
                position: "relative",
                top: "-70px",
                right: "-620px",
                cursor: "pointer",
              }}
            >
              <Tooltip
                title="Please provide the full name of the registered company including the corporate form (e.g. Apple inc)"
                placement="leftTop"
                children={<img src={info} />}
              />
            </div>
          </div>

          <TextInput
            onChange={(e) => {
              about.values.details.email = e;
            }}
            label="Company corporate email*"
            dataTest="Company corporate email"
            className={styles.textField}
            value={about.values.details.email}
            error={validation.errors.first("email")}
          />

          <div style={{ height: "60px", marginBottom: "32px" }}>
            <TextInput
              onChange={(e) => {
                about.values.details.phone = e;
              }}
              label="Company phone number*"
              dataTest="Company phone number"
              value={about.values.details.phone}
              error={validation.errors.first("phone")}
            />
            <div
              style={{
                position: "relative",
                top: "-70px",
                right: "-620px",
                cursor: "pointer",
              }}
              onClick={() => setIsPhoneTooltipOpen(!isPhoneTooltipOpen)}
            >
              <Tooltip
                title="Phone number with country code"
                placement="leftTop"
                children={<img src={info} />}
              />
            </div>
          </div>

          <TextInput
            onChange={(e) => {
              about.values.details.website = e;
            }}
            dataTest="Company website"
            label="Company website"
            value={about.values.details.website}
            error={validation.errors.first("website")}
          />
        </div>
      )}
      {about.values.details.company_form === "sole-proprietor" && (
        <div>
          <div style={{ marginBottom: "20px" }}>
            <Title3 className={styles.subTitle}>
              Individual Entrepreneur info*
            </Title3>
          </div>
          <TextInput
            onChange={(e) => {
              about.values.details.legal_name = e;
            }}
            label="IE Full name*"
            dataTest="Company legal name"
            className={styles.textField}
            value={about.values.details.legal_name}
            error={validation.errors.first("legal_name")}
          />

          <TextInput
            onChange={(e) => {
              about.values.details.email = e;
            }}
            label="IE email*"
            dataTest="Company corporate email"
            className={styles.textField}
            value={about.values.details.email}
            error={validation.errors.first("email")}
          />

          {/* <TextInput
            onChange={(e) => {
              about.values.details.phone = e;
            }}
            label="IE phone number*"
            dataTest="Company phone number"
            className={styles.textField}
            value={about.values.details.phone}
            error={validation.errors.first("phone")}
          /> */}

          <div style={{ height: "60px", marginBottom: "32px" }}>
            <TextInput
              onChange={(e) => {
                about.values.details.phone = e;
              }}
              className={styles.textField}
              label="IE phone number*"
              dataTest="Company phone number"
              value={about.values.details.phone}
              error={validation.errors.first("phone")}
            />
            <div
              style={{
                position: "relative",
                top: "-70px",
                right: "-620px",
                cursor: "pointer",
              }}
              onClick={() => setIsPhoneTooltipOpen(!isPhoneTooltipOpen)}
            >
              <Tooltip
                title="Phone number with country code"
                placement="leftTop"
                children={<img src={info} />}
              />
            </div>
          </div>

          <TextInput
            onChange={(e) => {
              about.values.details.website = e;
            }}
            dataTest="Company website url"
            label="IE website"
            value={about.values.details.website}
            error={validation.errors.first("website")}
          />
        </div>
      )}
    </>
  );
});

export default Company;
