import React from "react";
import { observer } from "mobx-react-lite";
import { InfoDrawer } from "../../pages/Connection";
import appStore from "../../store/App";
import styles from "./styles.module.scss";
import { ReactComponent as Close } from "./assets/close.svg";

type Props = {
  children: React.ReactNode;
};

// eslint-disable-next-line react/function-component-definition
const Main: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      {children}
      <NotificationContainer />
    </div>
  );
};

const NotificationContainer = observer(() => {
  const isErrorType = appStore.notification.type === "error";

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <div
        className={`${styles.bubble} ${
          appStore.notification.visible && styles.opened
        } ${isErrorType ? styles.error : styles.success} ${
          appStore.isPortalOpened ? styles.light : styles.dark
        }`}
        style={{ width: appStore.notification.width }}
      >
        <div>{appStore.notification.title}</div>
        <div>{appStore.notification.message}</div>
        <button
          className={`${styles.btn} ${
            appStore.isPortalOpened ? styles.btnLight : styles.btnDark
          }`}
          onClick={() => appStore.resetNotification()}
        >
          <Close />
        </button>
      </div>
    </>
  );
});

export default Main;
export { NotificationContainer };
