import { Radio } from "@gamesb42/ui-kit";
import { contactPersonInst } from ".";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { about } from "../AboutCompany";

const Step1 = observer(() => {
  // validate step data
  useEffect(() => {
    if (contactPersonInst.values.step1Variant.length > 0) {
      contactPersonInst.isStepIsValid[1] = true;
    } else {
      contactPersonInst.isStepIsValid[1] = false;
    }
  }, [contactPersonInst.values.step1Variant, contactPersonInst.isStepIsValid]);

  return (
    <>
      <p>Is the contact person also the signatory?*</p>
      <Radio.Group value={contactPersonInst.values.step1Variant}>
        <Radio
          value="yes"
          onChange={() => {
            contactPersonInst.values.step1Variant = "yes";
          }}
        >
          Yes
        </Radio>
        <Radio
          value="no"
          onChange={() => {
            contactPersonInst.values.step1Variant = "no";

            const currentContact = about.values.contacts.find(
              (item) => item.type === "contact"
            );
            const currentContactIndx = about.values.contacts.indexOf(
              currentContact!
            );
            if (currentContactIndx > -1) {
              about.values.contacts.splice(currentContactIndx, 1);
            }
          }}
        >
          No
        </Radio>
      </Radio.Group>
    </>
  );
});

export default Step1;
