import { useEffect } from "react";
import { observer } from "mobx-react";
import { Input } from "@gamesb42/ui-kit";
// @ts-ignore
import * as Validator from "validatorjs";

import CheckBox from "components/CheckBox";
import { appInfoInst } from ".";
import TextArea from "components/TextArea";

import s from "./styles.module.scss";

const Step1 = observer(() => {
  const rules = {
    distribution: {
      appleStoreComment: "url|regex:/apple/",
      googleStoreComment: "url|regex:/google/",
      steamComment: "url|regex:/steam/",
    },
  };
  const validation = new Validator(appInfoInst.values, rules, {
    url: "The link format is invalid",
    regex: "Link is not correct",
  });
  const isValid = validation.passes();

  useEffect(() => {
    appInfoInst.isStepIsValid[1] =
      [
        appInfoInst.values.distribution.appleStoreComment,
        appInfoInst.values.distribution.googleStoreComment,
        appInfoInst.values.distribution.steamComment,
        appInfoInst.values.distribution.otherComment,
      ].some((el) => el?.length > 0) && isValid;
  }, [
    appInfoInst.values.distribution,
    appInfoInst.values.distribution.appleStoreComment,
    appInfoInst.values.distribution.googleStoreComment,
    appInfoInst.values.distribution.steamComment,
    appInfoInst.values.distribution.otherComment,
  ]);

  return (
    <>
      <h3>
        <b>Where is your app or game distributed now?*</b>
      </h3>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <CheckBox
          label="Apple App Store"
          onChange={() =>
            (appInfoInst.values.distribution.appleStore =
              !appInfoInst.values.distribution.appleStore)
          }
          checked={appInfoInst.values.distribution.appleStore}
        />
        {appInfoInst.values.distribution.appleStore && (
          <div>
            <Input
              size="large"
              label="Link to your game/app at Apple App Store*"
              value={appInfoInst.values.distribution.appleStoreComment}
              onChange={(e) => {
                appInfoInst.values.distribution.appleStoreComment =
                  e.target.value;
              }}
              status={
                validation.errors.first("distribution.appleStoreComment")
                  ? "error"
                  : undefined
              }
            />
            {validation.errors.first("distribution.appleStoreComment") && (
              <div className={s.errorMessage}>
                {validation.errors.first("distribution.appleStoreComment")}
              </div>
            )}
          </div>
        )}

        <CheckBox
          label="Google Play"
          onChange={() =>
            (appInfoInst.values.distribution.googleStore =
              !appInfoInst.values.distribution.googleStore)
          }
          checked={appInfoInst.values.distribution.googleStore}
        />
        {appInfoInst.values.distribution.googleStore && (
          <div>
            <Input
              size="large"
              label="Link to your game/app at Google Play*"
              value={appInfoInst.values.distribution.googleStoreComment}
              onChange={(e) => {
                appInfoInst.values.distribution.googleStoreComment =
                  e.target.value;
              }}
              status={
                validation.errors.first("distribution.googleStoreComment")
                  ? "error"
                  : undefined
              }
            />
            {validation.errors.first("distribution.googleStoreComment") && (
              <div className={s.errorMessage}>
                {validation.errors.first("distribution.googleStoreComment")}
              </div>
            )}
          </div>
        )}

        <CheckBox
          label="Steam"
          onChange={() =>
            (appInfoInst.values.distribution.steam =
              !appInfoInst.values.distribution.steam)
          }
          checked={appInfoInst.values.distribution.steam}
        />
        {appInfoInst.values.distribution.steam && (
          <div>
            <Input
              size="large"
              label="Link to your game/app at Steam*"
              value={appInfoInst.values.distribution.steamComment}
              onChange={(e) => {
                appInfoInst.values.distribution.steamComment = e.target.value;
              }}
              status={
                validation.errors.first("distribution.steamComment")
                  ? "error"
                  : undefined
              }
            />
            {validation.errors.first("distribution.steamComment") && (
              <div className={s.errorMessage}>
                {validation.errors.first("distribution.steamComment")}
              </div>
            )}
          </div>
        )}

        <CheckBox
          label="Other"
          onChange={() =>
            (appInfoInst.values.distribution.other =
              !appInfoInst.values.distribution.other)
          }
          checked={appInfoInst.values.distribution.other}
        />
        {appInfoInst.values.distribution.other && (
          <Input
            size="large"
            label="Enter your distribution platform"
            value={appInfoInst.values.distribution.otherComment}
            onChange={(e) => {
              appInfoInst.values.distribution.otherComment = e.target.value;
            }}
          />
        )}
      </div>
    </>
  );
});

export default Step1;
