import { observer } from "mobx-react";

import CheckBox from "components/CheckBox";
import { appInfoInst } from ".";

import s from "./styles.module.scss";
import { useEffect } from "react";

const Step4 = observer(() => {
  useEffect(() => {
    appInfoInst.isStepIsValid[4] = Object.values(appInfoInst.values.mmp).some(
      (el) => true
    );
  }, [
    appInfoInst.values.mmp.adjust,
    appInfoInst.values.mmp.appsflyer,
    appInfoInst.values.mmp.tenjin,
    appInfoInst.values.mmp.kochava,
    appInfoInst.values.mmp.branch,
    appInfoInst.values.mmp.firebase,
    appInfoInst.values.mmp.dontKnow,
    appInfoInst.values.mmp.other,
  ]);

  return (
    <>
      <h3 style={{ marginBottom: "20px" }}>
        <b>Your current MMP (tracking service)*</b>
      </h3>
      <CheckBox
        label="Adjust"
        onChange={() =>
          (appInfoInst.values.mmp.adjust = !appInfoInst.values.mmp.adjust)
        }
        checked={appInfoInst.values.mmp.adjust}
      />
      <CheckBox
        onChange={() =>
          (appInfoInst.values.mmp.appsflyer = !appInfoInst.values.mmp.appsflyer)
        }
        checked={appInfoInst.values.mmp.appsflyer}
        label="Appsflyer"
        className={s.item}
      />
      <CheckBox
        onChange={() =>
          (appInfoInst.values.mmp.tenjin = !appInfoInst.values.mmp.tenjin)
        }
        checked={appInfoInst.values.mmp.tenjin}
        label="Tenjin"
        className={s.item}
      />
      <CheckBox
        onChange={() =>
          (appInfoInst.values.mmp.kochava = !appInfoInst.values.mmp.kochava)
        }
        checked={appInfoInst.values.mmp.kochava}
        label="Kochava"
        className={s.item}
      />
      <CheckBox
        onChange={() =>
          (appInfoInst.values.mmp.branch = !appInfoInst.values.mmp.branch)
        }
        checked={appInfoInst.values.mmp.branch}
        label="Branch"
        className={s.item}
      />
      <CheckBox
        onChange={() =>
          (appInfoInst.values.mmp.firebase = !appInfoInst.values.mmp.firebase)
        }
        checked={appInfoInst.values.mmp.firebase}
        label="Firebase"
        className={s.item}
      />
      <CheckBox
        onChange={() =>
          (appInfoInst.values.mmp.dontKnow = !appInfoInst.values.mmp.dontKnow)
        }
        checked={appInfoInst.values.mmp.dontKnow}
        label="I don’t know"
        className={s.item}
      />
      <CheckBox
        onChange={() =>
          (appInfoInst.values.mmp.other = !appInfoInst.values.mmp.other)
        }
        checked={appInfoInst.values.mmp.other}
        label="Other"
      />
    </>
  );
});

export default Step4;
