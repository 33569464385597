import React from 'react';
import styles from './styles.module.scss';

const Title2 = ({ children, className = '' }: { children: React.ReactNode, className?: string }) => {
  return (
    <h2 className={`${styles.title} ${className}`}>
      {children}
    </h2>
  );
};

const Title3 = ({ children, className = '' }: { children: React.ReactNode, className?: string }) => {
  return (
    <h3 className={`${styles.title2} ${className}`}>
      {children}
    </h3>
  );
};

const Text1 = ({ children, className = '' }: { children: React.ReactNode, className?: string }) => {
  return (
    <p className={`${styles.text1} ${className}`}>{children}</p>
  );
};

const Text2 = ({ children, className = '' }: { children: React.ReactNode, className?: string }) => {
  return (
    <p className={`${styles.text2} ${className}`}>{children}</p>
  );
};

export { Title2, Text1, Title3, Text2 };
