import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
// @ts-ignore
import * as Validator from "validatorjs";
import TextInput from "../TextInput";
import { Title3 } from "../Typography";
import { companySignatoryInst } from ".";
import UploadFile from "components/UploadFile";
import FileName from "./components/FileName";

import { ReactComponent as Pdf } from "../CorporateDocument/assets/pdf.svg";
import { ReactComponent as Close } from "../CorporateDocument/assets/close.svg";

import s from "./styles.module.scss";

const Step1 = observer(() => {
  const rules = {
    fullName: "required",
    position: "required",
    email: "required|email",
  };

  const validation = useMemo(
    () =>
      new Validator(companySignatoryInst.values, rules, {
        required: "This field is required",
        email: "The email format is invalid",
      }),
    [
      companySignatoryInst.values.fullName,
      companySignatoryInst.values.position,
      companySignatoryInst.values.email,
    ]
  );

  const isValid = validation.passes();
  companySignatoryInst.isStepIsValid[1] = isValid;

  const delFileHandler = () => {
    companySignatoryInst.values.companySignatoryFile!.name = "";
    companySignatoryInst.values.companySignatoryFile!.value = "";
    companySignatoryInst.values.companySignatoryFile!.size = 0;
  };

  console.log(companySignatoryInst.values.companySignatoryFile);

  return (
    <div>
      <>
        <Title3 className={s.subTitle}>
          Person who is entitled to sign, execute and approve business contracts
          on behalf of a company*
        </Title3>
        <TextInput
          onChange={(e) => {
            companySignatoryInst.values.fullName = e;
          }}
          label="Full name*"
          dataTest="fullName"
          className={s.textField}
          value={companySignatoryInst.values.fullName}
          error={validation.errors.first("fullName")}
        />
        <TextInput
          onChange={(e) => {
            companySignatoryInst.values.position = e;
          }}
          label="Position*"
          dataTest="position"
          className={s.textField}
          value={companySignatoryInst.values.position}
          error={validation.errors.first("position")}
        />
        <TextInput
          onChange={(e) => {
            companySignatoryInst.values.email = e;
          }}
          label="Email*"
          dataTest="email"
          className={s.textField}
          value={companySignatoryInst.values.email}
          error={validation.errors.first("email")}
        />
      </>

      <div>
        {(companySignatoryInst.values.companySignatoryFile!.size === 0 && (
          <UploadFile
            dragText="Drag and drop file*"
            uploadText={`Please upload the document confirming he/she is an authorised person to sign a contract 
            on company's behalf e.g. Power of Attorney, register of Directors etc.
              <br />
            50 Mb max. weight`}
            onUpload={async (e, file) => {
              companySignatoryInst.values.companySignatoryFile!.name =
                file.name;
              companySignatoryInst.values.companySignatoryFile!.size =
                file.size;
              companySignatoryInst.values.companySignatoryFile!.type =
                file.type;
              companySignatoryInst.values.companySignatoryFile!.value = file;
            }}
          />
        )) || <FileName fileName="companySignatoryFile" />}
      </div>
    </div>
  );
});

export default Step1;
