import React, { ReactNode } from 'react';
import styles from './styles.module.scss'

type SplittedProps = {
    children: ReactNode,
}

type ButtonProps = {
    children: string,
    onClick: () => void,
    isActive?: boolean,
}

const SplittedButtons = ({ children }:SplittedProps) => {
  return (
    <div className={styles.holder}>{children}</div>
  );
};

const SplittedButton = ({ children, onClick, isActive }: ButtonProps) => {
  const btnClass = isActive ? `${styles.active} ${styles.button}` : styles.button;
  return (
    <button onClick={onClick} className={btnClass}>{children}</button>
  );
};

export default SplittedButtons;
export { SplittedButton };
