import { observer } from "mobx-react";
// @ts-ignore
import * as Validator from "validatorjs";
import React, { useEffect, useMemo } from "react";
import { toJS } from "mobx";
import { Title3 } from "../Typography";
import TextInput from "../TextInput";
import { about } from "./index";
import styles from "./styles.module.scss";
import { Input } from "@gamesb42/ui-kit";

const AccountUsd = observer(() => {
  const rules = {
    bank_name: "required",
    swift: "required|regex:/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/",
    account: "required",
  };
  const bank = about.values.bank_details.find(
    (item) => item.currency === "USD"
  );
  const validation = useMemo(
    () =>
      new Validator(toJS(bank), rules, {
        required: "This field is required",
      }),
    [bank!.bank_name, bank!.swift, bank!.account]
  );
  const isValid = validation.passes();
  useEffect(() => {
    const step =
      about.currency.length === 1 && about.currency[0] === "usd" ? 5 : 6;
    about.isStepIsValid[step] = isValid;
  }, [isValid]);
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Title3 className={styles.subTitle}>Your USD Bank Account</Title3>
      </div>
      <TextInput
        onChange={(e) => {
          bank!.bank_name = e;
        }}
        label="Bank name*"
        dataTest="bankName"
        className={styles.textField}
        value={bank?.bank_name}
        error={validation.errors.first("bank_name")}
      />

      <TextInput
        onChange={(e) => {
          bank!.swift = e;
        }}
        label="SWIFT code*"
        dataTest="swiftCode"
        className={styles.textField}
        value={bank!.swift}
        error={validation.errors.first("swift")}
      />

      <TextInput
        onChange={(e) => {
          bank!.account = e;
        }}
        label="IBAN or account number*"
        dataTest="iban"
        className={styles.textField}
        value={bank!.account}
        error={validation.errors.first("account")}
      />

      <TextInput
        onChange={(e) => {
          bank!.bank_address = e;
        }}
        label="Bank address"
        dataTest="iban"
        className={styles.textField}
        value={bank!.bank_address}
        // error={validation.errors.first("account")}
      />

      <TextInput
        onChange={(e) => {
          bank!.correspondent_bank_name = e;
        }}
        label="Correspondent bank"
        dataTest="iban"
        className={styles.textField}
        value={bank!.correspondent_bank_name}
        // error={validation.errors.first("account")}
      />

      <TextInput
        onChange={(e) => {
          bank!.correspondent_swift = e;
        }}
        label="Correspondent SWIFT code"
        dataTest="iban"
        className={styles.textField}
        value={bank!.correspondent_swift}
        // error={validation.errors.first("account")}
      />

      <TextInput
        onChange={(e) => {
          bank!.correspondent_account = e;
        }}
        label="Correspondent IBAN or account number"
        dataTest="iban"
        className={styles.textField}
        value={bank!.correspondent_account}
        // error={validation.errors.first("account")}
      />
      <Input.TextArea
        style={{ height: "100px" }}
        label="Special instructions"
        maxLength={200}
        //@ts-ignore
        value={bank!.special_instructions}
        onChange={(e: any) => {
          bank!.special_instructions = e.target.value;
        }}
      />
    </>
  );
});

export default AccountUsd;
