import { observer } from "mobx-react";

import { appInfoInst } from ".";

import { useEffect } from "react";
import { Input } from "@gamesb42/ui-kit";

const Step5 = observer(() => {
  useEffect(() => {
    appInfoInst.isStepIsValid[5] = true;
  }, [appInfoInst.values.additionalInfo]);

  return (
    <>
      <h3 style={{ marginBottom: "20px" }}>
        <b>Additional info about the project</b>
      </h3>
      <Input.TextArea
        style={{ height: "140px" }}
        label="Comment"
        disabled={false}
        showCount={true}
        maxLength={400}
        value={appInfoInst.values.additionalInfo}
        onChange={(e) => {
          appInfoInst.values.additionalInfo = e.target.value;
        }}
      />
    </>
  );
});

export default Step5;
