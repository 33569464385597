import React, { Fragment, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  idx: number,
  children: React.ReactNode,
  key: string|number,
  className?: string
}

const AnimatedList = ({ idx, children, key, className }:Props) => {
  const [showItem, setShowItem] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowItem(true);
    }, 100 * idx);
  }, []);

  if (!showItem) return null;

  return <div key={key} className={cn(className, styles.slideInDown)}>{children}</div>;
};

export default AnimatedList;
