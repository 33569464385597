import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Button, DoneRoundIcon } from "@gamesb42/ui-kit";
import moment from "moment";
import connectSources, { Accounts } from "store/ConnectSources";
import styles from "./styles.module.scss";

type Props = {
  id: string;
  name: string;
  logo: string;
  onClick: () => void;
  syncType: string;
  syncStatus: string;
  updatedAt: string;
};

const RevenueCard = ({
  id,
  name,
  logo,
  onClick,
  syncType,
  syncStatus,
  updatedAt,
}: Props) => {
  const connectedAccount: Accounts | undefined =
    connectSources.accountsById[id];
  const isConnected =
    (connectedAccount?.syncStatus !== "credentials_not_filled" &&
      connectedAccount?.storeSyncType === "auto" &&
      true) ||
    false;
  const isFile = connectedAccount?.storeSyncType === "file";

  return (
    <div className={cn(styles.card, styles[id])}>
      <div className={styles.store}>
        {logo && <img className={styles.logo} src={logo} alt="logo" />}

        <div className={styles.name}>
          <span className={styles.title}>{name}</span>
          {((!isFile && isConnected) || isFile) && (
            <DoneRoundIcon size={16} className={styles.icon} />
          )}
        </div>
      </div>

      {!isFile && isConnected && (
        <div className={styles.sync}>
          Last sync: {moment(updatedAt).format("DD.MM.YYYY HH:mm")}
        </div>
      )}

      {(!isFile && isConnected && (
        <Button className={styles.btn} onClick={onClick} size="small">
          Edit
        </Button>
      )) ||
        (!isFile && !isConnected && (
          <Button
            className={styles.btn}
            onClick={onClick}
            size="small"
            type="primary"
          >
            Connect
          </Button>
        ))}
    </div>
  );
};

export default observer(RevenueCard);
