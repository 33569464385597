import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { Input } from '@gamesb42/ui-kit';
import styles from './styles.module.scss';

const TextInput = (
  { value, onChange, label, className = '', onBlur, error = '' }:
    // eslint-disable-next-line react/no-unused-prop-types
    { dataTest?: string, value?: string|null, onChange: (val: string) => void, label: string, className?: string, onBlur?: () => void, error?: string, labelClassName?: string }) => {
  const [isError, showError] = useState(false);
  return (
    <div className={cn(className, styles.inputHolder)}>
      <Input
        label={label}
        size="large"
        onChange={(e) => onChange(e.currentTarget.value)}
        onBlur={() => {
          if (error) {
            showError(true);
          } else {
            showError(false);
          }
          onBlur && onBlur();
        }}
        // @ts-ignore
        value={value}
        status={(error && isError) ? 'error' : undefined}
      />
      {/*<input*/}
      {/*  type="text"*/}
      {/*  value={value || ''}*/}
      {/*  onChange={e => onChange(e.currentTarget.value)}*/}
      {/*  className={`${(error && isError) ? styles.errorInput : styles.input}`}*/}
      {/*  data-test={dataTest}*/}
      {/*  onBlur={() => {*/}
      {/*    if (error) {*/}
      {/*      showError(true);*/}
      {/*    } else {*/}
      {/*      showError(false);*/}
      {/*    }*/}
      {/*    onBlur && onBlur();*/}
      {/*  }}*/}
      {/*  ref={input}*/}
      {/*/>*/}
      {
        (isError && error) &&
        <div className={styles.fadeInDown}>
          <div className="ant-form-item-explain">
            <div className="ant-form-item-explain-error">{error}</div>
          </div>
        </div>
      }
    </div>
  );
};

export default TextInput;
