import React, { FC, memo } from 'react';

const intlDate = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

type Props = {
  date: Date | string;
}

const FormatDate: FC<Props> = ({ date }) => {
  let result: string = '';

  if (typeof date === 'string') {
    const dateObject = Date.parse(date);
    result = Number.isNaN(dateObject) ? 'Unknown date' : intlDate.format(dateObject);
  }

  if (typeof date !== 'string') {
    result = intlDate.format(date);
  }

  return <>{result.replaceAll('/', '.')}</>;
};

export default memo(FormatDate);
